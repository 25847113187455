import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AvailableTimes as MuiAvailableTimes } from '../styled';
import { IAppointments, useScheduleAvailableTimeGroupedByPeriod } from '../hooks';
import { formatPeriodLabel, formatTimeLabel } from '../../../../lib/dayjs';
import { saveSelectedDate } from '../../../../features/schedule/schedule-slice';
import { RootState } from '../../../../store';
import { customEventTracking } from '../../../../lib/gtm';
import { ChipHour } from './style';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
interface AvailableTimesProps {
  appointments: IAppointments;
  isLoading: boolean;
}

const AvailableTimes = ({ appointments, isLoading }: AvailableTimesProps) => {
  const dispatch = useDispatch();
  const { selectedDay, dateStart, events } = useSelector((state: RootState) => state.schedule);
  const [scheduleAvailableTimeGroupedByPeriod] = useScheduleAvailableTimeGroupedByPeriod(appointments, selectedDay);

  //Google Tag Manager - Event Tracking
  useEffect(() => {
    const timer = setTimeout(() => {
      customEventTracking.send({
        event: events,
        props: {
          day_available: selectedDay,
          available_times: `morning: ${
            scheduleAvailableTimeGroupedByPeriod?.morning?.availableList.length > 0 ? true : false
          }, afternoon: ${
            scheduleAvailableTimeGroupedByPeriod?.afternoon?.availableList.length > 0 ? true : false
          }, evening: ${scheduleAvailableTimeGroupedByPeriod?.evening?.availableList.length > 0 ? true : false}`
        }
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [scheduleAvailableTimeGroupedByPeriod, events]);
  return (
    <MuiAvailableTimes>
      {!isLoading ? <></> : <CircularProgress sx={{ color: 'brand.darkGreen' }} />}
      <>
        {Object.keys(scheduleAvailableTimeGroupedByPeriod).map((key, index) => {
          return (
            scheduleAvailableTimeGroupedByPeriod[key].availableList.length >= 1 && (
              <Box key={index} sx={{ marginBottom: { xs: '24px', md: '30px' } }}>
                <Typography variant="body2R">{formatPeriodLabel(key)}</Typography>
                <Box>
                  {scheduleAvailableTimeGroupedByPeriod[key].availableList.map((value, index) => {
                    return (
                      <ChipHour
                        key={index}
                        label={formatTimeLabel(value)}
                        variant="outlined"
                        className={value === dateStart ? 'selected' : ''}
                        onClick={() => {
                          dispatch(saveSelectedDate(value));
                          customEventTracking.send({
                            event: 'calendar_touch_time',
                            props: {
                              touch_time: value
                            }
                          });
                        }}
                      />
                    );
                  })}
                </Box>
              </Box>
            )
          );
        })}
      </>
    </MuiAvailableTimes>
  );
};

export default AvailableTimes;
