import { api } from './api.service';
import { IJourneyResp } from './journey.service';

export const Home = api.injectEndpoints({
  endpoints: build => ({
    getHistory: build.mutation<IJourneyResp[], any>({
      query: () => ({
        url: `/portal/v1/me/history`
      })
    })
  })
});

export const { useGetHistoryMutation } = Home;
