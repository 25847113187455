import { api } from './api.service';

export interface IRequest {
  journeyId: string;
}

export interface IFileRequest {
  key: string;
}

export interface IFileResponse {
  url: string;
}

export interface ISteps {
  currentStep: string;
  nextSteps: string;
  previousSteps: string;
}

export interface IPaymentAddress {
  address: string;
  addressComplement: string;
  addressNumber: string;
  addressReference: string;
  cep: string;
  city: string;
  neighborhood: string;
  state: string;
}
export interface IPayments {
  address: IPaymentAddress;
  amount: number;
  cupomCode: string;
  discount: number;
  id: string;
  installments: number;
  method: 'creditCard' | 'pix' | 'healthPlan' | '';
  paymentDate: any;
  status: string;
}

export interface IAddress {
  address: string;
  addressComplement: string;
  addressNumber: string;
  addressReference: string;
  cep: string;
  city: string;
  neighborhood: string;
  state: string;
}

export interface ISchedules {
  address: IAddress;
  dateStart: string;
  dateEnd: string;
  id: string;
  returnSchedule: boolean;
  status: string;
  type: string;
  calendarId: string;
  teleUrl: string;
  allowReschedule: boolean;
  isNeedCollectCSAT: boolean;
}

export interface InfoFiles {
  id: string;
  name: string;
  fileType: string;
  url: string;
  date: string;
}

export interface Files {
  exams: InfoFiles[];
  reports: InfoFiles[];
  requests: InfoFiles[];
}

export enum ECalendarType {
  MAIN = 'MAIN',
  RETURN = 'RETURN',
  BOTH = 'BOTH'
}
export interface CalendarTypes {
  id: string;
  calendarId: string;
  type: ECalendarType.MAIN | ECalendarType.RETURN | ECalendarType.BOTH;
}
export interface IJourneyResp {
  doctor?: {
    name: string;
    profileImage: string;
    profileUrl: string;
    id?: string;
  };
  haveToAnswerAnamnese: string;
  journeyid: string;
  schedules: Array<ISchedules>;
  payments: Array<IPayments>;
  product: {
    id: string;
    name: string;
  };
  steps: ISteps;
  status: string;
  calendars?: any;
  calendarSecundary: {
    durationEvent: number;
    id: string;
    type: string;
  };
  files: Files;
  calendarTypes: Array<CalendarTypes>;
}

export const Journey = api.injectEndpoints({
  endpoints: build => ({
    getJourneyById: build.mutation<IJourneyResp, IRequest>({
      query: ({ journeyId }) => ({
        url: `/portal/v1/me/journey/${journeyId}`
      })
    }),
    getFileUrl: build.mutation<IFileResponse, IFileRequest>({
      query: ({ key }) => ({
        url: `/oyanafiles`,
        method: 'PUT',
        body: {
          key
        }
      })
    }),
    logSelectedFile: build.mutation<void, string>({
      query: fileId => ({
        url: `/oyanafiles/${fileId}/log`,
        method: 'PUT'
      })
    })
  })
});

export const { useGetJourneyByIdMutation, useGetFileUrlMutation, useLogSelectedFileMutation } = Journey;
