import { FC, SyntheticEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface Props {
  textButton?: string;
  text: string;
  type: 'error' | 'warning' | 'info' | 'success';
}

export const AlertMessage: FC<Props> = ({ text, textButton, type }) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(false);
  };

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} key={Math.random()}>
        <Alert
          variant="filled"
          severity={type}
          sx={{ width: '100%' }}
          action={
            <Button color="inherit" size="small" sx={{ height: '20px' }} onClick={handleClick}>
              {textButton}
            </Button>
          }
        >
          {text}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AlertMessage;
