import BasicModal from '../../../../../shared/Modal/ModalBasic';
import Typography from '@mui/material/Typography';

interface ModalProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
}

const Modal = ({ openCloseController, onCloseHandler }: ModalProps) => {
  return (
    <>
      <BasicModal open={openCloseController} onCloseHandler={onCloseHandler} title="Como funciona a consulta online?">
        <Typography variant="body1" sx={{ mb: 4 }}>
          A consulta médica online funciona da mesma forma que presencial, só que por videochamada. Você só precisa de um
          celular, tablet ou computador com internet, câmera e microfone.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Durante a consulta, um dos médicos e médicas ginecologistas da nossa equipe vai conversar com você sobre suas
          dúvidas e desconfortos e pensar em um plano de ação a partir do seu histórico de saúde, estilo de vida e
          necessidades do momento. Garantimos atendimento humanizado feito por profissionais capacitados com as melhores
          práticas de empatia de empatia digital.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Se precisar de algum exame, te enviamos uma receita digital com todas as orientações no momento da sua consulta
          online. Você pode agendar seus exames no laboratório que quiser e enviar o resultado para a Oya.
        </Typography>
      </BasicModal>
    </>
  );
};

export default Modal;
