import { ReactElement, FC, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

interface Props {
  icon: ReactElement;
  title: string;
  subtitle: string;
  children?: ReactNode;
}

const JourneyAction: FC<Props> = ({ icon, title, subtitle, children }) => {
  const theme = useTheme();
  return (
    <Grid>
      <Box
        sx={{
          border: `1px solid ${theme.palette.neutrals.beige4}`,
          borderRadius: 2,
          padding: 2,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'initial' }
        }}
      >
        <Box sx={{ mb: { xs: 2, md: 0 }, mr: 2 }}>{icon}</Box>
        <Box>
          <Typography variant="body1B" mb={2}>
            {title}
          </Typography>
          <Typography variant="body1" mb={2}>
            {subtitle}
          </Typography>
          <Box>{children}</Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default JourneyAction;
