import { createSlice } from '@reduxjs/toolkit';
import { IOyanaResponse } from '../../services/me.service';

interface IOyana {
  me: IOyanaResponse;
}

const initialState: IOyana = {
  me: {
    id: '',
    cpf: '',
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    birthDate: '',
    nickName: '',
    fullName: '',
    addresses: [
      {
        id: '',
        cep: '',
        city: '',
        state: '',
        country: '',
        address: '',
        addressNumber: 0,
        addressComplement: '',
        addressReference: '',
        neighborhood: ''
      }
    ]
  }
};

export const oyana = createSlice({
  name: 'oyana',
  initialState,
  reducers: {
    saveOyanaPersonaData: (state, action) => {
      state.me = action.payload;
    }
  }
});

export const { saveOyanaPersonaData } = oyana.actions;
export const oyanaReducer = oyana.reducer;
