import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';

import { FullHeightOnly } from '../../components/shared/Grid';
import TemplateDefault from '../../layout/TemplateDefault';
import Calendar from '../../components/shared/Calendar';
import MyFiles from '../../components/pages/Home/MyFiles';
import { TittleButton } from '../../components/shared/TitleButton';
import CalendarIcon from '../../images/calendar_icon.svg';

import { RootState } from '../../store';
import { saveActiveJourneys, saveDoneJourneys } from '../../features/journeys';
import { IJourneyResp } from '../../services/journey.service';
import { useGetPersonalDataMutation } from '../../services/me.service';
import { useGetHistoryMutation } from '../../services/home.service';
import { saveOyanaPersonaData } from '../../features/me/me-slice';
import { saveConfirmSchedule } from '../../features/schedule/schedule-slice';
import { JourneyManagerInitialState, saveCalendarTypeSelected } from '../../features/journeyManager/journey-manager-slice';
import JourneyAction from '../../components/shared/JourneyAction';
import JourneyActionManager, { getCurrentSchedule } from '../../components/shared/JourneyActionManager';
import { customEventTracking } from '../../lib/gtm';

export interface DataHistory {
  product: {
    id: string;
    name: string;
  };
  journeyid: string;
  status: string;
  schedule: {
    id: string;
    dateStart: string;
    status: string;
    type: string;
  };
  user: {
    name: string;
  };
}
[];

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getHistory, { data, isLoading }] = useGetHistoryMutation();
  const [getPersonalData, { data: dataOyana }] = useGetPersonalDataMutation();

  const [historic, setHistoric] = useState<IJourneyResp[]>();
  const [noActiveJourneys, setNoActiveJourneys] = useState(false);
  const {
    schedule: {
      haveToAnswerAnamnese,
      confirm,
      ui: { scheduling }
    },
    singIn: {
      auth: { access_token }
    },
    oyana: {
      me: { nickName }
    }
  } = useSelector((state: RootState) => state);

  const loadJourneyHistory = () => {
    getHistory({}).then(() => {
      dispatch(saveConfirmSchedule(false));
    });
  };

  useEffect(() => {
    getHistory({});
    if (access_token && nickName === '') getPersonalData({});
    dispatch(saveCalendarTypeSelected(JourneyManagerInitialState));
  }, []);

  useEffect(() => {
    if (haveToAnswerAnamnese || confirm) {
      loadJourneyHistory();
    }
  }, [haveToAnswerAnamnese, confirm]);

  useEffect(() => {
    if (dataOyana) {
      dispatch(saveOyanaPersonaData(dataOyana));
    }
  }, [dataOyana]);

  useEffect(() => {
    if (data) {
      const activeJourneys = data.filter(resp => {
        const inProgressSchedule = getCurrentSchedule(resp);
        if (!inProgressSchedule) return;
        if (resp.status !== 'CANCELED' && resp.status !== 'DONE') {
          return resp;
        }
      });
      dispatch(saveActiveJourneys(activeJourneys));

      const doneJournyes = data.filter(resp => resp.status === 'DONE');
      dispatch(saveDoneJourneys(doneJournyes));
      setHistoric(doneJournyes);

      if (activeJourneys.length <= 0) {
        setNoActiveJourneys(true);
      }
    }
  }, [data]);

  return (
    <>
      <TemplateDefault>
        <FullHeightOnly>
          <Grid container>
            <Grid item xs={0} sm={1} md={3}></Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Typography variant="h2" mb={5}>
                Jornadas atuais
              </Typography>
              {!isLoading ? <></> : <CircularProgress sx={{ color: 'brand.darkGreen' }} />}

              {!isLoading && (
                <>
                  <Box>
                    <JourneyActionManager variant={'pending'} />
                  </Box>
                  {noActiveJourneys && (
                    <Box>
                      <JourneyAction
                        icon={<img alt={'Calendar Icon'} src={CalendarIcon} className="image" width={51} />}
                        title="Não há jornadas programadas... ainda!"
                        subtitle="Planeje uma consulta e mantenha a sua saúde em dia com acolhimento do início ao fim"
                      >
                        <Link
                          href={`${process.env.REACT_APP_SCHEDULE_CLIENT_URL}/selecao-de-servicos`}
                          sx={{ textDecoration: 'none' }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowOutwardOutlinedIcon />}
                            onClick={() => {
                              customEventTracking.send({
                                event: 'agendar_home_mc'
                              });
                            }}
                          >
                            Agendar agora
                          </Button>
                        </Link>
                      </JourneyAction>
                    </Box>
                  )}

                  <Typography variant="h3" mt={5} mb={5}>
                    Meus arquivos
                  </Typography>

                  <MyFiles />

                  {historic?.length !== 0 && (
                    <TittleButton
                      onClick={() => {
                        customEventTracking.send({
                          event: 'clique_jornada_histórico_mc'
                        });
                        navigate('/historico');
                      }}
                      title="Histórico"
                    />
                  )}
                </>
              )}
              {scheduling === 'initialize' && <Calendar />}
            </Grid>
            <Grid item xs={0} sm={1} md={3}></Grid>
          </Grid>
        </FullHeightOnly>
      </TemplateDefault>
    </>
  );
};

export default Home;
