import { FC, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider } from '../Divider';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { ItemButtonIcon } from './style';
import Button from '@mui/material/Button';

interface Props {
  onClick?: () => void;
  text: string;
  subText?: string;
  subText2?: string;
  icon?: ReactElement<SvgIconTypeMap>;
  moreInfo?: boolean;
}

export const ItemButtonSimple: FC<Props> = ({ onClick, text, icon, subText, subText2, moreInfo = false }) => {
  return (
    <Box>
      <Box>
        <Box mt={2} mb={2}>
          <Divider sx={{ mt: 0, mb: 0 }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={onClick}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ItemButtonIcon>{icon}</ItemButtonIcon>
            <Box>
              <Box>{text && <Typography variant="body1">{text}</Typography>}</Box>
              <Box>{subText && <Typography variant="body2R">{subText}</Typography>}</Box>
              <Box>{subText2 && <Typography variant="body2R">{subText2}</Typography>}</Box>
            </Box>
          </Box>
          <Box>
            {moreInfo ? (
              <Button variant="inline" color="primary" sx={{ mb: 3 }}>
                ver detalhes
              </Button>
            ) : (
              <ChevronRightOutlinedIcon sx={{ color: 'neutrals.beige4' }} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemButtonSimple;
