import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { AwaitingPaymentLarge } from '../AwaitingPayment';
import { AwaitingAnamneselarge } from '../AwaitingAnamnese';
import { AwaitingScheduleReturnLarge } from '../AwaitingScheduleReturn';
import { EnumJourneyAction, getJourneyAction } from '../../../../utils/enum/journeyAction';
import { AwaitingScheduleLarge } from '../AwaitingSchedule';
import FlexibleReturnProvider from '../../../../providers/FlexibleReturnProvider';

const JourneyCurrent = () => {
  const { journey } = useSelector((state: RootState) => state);

  const status = getJourneyAction(journey);
  if (status === EnumJourneyAction.AWAITING_PAYMENT) return <AwaitingPaymentLarge journey={journey} />;
  if (
    (status === EnumJourneyAction.AWAITING_CONSULTATION && journey.haveToAnswerAnamnese) ||
    (status === EnumJourneyAction.VIDEOCALL_AVAILABLE && journey.haveToAnswerAnamnese) ||
    (status === EnumJourneyAction.AWAITING_EXAM && journey.haveToAnswerAnamnese)
  )
    return <AwaitingAnamneselarge journey={journey} />;
  if (status === EnumJourneyAction.AWAITING_SCHEDULE_RETURN)
    return (
      <FlexibleReturnProvider journey={journey}>
        <AwaitingScheduleReturnLarge journey={journey} />
      </FlexibleReturnProvider>
    );
  if (status === EnumJourneyAction.AWAITING_SCHEDULE) return <AwaitingScheduleLarge journey={journey} />;

  return <></>;
};

export default JourneyCurrent;
