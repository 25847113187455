import BasicModal from '../../../../../shared/Modal/ModalBasic';
import Typography from '@mui/material/Typography';

interface ModalProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
}

const Modal = ({ openCloseController, onCloseHandler }: ModalProps) => {
  return (
    <>
      <BasicModal open={openCloseController} onCloseHandler={onCloseHandler} title="Como funciona a coleta do Exame AMH">
        <Typography variant="body1" sx={{ mb: 4 }}>
          Você recebe a enfermeira no endereço informado e faz a coleta com o conforto e a segurança que merece.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          É um exame de sangue normal, com coleta rapidinha, e pode ser feito em qualquer período do mês (inclusive se você
          estiver menstruada). O resultado fica pronto em até 5 dias úteis e você pode marcar sua consulta com especialista.
        </Typography>
      </BasicModal>
    </>
  );
};

export default Modal;
