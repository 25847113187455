import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

export const ReciveItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.neutrals.beige1,
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  boxShadow: `0px 4px 8px 0px  ${theme.palette.neutrals.lead}2b`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.neutrals.beige2
  }
}));

export const ReciveItemIcon = styled(Box)(({ theme }) => ({
  width: '48px',
  height: '48px',
  display: 'flex',
  borderRadius: '100%',
  backgroundColor: theme.palette.neutrals.beige3,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2)
}));

export const ReciveItemText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: theme.spacing(1),
  marginTop: theme.spacing(2)
}));

export const Arrow = styled(Box)(() => ({}));
