import { formatDateLabel } from '../../../../lib/dayjs';
import ItemButtonSimple from '../../ItemButtonSimple';
import { JourneyActionPayload, getCurrentSchedule, getScheduleType } from '..';
import { Button as ButtonSmall } from '../style';
import { Divider } from '../../Divider';
import Link from '@mui/material/Link';

export const VideoCallAvailableSmall = ({ journey }: JourneyActionPayload) => {
  const currentSchedule = getCurrentSchedule(journey);

  return (
    <>
      <ItemButtonSimple
        journeyId={journey?.journeyid}
        text={journey?.product.name || ''}
        event="clique_jornada_mc"
        subText={`${getScheduleType(currentSchedule?.type || '')}: ${formatDateLabel(currentSchedule?.dateStart || '')}`}
        subText2={journey?.doctor?.name}
        button={
          <Link href={currentSchedule?.teleUrl} target="_blank">
            <ButtonSmall variant="contained">Entrar na videochamada</ButtonSmall>
          </Link>
        }
      />
      <Divider />
    </>
  );
};
