import { useDispatch, useSelector } from 'react-redux';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { formatDateLabel } from '../../../../lib/dayjs';
import { RootState } from '../../../../store';
import ItemButtonSimple from '../../ItemButtonSimple';
import JourneyAction from '../../JourneyAction';
import CircleIcon from '../../CircleIcon';
import { JourneyActionPayload, getCurrentSchedule, getScheduleType } from '..';
import Button from '@mui/material/Button';
import { Button as ButtonSmall } from '../style';
import { initizalizeScheduleAppointment } from '../../../../features/schedule/schedule-slice';
import { initialState as journeyInitialState, saveCurrentJourney } from '../../../../features/journey';
import { EnumJourneyAction, getJourneyAction } from '../../../../utils/enum/journeyAction';
import { Divider } from '../../Divider';
import { customEventTracking } from '../../../../lib/gtm';

export const AwaitingScheduleSmall = ({ journey }: JourneyActionPayload) => {
  const {
    schedule: { confirm }
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const currentSchedule = getCurrentSchedule(journey);
  const status = getJourneyAction(journey || journeyInitialState);

  return (
    <>
      <ItemButtonSimple
        journeyId={journey?.journeyid}
        text={journey?.product.name || ''}
        event="clique_jornada_mc"
        subText={`${getScheduleType(currentSchedule?.type || '')}: ${formatDateLabel(currentSchedule?.dateStart || '')}`}
        subText2={journey?.doctor?.name}
        button={
          confirm &&
          status !== EnumJourneyAction.AWAITING_SCHEDULE &&
          status !== EnumJourneyAction.AWAITING_SCHEDULE_RETURN ? (
            <> </>
          ) : (
            <ButtonSmall
              variant="contained"
              onClick={() => {
                dispatch(saveCurrentJourney(journey));
                dispatch(initizalizeScheduleAppointment());
                customEventTracking.send({
                  event: 'agendar_lista_jornada_mc'
                });
              }}
            >
              Agendar agora
            </ButtonSmall>
          )
        }
      />
      <Divider />
    </>
  );
};

export const AwaitingScheduleLarge = ({ journey }: JourneyActionPayload) => {
  const {
    schedule: { confirm }
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  if (confirm) return <></>;

  return (
    <JourneyAction
      icon={<CircleIcon border size="60px" icon={<WarningAmberIcon />} />}
      title="Agende sua consulta"
      subtitle={`Seu exame está pronto, agora você só precisa escolher o melhor horário pra sua consulta com ginecologista especialista em fertilidade`}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch(saveCurrentJourney(journey));
          dispatch(initizalizeScheduleAppointment());
          customEventTracking.send({
            event: 'agendar_jornada_mc'
          });
        }}
      >
        Agendar agora
      </Button>
    </JourneyAction>
  );
};
