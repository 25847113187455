import TemplateFull from '../../layout/TemplateFull';
import AnamneseProvider from '../../providers/AnamneseProvider';
import AnamneseRoot from '../../components/pages/Anamnese';

const Anamnese = () => {
  return (
    <AnamneseProvider>
      <TemplateFull>
        <AnamneseRoot />
      </TemplateFull>
    </AnamneseProvider>
  );
};

export default Anamnese;
