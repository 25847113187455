import { styled } from '@mui/material/styles';
import MuiLink from '@mui/material/Link';

export const LinkContant = styled(MuiLink)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.neutrals.beige1,
  verticalAlign: 'middle',
  textDecoration: 'none',
  '&:first-of-type': {
    marginRight: theme.spacing(1),
  },
  '&:hover': {
    textDecoration: 'underline',
  },
  'svg': {
    verticalAlign: 'middle',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    '&:first-of-type': {
      marginBottom: theme.spacing(1),
    },
  },
}));
