import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { formatDateLabel } from '../../../../lib/dayjs';
import ItemButtonSimple from '../../ItemButtonSimple';
import JourneyAction from '../../JourneyAction';
import CircleIcon from '../../CircleIcon';
import { JourneyActionPayload, getCurrentSchedule, getScheduleType } from '..';
import { Divider } from '../../Divider';
import { customEventTracking } from '../../../../lib/gtm';
import { Button as ButtonSmall } from '../style';

export const AwaitingAnamneseSmall = ({ journey }: JourneyActionPayload) => {
  const currentSchedule = getCurrentSchedule(journey);
  const anamneseURL = journey.haveToAnswerAnamnese;
  return (
    <>
      <ItemButtonSimple
        journeyId={journey?.journeyid}
        text={journey?.product.name || ''}
        event="clique_jornada_mc"
        subText={`${getScheduleType(currentSchedule?.type || '')}: ${formatDateLabel(currentSchedule?.dateStart || '')}`}
        subText2={journey?.doctor?.name}
        button={
          <ButtonSmall
            variant="contained"
            onClick={() => {
              window.open(anamneseURL, '_self');
              customEventTracking.send({
                event: 'home_open_anamnese_mc'
              });
            }}
          >
            Responda às perguntas médicas
          </ButtonSmall>
        }
      />
      <Divider />
    </>
  );
};

export const AwaitingAnamneselarge = ({ journey }: JourneyActionPayload) => {
  const anamneseURL = journey.haveToAnswerAnamnese;
  return (
    <JourneyAction
      icon={<CircleIcon border size="60px" icon={<WarningAmberIcon />} />}
      title="Responda às perguntas médicas"
      subtitle={`As perguntas te ajudam a aproveitar melhor o tempo da sua consulta. Leva só 5 minutos!`}
    >
      <ButtonSmall
        variant="contained"
        onClick={() => {
          window.open(anamneseURL, '_self');
          customEventTracking.send({
            event: 'jouney_open_anamnese_mc'
          });
        }}
      >
        Responda às perguntas médicas
      </ButtonSmall>
    </JourneyAction>
  );
};
