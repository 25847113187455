import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';

import ImageHero from '../../images/pana.png';
import { FullHeightOnly } from '../../components/shared/Grid';
import TemplateDefault from '../../layout/TemplateDefault';
import ReceivingMethod from '../../components/pages/Login/ReceivingMethod';

const LoginMethod = () => {
  return (
    <TemplateDefault>
      <FullHeightOnly container>
        <Grid xs={1} order={1} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
        <Grid xs={12} md={4} order={{ xs: 2, md: 4 }} sx={{ display: 'flex', alignItems: { xs: 'center', md: 'initial' } }}>
          <Box sx={{ mb: 5 }}>
            <CardMedia component="img" alt="computador" image={ImageHero} sx={{ width: { xs: 200, md: '100%' } }} />
          </Box>
        </Grid>

        <Grid xs={1} order={3} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
        <Grid xs={12} md={4} order={{ xs: 4, md: 2 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            Receber o código por
          </Typography>
          <ReceivingMethod />
        </Grid>
        <Grid xs={2} order={5} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
      </FullHeightOnly>
    </TemplateDefault>
  );
};

export default LoginMethod;
