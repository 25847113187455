import { api } from './api.service';

export type IRatingRequest = {
  scheduleId: string;
  rate: number;
  comment?: string;
};

export type IRatingNoShowRequest = {
  scheduleId: string;
};

export const CSAT = api.injectEndpoints({
  endpoints: build => ({
    saveRating: build.mutation<any, IRatingRequest>({
      query: data => ({
        url: `/schedule/${data.scheduleId}/csat`,
        method: 'PUT',
        body: {
          rate: data.rate,
          comment: data.comment
        },
        headers: {}
      })
    }),
    noshow: build.mutation<any, IRatingNoShowRequest>({
      query: data => ({
        url: `/schedule/${data.scheduleId}/csat/noshow`,
        method: 'PUT',
        body: {},
        headers: {}
      })
    })
  })
});

export const { useSaveRatingMutation, useNoshowMutation } = CSAT;
