import { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import MuiModal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { ModalWrapper, ModalContent, ModalExtraContent, ModalHeader, ModalBoxContent } from './styled';

interface ModalProps {
  title?: string | undefined;
  open: boolean;
  onCloseHandler: () => void;
  extraContent?: React.ReactNode;
  typeTitle?: 'h3';
}

const Modal = ({ open, onCloseHandler, title, children, extraContent, typeTitle }: PropsWithChildren<ModalProps>) => {
  return (
    <MuiModal
      keepMounted
      open={open}
      onClose={onCloseHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapper>
        <ModalContent>
          <ModalHeader>
            <CloseIcon onClick={onCloseHandler} sx={{ cursor: 'pointer' }} />
          </ModalHeader>
          <ModalBoxContent sx={{ px: '1px' }} id="modal-modal-description">
            {title && (
              <Typography id="modal-modal-title" variant={typeTitle ? typeTitle : 'h2'} sx={{ mb: 2 }}>
                {title}
              </Typography>
            )}
            {children}
          </ModalBoxContent>
        </ModalContent>
        {extraContent && <ModalExtraContent>{extraContent}</ModalExtraContent>}
      </ModalWrapper>
    </MuiModal>
  );
};

export default Modal;
