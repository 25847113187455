import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { EnumJourneyAction, getJourneyAction } from '../../../../utils/enum/journeyAction';
import { RootState } from '../../../../store';
import { Dialog, DialogTitle } from '../styled';
import { formatTitleByJourneyStatus } from '../utis';
import { closeScheduleAppointment, doneScheduleAppointment } from '../../../../features/schedule/schedule-slice';
import ScheduleAppointment from './ScheduleAppointment';
import RescheduleAppointment from './RescheduleAppointment';
import RescheduleExam from './RescheduleExam';
import ScheduleReturn from './ScheduleReturn';

interface IConfirmation {
  open: boolean;
}
const Confirmation = ({ open }: IConfirmation) => {
  const { journey } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const status = getJourneyAction(journey);
  return (
    <Dialog open={open}>
      <DialogTitle>
        <CloseIcon
          onClick={() => {
            dispatch(doneScheduleAppointment({ closeAllModals: true }));
            dispatch(closeScheduleAppointment(true));
          }}
          sx={{ cursor: 'pointer', mb: 3 }}
        />
        <Typography variant="h3" sx={{ mb: 2 }}>
          {formatTitleByJourneyStatus(journey)}
        </Typography>
      </DialogTitle>
      {status === EnumJourneyAction.AWAITING_SCHEDULE && <ScheduleAppointment />}
      {(status === EnumJourneyAction.AWAITING_CONSULTATION || status === EnumJourneyAction.VIDEOCALL_AVAILABLE) && (
        <RescheduleAppointment />
      )}
      {status === EnumJourneyAction.AWAITING_EXAM && <RescheduleExam />}
      {status === EnumJourneyAction.AWAITING_SCHEDULE_RETURN && <ScheduleReturn />}
    </Dialog>
  );
};

export default Confirmation;
