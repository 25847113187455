import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import BasicModal from '../../../Modal/ModalBasic';
import { FC } from 'react';
import { ButtonCustom } from './styled';
import Typography from '@mui/material/Typography';
import {
  ICalendarTypeSelected,
  saveCalendarTypeSelected
} from '../../../../../features/journeyManager/journey-manager-slice';
import { initizalizeScheduleAppointment } from '../../../../../features/schedule/schedule-slice';

interface ChooseReturnTypeProps {
  onCloseHandler: () => void;
  open: boolean;
  calendarTypeSelected?: Array<ICalendarTypeSelected>;
}

export const ChooseReturnType: FC<ChooseReturnTypeProps> = ({ onCloseHandler, open, calendarTypeSelected }) => {
  const dispatch = useDispatch();
  const formatTitlebyCalendarType = (calendarTypeSelected: ICalendarTypeSelected) => {
    switch (calendarTypeSelected?.type) {
      case 'VIRTUAL_CONSULTATION':
        return 'Consulta Online';
      default:
        return 'Consulta Presencial em São Paulo';
    }
  };

  const CalendarTypeItem = calendarTypeSelected
    ?.sort((a, b) => a.type.localeCompare(b.type))
    .map((calendar, key) => {
      return (
        <Box key={key} mb={1} mt={1}>
          <ButtonCustom
            onClick={() => {
              dispatch(saveCalendarTypeSelected(calendar));
              dispatch(initizalizeScheduleAppointment());
            }}
          >
            <Typography variant="body2B">{formatTitlebyCalendarType(calendar)}</Typography>
          </ButtonCustom>
        </Box>
      );
    });

  return (
    <>
      <BasicModal open={open} onCloseHandler={onCloseHandler} title={'Agendar retorno'} typeTitle="h3">
        <Typography>Como você gostaria de realizar seu retorno?</Typography>
        {CalendarTypeItem}
      </BasicModal>
    </>
  );
};

export default ChooseReturnType;
