import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { IQuestion } from '../../../../../providers/AnamneseProvider/context';
import { Controller, useFormContext } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import MaskedInput from 'react-text-mask';

interface TextProps {
  question: IQuestion;
  nextHandler: () => void;
}

const UnitMeasure = ({ question, nextHandler }: TextProps) => {
  const {
    control,
    formState: { errors },
    register,
    trigger
  } = useFormContext();

  useEffect(() => {
    trigger(question.id);
  }, []);

  return (
    <>
      <Controller
        name={question.id}
        control={control}
        defaultValue={question.isRecovery ? question.answer : ''}
        rules={{
          required: question.isRequired
        }}
        render={() => (
          <MaskedInput
            mask={[/\d/, /\d/, /\d/]}
            guide={false}
            id={question.id}
            render={(ref, props) => (
              <TextField
                {...props}
                inputRef={ref}
                type="tel"
                autoComplete="measure"
                variant="standard"
                fullWidth
                placeholder={question.tip}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{question.unitOfMeasurementType}</InputAdornment>
                }}
                inputProps={{
                  maxLength: 3
                }}
                onKeyUp={() => {
                  trigger(question.id);
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    if (errors[question.id]) return;
                    nextHandler();
                  }
                }}
                {...register(question.id, {
                  onBlur: () => trigger(question.id),
                  required: question.isRequired,
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'invalid caracter'
                  }
                })}
              />
            )}
          />
        )}
      />
    </>
  );
};

export default UnitMeasure;
