import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BackButton } from '../../style';
import useAnamnese from '../../../../../providers/AnamneseProvider/use';

const BackAction = () => {
  const {
    state: { step },
    actions: { stepBack }
  } = useAnamnese();
  return (
    <>
      {step.current > 0 ? (
        <BackButton aria-label="delete" color="primary" onClick={() => stepBack()}>
          <ArrowBackIcon />
        </BackButton>
      ) : (
        []
      )}
    </>
  );
};

export default BackAction;
