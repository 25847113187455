import Typography from '@mui/material/Typography';
import useAnamnese from '../../../../../providers/AnamneseProvider/use';
import Box from '@mui/material/Box';
import { LinearProgress } from '../../style';
import { IForm } from '../../../../../providers/AnamneseProvider/context';

const FormProgressBar = () => {
  const {
    state: { form, step, isFinish }
  } = useAnamnese();

  const calcPercent = (value: number): number => {
    const percent =
      ((value - step.question.initial) * 100) / (step.question.total[step.category.current] - step.question.initial);
    return percent;
  };

  const getCategoryName = (form: IForm): string => {
    if (Object.keys(form).length === 0) return '';
    return form?.categories[step.category.current].category.title;
  };

  let progress = 0;
  if (isFinish) {
    progress = 100;
  } else progress = calcPercent(step.question.current);

  const titleCategory = getCategoryName(form);

  return (
    <>
      <Box mb={2}>
        <Typography variant="body1" color="neutrals.beige1">
          {titleCategory}
        </Typography>
      </Box>
      <Box>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </>
  );
};

export default FormProgressBar;
