import { PropsWithChildren } from 'react';
import AppBar from '../../components/shared/AppBar';
import Footer from '../Footer';
import Header from '../Header';
import Main from '../Main';

const TemplateDefault = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Header>
        <AppBar />
      </Header>
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default TemplateDefault;
