export const numberWithoutMask = (value: string): string => value.replace(/[^\d]/g, '');

export const formatCurrency = (value: number, currency: string): string => {
  const formatter = new Intl.NumberFormat([], {
    style: 'currency',
    currency
  });

  return formatter.format(value);
};
