import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ECalendarType, IJourneyResp as IJourneyResponse } from '../../services/journey.service';

interface Journeys {
  activeJourneys: IJourneyResponse[];
  doneJourneys: IJourneyResponse[];
}

const initialState: Journeys = {
  activeJourneys: [
    {
      status: '',
      journeyid: '',
      schedules: [
        {
          address: {
            address: '',
            addressComplement: '',
            addressNumber: '',
            addressReference: '',
            cep: '',
            city: '',
            neighborhood: '',
            state: ''
          },
          dateStart: '',
          dateEnd: '',
          id: '',
          returnSchedule: false,
          status: '',
          type: '',
          calendarId: '',
          teleUrl: '',
          allowReschedule: false,
          isNeedCollectCSAT: false
        }
      ],
      payments: [],
      product: {
        id: '',
        name: ''
      },
      haveToAnswerAnamnese: '',
      doctor: {
        name: '',
        profileImage: '',
        profileUrl: '',
        id: ''
      },
      steps: {
        currentStep: '',
        nextSteps: '',
        previousSteps: ''
      },
      calendars: [
        {
          durationEvent: 0,
          id: '',
          type: ''
        }
      ],
      calendarSecundary: {
        durationEvent: 0,
        id: '',
        type: ''
      },
      files: {
        exams: [],
        reports: [],
        requests: []
      },
      calendarTypes: [
        {
          calendarId: '',
          id: '',
          type: ECalendarType.MAIN
        }
      ]
    }
  ],
  doneJourneys: [
    {
      status: '',
      journeyid: '',
      schedules: [
        {
          address: {
            address: '',
            addressComplement: '',
            addressNumber: '',
            addressReference: '',
            cep: '',
            city: '',
            neighborhood: '',
            state: ''
          },
          dateStart: '',
          dateEnd: '',
          id: '',
          returnSchedule: false,
          status: '',
          type: '',
          calendarId: '',
          teleUrl: '',
          allowReschedule: false,
          isNeedCollectCSAT: false
        }
      ],
      payments: [],
      product: {
        id: '',
        name: ''
      },
      haveToAnswerAnamnese: '',
      doctor: {
        name: '',
        profileImage: '',
        profileUrl: '',
        id: ''
      },
      steps: {
        currentStep: '',
        nextSteps: '',
        previousSteps: ''
      },
      calendars: [
        {
          durationEvent: 0,
          id: '',
          type: ''
        }
      ],
      calendarSecundary: {
        durationEvent: 0,
        id: '',
        type: ''
      },
      files: {
        exams: [],
        reports: [],
        requests: []
      },
      calendarTypes: [
        {
          calendarId: '',
          id: '',
          type: ECalendarType.MAIN
        }
      ]
    }
  ]
};

export const Journeys = createSlice({
  name: 'journeys',
  initialState,
  reducers: {
    saveActiveJourneys: (state, action: PayloadAction<IJourneyResponse[]>) => {
      state.activeJourneys = action.payload;
    },
    saveDoneJourneys: (state, action: PayloadAction<IJourneyResponse[]>) => {
      state.doneJourneys = action.payload;
    }
  }
});

export const { saveActiveJourneys, saveDoneJourneys } = Journeys.actions;
export const journeysReducer = Journeys.reducer;
