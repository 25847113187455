import Button from '@mui/material/Button';
import BasicModal from '../../../../../shared/Modal/ModalBasic';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface ModalProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
}

const Modal = ({ openCloseController, onCloseHandler }: ModalProps) => {
  return (
    <>
      <BasicModal open={openCloseController} onCloseHandler={onCloseHandler} title="Cancelar jornada">
        <Typography variant="body1" sx={{ mb: 4 }}>
          Para cancelar seu agendamento, entre em contato com nosso time de atendimento.
        </Typography>

        <Button
          variant="inline"
          color="primary"
          sx={{ mb: 3 }}
          endIcon={<WhatsAppIcon />}
          href={process.env.REACT_APP_WHATSAPP || ''}
          target="_blank"
        >
          Entrar em contato
        </Button>
      </BasicModal>
    </>
  );
};

export default Modal;
