import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextFieldMui from '@mui/material/TextField';
import RatingMui from '@mui/material/Rating';
import { styled } from '@mui/material/styles';

export const RationgAction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignContent: 'flex-start'
  }
}));

export const ButtonNoShow = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2)
  },
  cursor: 'pointer',
  color: theme.palette.brand.darkGreen,
  ':hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  },
  '&.disabled': {
    color: `${theme.palette.brand.darkGreen}60`,
    cursor: 'not-allowed',
    textDecorationColor: `${theme.palette.brand.darkGreen}60`
  }
}));

export const RatingResulIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  borderRadius: '100%',
  backgroundColor: theme.palette.neutrals.beige3,
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3)
}));

export const Rating = styled(RatingMui)(({ theme }) => ({
  '& .MuiRating-icon': {
    color: theme.palette.brand.darkGreen
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.brand.darkGreen
  }
}));

export const TextField = styled(TextFieldMui)(({ theme }) => ({
  '&.Mui-focused': {
    outLineColor: `${theme.palette.brand.darkGreen} !important`
  }
}));
