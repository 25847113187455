import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import MuiIconButton from '@mui/material/IconButton';

const LoginButtonDesktop = styled(Button)(({ theme }) => ({
  color: theme.palette.neutrals.beige1,
  borderRadius: 40,
  '&:hover': {
    backgroundColor: theme.palette.colored.green2
  }
}));

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.lightGreen,
  '&:hover': {
    backgroundColor: theme.palette.brand.lightGreen
  }
}));

export default LoginButtonDesktop;
