import { useDispatch } from 'react-redux';
import BasicModal from '../../../../../shared/Modal/ModalBasic';
import { ButtonCustom } from '../../../../../shared/JourneyActionManager/AwaitingScheduleReturn/ChooseReturnType/styled';
import Box from '@mui/material/Box';
import {
  initizalizeScheduleAppointment,
  rescheduleWithSameDoctor
} from '../../../../../../features/schedule/schedule-slice';

interface ModalProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
  doctorName: string;
}

const ModalReschedule = ({ openCloseController, onCloseHandler, doctorName }: ModalProps) => {
  const dispatch = useDispatch();
  return (
    <>
      <BasicModal
        open={openCloseController}
        onCloseHandler={onCloseHandler}
        title="Como você gostaria de reagendar?"
        typeTitle="h3"
      >
        <Box mb={1} mt={1}>
          <ButtonCustom
            onClick={() => {
              dispatch(rescheduleWithSameDoctor(true));
              dispatch(initizalizeScheduleAppointment());
            }}
          >
            Com a mesma médica (Dra. {doctorName})
          </ButtonCustom>
        </Box>
        <Box mb={1} mt={1}>
          <ButtonCustom
            onClick={() => {
              dispatch(rescheduleWithSameDoctor(false));
              dispatch(initizalizeScheduleAppointment());
            }}
          >
            Por disponibilidade de horário (todas as médicas)
          </ButtonCustom>
        </Box>
      </BasicModal>
    </>
  );
};

export default ModalReschedule;
