import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import MuiMenuItem from '@mui/material/MenuItem';

export const List = styled(Popper)(({ theme }) => ({
  '.MuiPaper-root': {
    backgroundColor: theme.palette.neutrals.beige2,
    border: `solid 1px ${theme.palette.neutrals.beige4}`,
    borderRadius: 16,
    marginTop: 2,
    minWidth: 220,
    boxShadow: 'none'
  },
  '.MuiList-root': {
    padding: 0
  },
  '.MuiButtonBase-root': {
    a: {
      color: theme.palette.brand.darkGreen,
      textDecoration: 'none',
      '&:visited': {
        color: theme.palette.brand.darkGreen
      }
    },
    color: theme.palette.brand.darkGreen,
    fontWeight: 400
  }
}));

export const WrapperAvatar = styled(Box)(() => ({
  position: 'relative',
  '&:hover': {
    cursor: 'pointer'
  }
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(2, 2),
  '.MuiTypography-root': {
    display: 'flex',
    alignItems: 'center'
  },
  '.MuiSvgIcon-root': {
    marginRight: theme.spacing(1)
  }
}));

export const CustomIcon = styled('span')(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(1)
}));
