import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
  closeScheduleAppointment,
  doneScheduleAppointment,
  updateScheduleSteps
} from '../../../../features/schedule/schedule-slice';
import { formatAvailableDaysByDatePicker } from '../../../../lib/dayjs';
import { Dialog, DialogContent, DialogTitle } from '../styled';
import { formatTitleByJourneyStatus } from '../utis';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { RootState } from '../../../../store';
import AvailableTimes from '../../Schedules/AvailableTimes';
import { IAppointments } from '../../Schedules/hooks';

interface ISelectAvailableTimes {
  open: boolean;
  isLoading: boolean;
  appointments: IAppointments;
}

const SelectAvailableTimes = ({ open, isLoading, appointments }: ISelectAvailableTimes) => {
  const {
    schedule: { selectedDay, dateStart },
    journey
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  return (
    <Dialog open={open} scroll="paper">
      <DialogTitle>
        <CloseIcon
          onClick={() => {
            dispatch(doneScheduleAppointment({ closeAllModals: false }));
            dispatch(closeScheduleAppointment(true));
          }}
          sx={{
            cursor: 'pointer',
            mb: 3
          }}
        />

        <Typography variant="h3" sx={{ mb: 2 }}>
          {formatTitleByJourneyStatus(journey)}
        </Typography>
      </DialogTitle>
      <Typography variant="body1" sx={{ mb: 3 }}>
        {formatAvailableDaysByDatePicker(selectedDay)}
      </Typography>
      <DialogContent>
        <AvailableTimes appointments={appointments} isLoading={isLoading} />
      </DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            dispatch(updateScheduleSteps('openCalendar'));
          }}
        >
          Voltar
        </Button>
        <Button
          disabled={dateStart.length <= 0}
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={() => dispatch(updateScheduleSteps('confirmSchedule'))}
        >
          Continuar
        </Button>
      </Box>
    </Dialog>
  );
};

export default SelectAvailableTimes;
