import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { journeyReducer } from './features/journey';
import { journeysReducer } from './features/journeys';
import { notificationsReducer } from './features/notifications/notifications-slice';
import { scheduleReducer } from './features/schedule/schedule-slice';
import { singInReducer } from './features/singin/singin-slice';
import { oyanaReducer } from './features/me/me-slice';
import { journeyManagerReducer } from './features/journeyManager/journey-manager-slice';
import { anamneseReducer } from './features/anamnese';
import { redirectReducer } from './features/redirect';
import { fileUploadReducer } from './features/fileUpload';

import { api, rtkQueryErrorLogger } from './services/api.service';

import { api as anamneseApi } from './services/anamnese.api.service';

const rootReducer = combineReducers({
  journey: journeyReducer,
  journeys: journeysReducer,
  schedule: scheduleReducer,
  singIn: singInReducer,
  notifications: notificationsReducer,
  oyana: oyanaReducer,
  journeyManager: journeyManagerReducer,
  anamnese: anamneseReducer,
  redirect: redirectReducer,
  fileUpload: fileUploadReducer,
  [api.reducerPath]: api.reducer,
  [anamneseApi.reducerPath]: anamneseApi.reducer
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(api.middleware).concat([rtkQueryErrorLogger]).concat(anamneseApi.middleware),
    preloadedState
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
