import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IAppointmentsAvailable, useAllAvailableDaysOfTheMonth, useAppointmentsAvailable } from '../Schedules/hooks';
import CustomMobileDatePicker from '../MobileDatePicker';
import { updateScheduleSteps } from '../../../features/schedule/schedule-slice';
import { EnumJourneyAction, getJourneyAction } from '../../../utils/enum/journeyAction';
import Confirmation from './Confirmation';
import { formatTitleByJourneyStatus } from './utis';
import SelectAvailableTimes from './SelectAvailableTimes';
import SendFile from './SendFiles';
import { getCurrentSchedule } from '../JourneyActionManager';
import { initialState } from '../../../features/journey';
import { useEffect } from 'react';
import { AvailableTimesType } from '../../../services/availableTimes.service';

const Calendar = () => {
  const {
    schedule: {
      dateStart,
      ui: { steps },
      rescheduleWithSameDoctor
    },
    journey,
    journey: { calendarSecundary, doctor },
    journeyManager: { calendarTypeSelected }
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const status = getJourneyAction(journey);
  const currentSchedule = getCurrentSchedule(journey) || initialState.schedules[0];
  const buildAppointmentParams = (): IAppointmentsAvailable => {
    //agendamento após exame
    if (status === EnumJourneyAction.AWAITING_SCHEDULE) {
      return {
        type: AvailableTimesType.SCHEDULE_AFTER_EXAM,
        calendarId: calendarSecundary.id,
        doctorId: ''
      };
    }
    // qualquer agendamento de retorno
    if (status === EnumJourneyAction.AWAITING_SCHEDULE_RETURN) {
      return {
        type: AvailableTimesType.SCHEDULE_RETURN,
        calendarId: calendarTypeSelected?.id || '',
        doctorId: doctor?.id ?? ''
      };
    }
    // reagendamento de retorno
    if (currentSchedule.returnSchedule) {
      return {
        type: AvailableTimesType.RESCHEDULE_RETURN,
        calendarId: currentSchedule.calendarId,
        doctorId: doctor?.id ?? ''
      };
    }
    // reagendamento com a mesma medica
    if (rescheduleWithSameDoctor && EnumJourneyAction.AWAITING_CONSULTATION) {
      return {
        type: AvailableTimesType.RESCHEDULE_WITH_SAME_DOCTOR,
        calendarId: currentSchedule.calendarId,
        doctorId: doctor?.id ?? ''
      };
    }
    // qualquer tipo de agendamento
    return { calendarId: currentSchedule.calendarId, doctorId: '' };
  };
  const { appointments, setCurrentMonth, setCurrentYear, setAppointments, isLoading } = useAppointmentsAvailable(
    buildAppointmentParams()
  );
  const [allAvailableDaysOfTheMonth] = useAllAvailableDaysOfTheMonth(appointments);
  useEffect(() => {
    if (steps === 'idle') {
      setAppointments({ availableList: [] });
    }
  }, [steps]);
  return (
    <>
      {steps === 'openCalendar' && (
        <CustomMobileDatePicker
          title={formatTitleByJourneyStatus(journey)}
          availableDays={allAvailableDaysOfTheMonth}
          updateYear={setCurrentYear}
          updateMonth={setCurrentMonth}
          isLoading={isLoading}
          onFinished={() => {
            dispatch(updateScheduleSteps('selectTime'));
          }}
        />
      )}
      {steps === 'selectTime' && (
        <SelectAvailableTimes
          open={steps === 'selectTime' ? true : false}
          isLoading={isLoading}
          appointments={appointments}
        />
      )}
      {steps === 'confirmSchedule' && dateStart.length > 0 && (
        <Confirmation open={steps === 'confirmSchedule' ? true : false} />
      )}

      {steps === 'sendFile' && <SendFile open={steps === 'sendFile' ? true : false} />}
    </>
  );
};

export default Calendar;
