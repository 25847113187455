import { formatDateLabel } from '../../../../lib/dayjs';
import ItemButtonSimple from '../../ItemButtonSimple';
import { JourneyActionPayload, getCurrentSchedule, getScheduleType } from '..';
import { Button as ButtonSmall } from '../style';
import { Divider } from '../../Divider';
import Modal from '../../../pages/Journey/ContentExams/Modal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export const MedicalRequestAvailableSmall = ({ journey }: JourneyActionPayload) => {
  const [open, setOpen] = useState(false);
  const {
    journey: { files }
  } = useSelector((state: RootState) => state);

  const handleOpen = () => {
    setOpen(!open);
  };

  const currentSchedule = getCurrentSchedule(journey);
  return (
    <>
      <ItemButtonSimple
        journeyId={journey?.journeyid}
        text={journey?.product.name || ''}
        event="clique_jornada_mc"
        subText={`${getScheduleType(currentSchedule?.type || '')}: ${formatDateLabel(currentSchedule?.dateStart || '')}`}
        subText2={journey?.doctor?.name}
        button={
          <ButtonSmall variant="contained" onClick={() => handleOpen()}>
            Ver exame
          </ButtonSmall>
        }
      />
      <Divider />
      <Modal
        openCloseController={open}
        onCloseHandler={() => {
          handleOpen();
        }}
        infoType={'REVENUES'}
        dataFiles={files}
      />
    </>
  );
};
