import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { CardMedia } from './styled';
import Link from '@mui/material/Link';

const Attend = () => {
  const {
    journey: { doctor }
  } = useSelector((state: RootState) => state);
  if (!doctor) return <></>;

  return (
    <Grid container alignItems={'center'}>
      <Grid xs={10}>
        <Typography variant="body1B" sx={{ mb: 1 }}>
          Médica responsável
        </Typography>
        <Typography sx={{ mb: 2 }}>{doctor?.name}</Typography>
        {doctor?.profileUrl?.length > 0 && (
          <Link href={doctor.profileUrl} target="_blank" sx={{ textDecoration: 'none' }}>
            <Button variant="inline" color="primary">
              Saiba mais
            </Button>
          </Link>
        )}
      </Grid>
      <Grid xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CardMedia component="img" alt={doctor.name} image={doctor.profileImage} />
      </Grid>
    </Grid>
  );
};

export default Attend;
