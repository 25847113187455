import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { BodyButton } from './styled';
import { IOption, IQuestion } from '../../../../../providers/AnamneseProvider/context';

interface SingleChoiceProps {
  question: IQuestion;
}

const SingleChoice: FC<SingleChoiceProps> = ({ question }) => {
  const { control, getValues, trigger } = useFormContext();
  const optionsList = (question: IQuestion): IOption[] => {
    let list: IOption[] = question?.options || [];
    if (question.allowOtherChoice) list = [...list, { id: 'other', label: 'Outro' }];
    return list;
  };
  const optionsListObject: IOption[] = optionsList(question);

  interface optionsChoice {
    option: IOption;
    otherChoice: string;
  }

  const setInitialValue = (question: IQuestion): optionsChoice => {
    // quando o usuário navega entre as perguntas e o valor já foi preenchido
    if (getValues(question.id)) return getValues(question.id);
    // quando a pergunta é recuperável
    if (question.isRecovery && question.answer) {
      const optionsAnswered = question.options?.find((option: IOption) => {
        return question.answer?.includes(option.label);
      });
      if (optionsAnswered) {
        return {
          option: optionsAnswered,
          otherChoice: ''
        };
      } else {
        return {
          option: {
            id: 'other',
            label: 'Outro'
          } as IOption,
          otherChoice: question.answer ? question.answer[0] : ''
        };
      }
    }
    // quando o usuario entra na pergunta pela primeira vez
    return {
      option: {} as IOption,
      otherChoice: ''
    };
  };

  const [optionsValueInitial, setOptionsValueInitial] = useState<optionsChoice>(setInitialValue(question));
  const validateOptions = (value: optionsChoice, isRequired: boolean): boolean => {
    if (!isRequired) return true;
    if (value.option.id === 'other' && value.otherChoice === '') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    trigger(question.id);
  }, []);

  return (
    <Box>
      <Controller
        control={control}
        defaultValue={optionsValueInitial}
        name={question.id}
        rules={{
          validate: (value: optionsChoice) => validateOptions(value, question.isRequired)
        }}
        render={({ field: { onChange } }) => {
          return (
            <Box display="flex" flexDirection="column" width="100%">
              {optionsListObject.map((option: IOption) => {
                return (
                  <BodyButton
                    key={option.id}
                    id={option.id}
                    sx={{
                      backgroundColor: optionsValueInitial.option.id === option.id ? 'brand.darkGreen' : 'neutrals.beige1',
                      color: optionsValueInitial.option.id === option.id ? 'neutrals.beige1' : 'brand.darkGreen'
                    }}
                    onClick={() => {
                      if (!question.isRequired && getValues(question.id).option.id === option.id) {
                        onChange({
                          ...optionsValueInitial,
                          option: {} as IOption
                        });
                        setOptionsValueInitial({
                          ...optionsValueInitial,
                          option: {} as IOption
                        });
                        trigger(question.id);
                      } else {
                        onChange({
                          ...optionsValueInitial,
                          option: option
                        });
                        setOptionsValueInitial({
                          ...optionsValueInitial,
                          option: option
                        });
                        trigger(question.id);
                      }
                    }}
                  >
                    {option.label}
                  </BodyButton>
                );
              })}
              {optionsValueInitial.option.id === 'other' ? (
                <TextField
                  value={optionsValueInitial.otherChoice}
                  placeholder="Descreva aqui"
                  type="text"
                  variant="standard"
                  fullWidth
                  onChange={e => {
                    setOptionsValueInitial({
                      ...optionsValueInitial,
                      otherChoice: e.target.value
                    });
                    onChange({
                      ...optionsValueInitial,
                      otherChoice: e.target.value
                    });
                    trigger(question.id);
                  }}
                />
              ) : (
                []
              )}
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default SingleChoice;
