interface IFileQueue {
  name: string;
  id?: string;
  sucess?: boolean;
  error?: boolean;
  current?: boolean;
}

class FileQueueProvider {
  queue: IFileQueue[];
  constructor(queuex: IFileQueue[]) {
    this.queue = queuex;
  }

  addFile(file: File) {
    this.queue.push({ name: file.name });
  }

  findByFileName(name: string) {
    return this.queue.find(file => file.name === name);
  }

  findCurrentByFileName(name: string) {
    return this.queue.find(file => file.name === name && file.current);
  }

  findErrorByFileName(name: string) {
    return this.queue.find(file => file.name === name && file.error);
  }

  setErroByFileName(name: string) {
    this.queue.forEach(file => {
      if (file.name === name) {
        file.error = true;
      }
    });
  }

  setSuccessByFileName(name: string, id: string) {
    this.queue.forEach(file => {
      if (file.name === name) {
        file.error = false;
        file.id = id;
      }
    });
  }

  setCurrentByFileName(name: string) {
    this.queue.forEach(file => {
      if (file.name === name) {
        file.current = true;
      }
    });
  }

  removeFromQueueByFileName(name: string) {
    this.queue = this.queue.filter(file => file.name !== name);
  }

  getFileByName(name: string) {
    return this.queue.find(file => file.name === name);
  }

  clean() {
    this.queue = [];
  }
}

export default FileQueueProvider;
