import { useDispatch, useSelector } from 'react-redux';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { formatDateLabel } from '../../../../lib/dayjs';
import { RootState } from '../../../../store';
import ItemButtonSimple from '../../ItemButtonSimple';
import JourneyAction from '../../JourneyAction';
import CircleIcon from '../../CircleIcon';
import { JourneyActionPayload, getCurrentSchedule, getScheduleType } from '..';
import { hasbackButton, initizalizeScheduleAppointment } from '../../../../features/schedule/schedule-slice';
import { initialState as journeyInitialState, saveCurrentJourney } from '../../../../features/journey';
import { EnumJourneyAction, getJourneyAction } from '../../../../utils/enum/journeyAction';
import Button from '@mui/material/Button';
import { Button as ButtonSmall } from '../style';
import { Divider } from '../../Divider';
import ChooseReturnType from './ChooseReturnType';
import { useEffect, useState } from 'react';
import {
  JourneyManagerInitialState,
  saveCalendarTypeSelected
} from '../../../../features/journeyManager/journey-manager-slice';
import useFlexibleReturn from '../../../../providers/FlexibleReturnProvider/use';

export const AwaitingScheduleReturnSmall = ({ journey }: JourneyActionPayload) => {
  const {
    schedule: {
      confirm,
      ui: { scheduling, handleClose }
    },
    journeyManager: { calendarTypeSelected }
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { calendarByCalendarType } = useFlexibleReturn();
  const currentSchedule = getCurrentSchedule(journey);
  const status = getJourneyAction(journey || journeyInitialState);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    dispatch(hasbackButton(false)); //reset
    dispatch(saveCalendarTypeSelected(JourneyManagerInitialState)); //reset
    setOpen(!open);
  };
  const handleActionReturn = () => {
    dispatch(saveCurrentJourney(journey));
    if (calendarByCalendarType.length > 1) {
      setOpen(true);
      dispatch(hasbackButton(true));
    } else {
      dispatch(saveCalendarTypeSelected(calendarByCalendarType[0]));
      dispatch(initizalizeScheduleAppointment());
    }
  };

  useEffect(() => {
    const toggle = () => {
      if (calendarByCalendarType.length <= 1 || scheduling === 'initialize' || handleClose) {
        return false;
      }

      if (calendarTypeSelected.id && calendarTypeSelected.id.length > 0) {
        return true;
      }
    };
    setOpen(toggle() || false);
  }, [scheduling, handleClose, calendarTypeSelected]);
  return (
    <>
      <ItemButtonSimple
        journeyId={journey?.journeyid}
        text={journey?.product.name || ''}
        event="clique_jornada_mc"
        subText={`${getScheduleType(currentSchedule?.type || '')}: ${formatDateLabel(currentSchedule?.dateStart || '')}`}
        subText2={journey?.doctor?.name}
        button={
          confirm &&
          status !== EnumJourneyAction.AWAITING_SCHEDULE &&
          status !== EnumJourneyAction.AWAITING_SCHEDULE_RETURN ? (
            <> </>
          ) : (
            <ButtonSmall variant="contained" onClick={() => handleActionReturn()}>
              Agendar retorno
            </ButtonSmall>
          )
        }
      />
      <Divider />
      <ChooseReturnType
        calendarTypeSelected={calendarByCalendarType}
        open={open}
        onCloseHandler={() => {
          handleOpen();
        }}
      />
    </>
  );
};

export const AwaitingScheduleReturnLarge = ({ journey }: JourneyActionPayload) => {
  const {
    schedule: {
      confirm,
      ui: { steps, scheduling, handleClose }
    },
    journeyManager: { calendarTypeSelected }
  } = useSelector((state: RootState) => state);
  const { calendarByCalendarType } = useFlexibleReturn();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    dispatch(hasbackButton(false)); //reset
    dispatch(saveCalendarTypeSelected(JourneyManagerInitialState)); //reset
    setOpen(!open);
  };
  const handleActionReturn = () => {
    dispatch(saveCurrentJourney(journey));
    if (calendarByCalendarType.length > 1) {
      dispatch(hasbackButton(true));
      setOpen(true);
    } else {
      dispatch(saveCalendarTypeSelected(calendarByCalendarType[0]));
      dispatch(initizalizeScheduleAppointment());
    }
  };

  useEffect(() => {
    const toggle = () => {
      if (calendarByCalendarType.length <= 1 || scheduling === 'initialize' || handleClose) {
        return false;
      }

      if (calendarTypeSelected.id && calendarTypeSelected.id.length > 0) {
        return true;
      }
    };
    setOpen(toggle() || false);
  }, [scheduling, handleClose, calendarTypeSelected]);

  return (
    <>
      {!confirm ? (
        <>
          <JourneyAction
            icon={<CircleIcon border size="60px" icon={<WarningAmberIcon />} />}
            title="Agendar consulta de retorno"
            subtitle={`Agora você só precisa escolher o melhor horário para sua consulta de retorno`}
          >
            <Button variant="contained" color="primary" onClick={() => handleActionReturn()}>
              Agendar retorno
            </Button>
          </JourneyAction>
          {open ? (
            <ChooseReturnType
              calendarTypeSelected={calendarByCalendarType}
              open={open}
              onCloseHandler={() => {
                handleOpen();
              }}
            />
          ) : (
            []
          )}
        </>
      ) : (
        []
      )}
    </>
  );
};
