import { SectionFullWidth } from '../../components/shared/Grid';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { ReactComponent as Logo } from '../../images/logo_horizontal.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import { LinkContant } from './styled';

const Wrapper = styled(SectionFullWidth)(({ theme }) => ({
  backgroundColor: theme.palette.brand.darkGreen,
  width: '100%',
  height: 'auto'
}));

const Footer = () => (
  <>
    <Wrapper component="footer" sx={{ py: { xs: 3 } }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Link href={process.env.REACT_APP_SITE_URL}>
            <Logo data-testid="logo-app-bar" />
          </Link>
        </Box>
        <Box sx={{ textAlign: 'left' }}>
          <LinkContant href="mailto:alo@oya.care" target="_blank">
            Fale conosco
          </LinkContant>
          <LinkContant href="https://www.instagram.com/oya.care/" target="_blank">
            Siga a Oya Care <InstagramIcon sx={{ color: 'white' }} />
          </LinkContant>
        </Box>
      </Box>
    </Wrapper>
  </>
);

export default Footer;
