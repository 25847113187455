import { createSlice } from '@reduxjs/toolkit';

interface IRedirect {
  redirectTo: string;
}

const initialState: IRedirect = {
  redirectTo: ''
};

export const redirectSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    setRedirect: (state, action) => {
      state.redirectTo = action.payload;
    }
  }
});

export const { setRedirect } = redirectSlice.actions;
export const redirectReducer = redirectSlice.reducer;
