import { styled } from '@mui/material/styles';

export const ItemButtonIcon = styled(`span`)(({ theme }) => ({
  '.MuiBox-root': {
    svg: {
      position: 'relative',
      top: 2,
      path: { fill: theme.palette.brand.darkGreen }
    }
  },
  svg: {
    fill: theme.palette.brand.darkGreen,
    position: 'relative',
    top: 2
  }
}));
