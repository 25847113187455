import { PropsWithChildren } from 'react';
import MainThree from '../MainThree';
import Header from '../Header';
import AppBar from '../../components/shared/AppBar';

const TemplateFullWithHeaderLogged = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Header>
        <AppBar />
      </Header>
      <MainThree>{children}</MainThree>
    </>
  );
};

export default TemplateFullWithHeaderLogged;
