import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme } from '@oya/ui';
import TagManager from 'react-gtm-module';
import { setupStore } from './store';
import App from './Routers';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = setupStore();

if (process.env.REACT_APP_STAGE === 'production') {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
    auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
    preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW
  });
}
root.render(
  <ThemeProvider theme={Theme}>
    <CssBaseline />
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>
);
