import { createContext } from 'react';
import { ICalendarTypeSelected } from '../../features/journeyManager/journey-manager-slice';

interface FlexibleReturnContextProps {
  calendarByCalendarType: Array<ICalendarTypeSelected>;
}

const FlexibleReturnContext = createContext<FlexibleReturnContextProps>({
  calendarByCalendarType: []
});

export default FlexibleReturnContext;
