import TextField from '@mui/material/TextField';
import { IQuestion } from '../../../../../providers/AnamneseProvider/context';
import { useFormContext } from 'react-hook-form';

interface LongTextProps {
  question: IQuestion;
}

const LongText = ({ question }: LongTextProps) => {
  const { register, trigger } = useFormContext();
  return (
    <TextField
      defaultValue={question.isRecovery ? question.answer : undefined}
      placeholder={question.tip}
      {...register(question.id, {
        required: question.isRequired,
        onBlur: () => trigger(question.id),
        onChange: () => trigger(question.id)
      })}
      variant="standard"
      fullWidth
      multiline
      rows={4}
    />
  );
};

export default LongText;
