import Grid from '@mui/material/Unstable_Grid2';
import OneTimePassword from '../../components/pages/Login/OneTimePassword';
import { FullHeight } from '../../components/shared/Grid';
import Typography from '@mui/material/Typography';
import TemplateDefault from '../../layout/TemplateDefault';

const Login = () => {
  return (
    <TemplateDefault>
      <FullHeight container>
        <Grid xs={0} md={3}></Grid>
        <Grid xs={12} md={6}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            Digite seu código de verificação
          </Typography>
          <OneTimePassword />
        </Grid>
        <Grid xs={0} md={3}></Grid>
      </FullHeight>
    </TemplateDefault>
  );
};

export default Login;
