import { api } from './api.service';

export interface ISingInRequest {
  cpf: string;
}

export interface ISingInMethod {
  id: string;
  method: string;
}

export interface ISingInMethodResponse {
  oyanaid: string;
  email: string;
  verifyCode: true;
}

export interface ISingInResponse {
  oyanaid: string;
  email: string;
  whatsapp: string;
}

export interface ISingInOTPRequest {
  oyanaid: string;
  code: string;
}

export const singIn = api.injectEndpoints({
  endpoints: build => ({
    userVerify: build.mutation<ISingInResponse, ISingInRequest>({
      query: body => ({
        url: `/login/auth`,
        body,
        method: 'POST'
      })
    }),

    singInMethod: build.mutation<ISingInMethodResponse, ISingInMethod>({
      query: ({ id, method }) => ({
        url: `/login/auth/${method}`,
        body: {
          id
        },
        method: 'POST'
      })
    }),

    otpVerify: build.mutation<any, ISingInOTPRequest>({
      query: body => ({
        url: `/portal/v1/login/verifyCode`,
        body,
        method: 'POST'
      })
    })
  })
});

export const { useUserVerifyMutation, useOtpVerifyMutation, useSingInMethodMutation } = singIn;
