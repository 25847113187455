import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatDateLabel } from '../../../../../lib/dayjs';
import { updateScheduleSteps } from '../../../../../features/schedule/schedule-slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Divider } from '../../../Divider';
import { DialogContent } from '../../styled';
import { useReschedulingConfirmationMutation } from '../../../../../services/schedule.service';

const RescheduleExam = () => {
  const {
    schedule: { dateStart, dateStartISO },
    journey: { schedules }
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [reschedulingConfirmation, { isLoading }] = useReschedulingConfirmationMutation();
  return (
    <>
      <DialogContent>
        <Typography>Depois de agendar, você ainda poderá alterar a data da coleta do exame.</Typography>
        <Divider />
        <Typography variant="body1B" sx={{ mb: 1 }}>
          Data do exame
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography>{formatDateLabel(dateStart)}</Typography>
          <Button variant="inline" disabled={isLoading} onClick={() => dispatch(updateScheduleSteps('openCalendar'))}>
            Alterar
          </Button>
        </Box>
        <Typography variant="body1B" sx={{ mb: 1 }}>
          Endereço
        </Typography>
        <Typography sx={{ mb: 1 }}>
          {schedules[0].address.address}, {schedules[0].address.addressNumber}
        </Typography>
        <Typography>
          {schedules[0].address.neighborhood}, {schedules[0].address.city}, {schedules[0].address.state}
        </Typography>
      </DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => {
            reschedulingConfirmation({ currentScheduleId: schedules[0].id, dateStart: dateStartISO })
              .unwrap()
              .then(() => {
                dispatch(updateScheduleSteps('sendFile'));
              })
              .catch(error => {
                console.log(error);
              });
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Salvar
        </LoadingButton>
      </Box>
    </>
  );
};

export default RescheduleExam;
