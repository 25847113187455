import { api } from './api.service';

interface IExameConfirmation {
  calendarid: string;
  journeyid: string;
  dateStart: string;
  timezone?: string;
}

export interface IReschedulingConfirmation {
  dateStart: string;
  currentScheduleId: string;
  userId?: string;
}

interface IScheduleReturnConfirmation {
  calendarid: string;
  journeyid: string;
  dateStart: string;
  returnSchedule: boolean;
  userid: string;
  timezone?: string;
}

export const schedule = api.injectEndpoints({
  endpoints: build => ({
    scheduleExameConfirmation: build.mutation<any, IExameConfirmation>({
      query: body => ({
        url: `/schedule`,
        method: 'POST',
        body
      })
    }),
    reschedulingConfirmation: build.mutation<any, IReschedulingConfirmation>({
      query(data) {
        const { currentScheduleId, ...body } = data;
        return {
          url: `/schedule/${currentScheduleId}/reschedule`,
          method: 'PUT',
          body
        };
      }
    }),
    scheduleReturnConfirmation: build.mutation<any, IScheduleReturnConfirmation>({
      query: body => ({
        url: `/schedule`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useScheduleExameConfirmationMutation,
  useReschedulingConfirmationMutation,
  useScheduleReturnConfirmationMutation
} = schedule;
