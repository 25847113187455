import { useCallback, useEffect, useState } from 'react';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import Modal from './Modal';
import { IJourneyResp, ISchedules } from '../../../services/journey.service';

export type RatingHighlight = {
  doctorName: string;
  doctorProfileImage: string;
  scredule: ISchedules;
};

const RatingNotification = () => {
  const {
    journey,
    journeys: { activeJourneys }
  } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState<boolean>(false);
  const [ratingHighlight, setRatingHighlight] = useState<RatingHighlight>({} as RatingHighlight);
  const isHome = useMatch('/');
  const toggleHandler = () => setOpen(!open);
  const hasScheduleWithCSAT = (journey: IJourneyResp): ISchedules | undefined => {
    const schedule = journey.schedules?.find(schedule => schedule.status === 'SCHEDULED' && schedule.isNeedCollectCSAT);
    if (!schedule) return;
    return schedule;
  };
  const lastSchedule = useCallback(() => {
    const lastJourney = isHome !== null ? activeJourneys[0] : journey;
    if (!lastJourney) return;

    const currentSchedule = hasScheduleWithCSAT(lastJourney);
    if (currentSchedule) {
      setRatingHighlight({
        doctorName: lastJourney?.doctor?.name as string,
        doctorProfileImage: lastJourney?.doctor?.profileImage as string,
        scredule: currentSchedule
      });
      setOpen(true);
    }
  }, [journey, activeJourneys]);

  useEffect(() => lastSchedule(), [journey, activeJourneys]);

  return (
    <>
      <Modal toggleHandler={open} onCloseHandler={() => toggleHandler()} ratingHighlight={ratingHighlight} />
    </>
  );
};

export default RatingNotification;
