import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { formatDateLabel } from '../../../../lib/dayjs';
import ItemButtonSimple from '../../ItemButtonSimple';
import JourneyAction from '../../JourneyAction';
import CircleIcon from '../../CircleIcon';
import { typeSchedule } from '../../../../utils/scheduleType';
import { JourneyActionPayload, getCurrentSchedule, getScheduleType } from '..';
import { Divider } from '../../Divider';
import Button from '@mui/material/Button';
import { Button as ButtonSmall } from '../style';
import Link from '@mui/material/Link';
import { customEventTracking } from '../../../../lib/gtm';

export const AwaitingPaymentSmall = ({ journey }: JourneyActionPayload) => {
  const currentSchedule = getCurrentSchedule(journey);
  return (
    <>
      <ItemButtonSimple
        journeyId={journey?.journeyid}
        text={journey?.product.name || ''}
        event="clique_jornada_mc"
        subText={`${getScheduleType(currentSchedule?.type || '')}: ${formatDateLabel(currentSchedule?.dateStart || '')}`}
        subText2={journey?.doctor?.name}
        button={
          <Link
            href={`${process.env.REACT_APP_SCHEDULE_CLIENT_URL}/opcoes-de-pagamento?journeyid=${journey?.journeyid}&returnurl=/`}
            sx={{ textDecoration: 'none' }}
            onClick={() => {
              customEventTracking.send({
                event: 'finalizar_pag_mc'
              });
            }}
          >
            <ButtonSmall variant="contained">Finalizar pagamento</ButtonSmall>
          </Link>
        }
      />
      <Divider />
    </>
  );
};

export const AwaitingPaymentLarge = ({ journey }: JourneyActionPayload) => {
  return (
    <JourneyAction
      icon={<CircleIcon border size="60px" icon={<WarningAmberIcon />} />}
      title="Finalize o pagamento"
      subtitle={`Para confirmar a ${typeSchedule(journey?.schedules[0].type || '')} realize o pagamento`}
    >
      <Link
        href={`${process.env.REACT_APP_SCHEDULE_CLIENT_URL}/opcoes-de-pagamento?journeyid=${journey?.journeyid}&returnurl=/`}
        sx={{ textDecoration: 'none' }}
      >
        <Button variant="contained" color="primary">
          Pagar agora
        </Button>
      </Link>
    </JourneyAction>
  );
};
