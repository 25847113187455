import MuiBox from '@mui/material/Box';
import { InnerSectionContainerTwo } from '../../components/shared/Grid';
import { PropsWithChildren } from 'react';

const MainTwo = ({ children }: PropsWithChildren) => (
  <MuiBox>
    <InnerSectionContainerTwo>{children}</InnerSectionContainerTwo>
  </MuiBox>
);

export default MainTwo;
