import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Card, Root } from './styled';
import Button from '@mui/material/Button';
import { fileUploadModalOpen } from '../../../../features/fileUpload';

const MyFiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFileUpload = () => {
    dispatch(
      fileUploadModalOpen({
        title: 'Enviar exames',
        successMessage:
          'Seus exames foram enviados com sucesso para o seu prontuário e já estão disponíveis para visualização na sua conta.'
      })
    );
  };

  return (
    <>
      <Root>
        <Card>
          <Typography variant="body2R">
            Compartilhe os resultados de seus exames recentes conosco. Eles contribuem para que nossa equipe médica ofereça o
            melhor cuidado possível.
          </Typography>

          <Button variant="contained" color="primary" fullWidth sx={{ mt: 4 }} onClick={handleFileUpload}>
            Enviar exames
          </Button>
        </Card>
        <Card>
          <Typography variant="body2R">
            Aqui você encontra todos os seus arquivos, como exames enviados e relatórios gerados pela Oya Care.
          </Typography>
          <Button variant="inline" color="primary" sx={{ mt: 4 }} onClick={() => navigate('/meus-arquivos')}>
            Visualizar meus arquivos
          </Button>
        </Card>
      </Root>
    </>
  );
};

export default MyFiles;
