import { formatDateLabel } from '../../../../lib/dayjs';
import ItemButtonSimple from '../../ItemButtonSimple';
import { JourneyActionPayload, getCurrentSchedule, getScheduleType } from '..';
import { Divider } from '../../Divider';

export const AwaitingExamSmall = ({ journey }: JourneyActionPayload) => {
  const currentSchedule = getCurrentSchedule(journey);
  return (
    <>
      <ItemButtonSimple
        journeyId={journey?.journeyid}
        text={journey?.product.name || ''}
        subText={`${getScheduleType(currentSchedule?.type || '')}: ${formatDateLabel(currentSchedule?.dateStart || '')}`}
        subText2={journey?.doctor?.name}
      />
      <Divider />
    </>
  );
};
