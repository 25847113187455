import { numberWithoutMask } from './utils';

/**
 * Valida CPF
 * @param {String} value
 * @returns {Boolean}
 */
const isCpf = (value: string): boolean => {
  const valueWithoutMask = numberWithoutMask(value);
  let rest: number;
  let sum = 0;

  if (
    valueWithoutMask.length < 11 ||
    Array.from(Array(10).keys())
      .reduce((arr: string[], idx) => [...arr, `${idx}`.repeat(11)], [])
      .includes(valueWithoutMask)
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += Number(valueWithoutMask.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== Number(valueWithoutMask.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i += 1) {
    sum += Number(valueWithoutMask.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== Number(valueWithoutMask.substring(10, 11))) {
    return false;
  }

  return true;
};

export default isCpf;
