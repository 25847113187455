import Box from '@mui/material/Box';
import BackAction from './BackAction';
import FormProgressBar from './FormProgressBar';

const FormHeader = () => {
  return (
    <>
      <Box id="form_header"></Box>
      <Box sx={{ padding: 3, minHeight: '161px' }}>
        <Box sx={{ minHeight: '24px' }}>
          <BackAction />
        </Box>
        <Box mt={4} mb={2}>
          <FormProgressBar />
        </Box>
      </Box>
    </>
  );
};

export default FormHeader;
