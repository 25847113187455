import { PropsWithChildren } from 'react';
import MainTwo from '../MainTwo';

const TemplateFull = ({ children }: PropsWithChildren) => {
  return (
    <>
      <MainTwo>{children}</MainTwo>
    </>
  );
};

export default TemplateFull;
