export const typeSchedule = (type: string) => {
  switch (type) {
    case 'EXAM':
      return 'coleta do exame';
    case 'VIRTUAL_CONSULTATION':
      return 'consulta online';
    case 'PHYSICAL_APPOINTMENT':
      return 'consulta presencial';
    default:
      '';
  }
};
