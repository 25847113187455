import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Grid } from '@mui/material';
import TemplateFullWithHeader from '../../layout/TemplateFullWithHeader';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Flor from '../../images/flor_3.gif';
import Link from '@mui/material/Link';
import { saveAnamneseMetaData } from '../../features/anamnese';
import Returning from './Returning';
import FirstTime from './FirstTime';

const HomeAnamnese = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['oya.anamnese.journeyId', 'oya.anamnese.oyanaId']);
  const { journeyId, formId, oyanaId } = useParams();

  useEffect(() => {
    if (journeyId) {
      setCookie('oya.anamnese.journeyId', journeyId, { secure: true, maxAge: 86400, path: '/' });
      setCookie('oya.anamnese.oyanaId', oyanaId, { secure: true, maxAge: 86400, path: '/' });
      dispatch(saveAnamneseMetaData({ journeyId, formId, oyanaId }));
    }
  }, [journeyId]);

  useEffect(() => {
    removeCookie('oya.anamnese.journeyId');
    removeCookie('oya.anamnese.oyanaId');
  }, []);

  return (
    <>
      <TemplateFullWithHeader>
        <Grid container sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          <Grid xs={0} md={5} mt={{ xs: 3, md: 7 }}>
            <Typography variant="h1" mb={1}>
              Anamnese
            </Typography>
            <Typography variant="subtitle1" mb={3}>
              <i>s.f.</i> As famosas perguntas médicas
            </Typography>

            {oyanaId && oyanaId?.length > 0 ? <Returning /> : <FirstTime />}

            <Box mt={4} mb={{ xs: 10, md: 0 }}>
              <Typography variant="body2R">
                Saiba mais sobre a{' '}
                <Link
                  href="https://documentos-oya.s3.us-east-2.amazonaws.com/Pol%C3%ADtica+de+Privacidade+da+Oya.pdf"
                  target="_blank"
                >
                  política de privacidade
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid xs={0} md={1} display={{ xs: 'none', md: 'block' }}></Grid>
          <Grid xs={5} md={5} mt={{ xs: 7, md: 7 }}>
            <CardMedia component="img" alt="flor" image={Flor} />
          </Grid>
          <Grid xs={0} md={1} display={{ xs: 'none', md: 'block' }}></Grid>
        </Grid>
      </TemplateFullWithHeader>
    </>
  );
};

export default HomeAnamnese;
