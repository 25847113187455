import { SectionFullWidth } from '../../components/shared/Grid';
import { styled } from '@mui/material/styles';

const Wrapper = styled(SectionFullWidth)(({ theme }) => ({
  backgroundColor: theme.palette.brand.darkGreen,
  width: '100%',
  height: '56px'
}));

type Props = {
  children: React.ReactNode;
};

const Header = ({ children }: Props) => <Wrapper component="header">{children}</Wrapper>;

export default Header;
