import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ItemButton from '../../../../shared/ItemButton';
import { ReactComponent as CalendarIcon } from '../../../../../images/calendar.svg';
import Modal from './Modal';
import { RootState } from '../../../../../store';
import { EnumJourneyAction, getJourneyAction } from '../../../../../utils/enum/journeyAction';
import { getCurrentSchedule } from '../../../../shared/JourneyActionManager';
import { differenceInMinutes } from 'date-fns';
import { customEventTracking } from '../../../../../lib/gtm';
import ModalReschedule from './ModalReschedule';
import { hasbackButton, initizalizeScheduleAppointment } from '../../../../../features/schedule/schedule-slice';
import Box from '@mui/material/Box';

const CANCELLATION_TIME_AVAILABLE_IN_MINUTES = 179; // 2 horas e 59 minutos

const CallectionPolicy = () => {
  const {
    journey,
    journey: { doctor },
    schedule: {
      finishReschedule,
      ui: { scheduling, steps, handleClose }
    }
  } = useSelector((state: RootState) => state);
  const journeyStatus = getJourneyAction(journey);
  const currentSchedule = getCurrentSchedule(journey);
  const [open, setOpen] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const handleOpen = () => setOpen(!open);
  const currentScheduleStartDate = new Date(currentSchedule?.dateStart || '');
  const TODAY = new Date();
  const dispatch = useDispatch();

  const cancellationAvailable = (): boolean => {
    return (
      journeyStatus !== EnumJourneyAction.CANCELED &&
      currentScheduleStartDate.getTime() > TODAY.getTime() &&
      differenceInMinutes(currentScheduleStartDate, TODAY) > CANCELLATION_TIME_AVAILABLE_IN_MINUTES
    );
  };

  const handleActionReschedule = () => {
    dispatch(hasbackButton(true));
    setOpenReschedule(true);
  };

  const handleCloseModalReschedule = () => {
    dispatch(hasbackButton(false));
    setOpenReschedule(!openReschedule);
  };

  useEffect(() => {
    const toggle = (): boolean => {
      if (handleClose || finishReschedule || (scheduling == 'idle' && steps === 'idle') || scheduling === 'initialize')
        return false;

      if (scheduling === 'done' && steps === 'idle') return true;
      return true;
    };
    setOpenReschedule(toggle());
  }, [scheduling, handleClose, steps]);

  return (
    <>
      <Typography variant="body1B" sx={{ mb: 1 }}>
        Política de cancelamento
      </Typography>
      <Typography sx={{ mb: 2 }}>Cancele ou reagende sua consulta conforme nossa política.</Typography>
      <Button
        variant="inline"
        color="primary"
        sx={{ mb: 3 }}
        href="https://documentos-oya.s3.us-east-2.amazonaws.com/Pol%C3%ADtica+de+cancelamento.pdf"
        target="_blank"
      >
        Saiba mais
      </Button>

      {journeyStatus !== EnumJourneyAction.AWAITING_SCHEDULE &&
        journeyStatus !== EnumJourneyAction.DONE &&
        journeyStatus !== EnumJourneyAction.CANCELED &&
        journeyStatus !== EnumJourneyAction.AWAITING_PAYMENT &&
        journeyStatus !== EnumJourneyAction.AWAITING_SCHEDULE_RETURN &&
        currentSchedule?.allowReschedule && (
          <ItemButton
            text="Reagendar"
            icon={
              <Box sx={{ marginRight: 2 }}>
                <CalendarIcon />
              </Box>
            }
            onClick={() => {
              customEventTracking.send({ event: 'reagendar_mc' });
              if (currentSchedule.returnSchedule || journeyStatus === EnumJourneyAction.AWAITING_EXAM) {
                dispatch(initizalizeScheduleAppointment());
              } else {
                handleActionReschedule();
              }
            }}
          />
        )}

      {cancellationAvailable() ? (
        <ItemButton
          text="Cancelar jornada"
          icon={
            <Box sx={{ marginRight: 2 }}>
              <CloseIcon />
            </Box>
          }
          onClick={() => {
            handleOpen();
            customEventTracking.send({ event: 'cancelar_mc' });
          }}
        />
      ) : (
        []
      )}
      <Modal
        openCloseController={open}
        onCloseHandler={() => {
          handleOpen();
        }}
      />
      {openReschedule ? (
        <ModalReschedule
          openCloseController={openReschedule}
          onCloseHandler={() => handleCloseModalReschedule()}
          doctorName={doctor?.name || ''}
        />
      ) : (
        []
      )}
    </>
  );
};

export default CallectionPolicy;
