import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { Divider } from '../../../shared/Divider';
import { RootState } from '../../../../store';
import { formatDateLabel } from '../../../../lib/dayjs';
import Address from './Address';
import { HowItWorks } from './HowItWorks';
import { useState } from 'react';
import Link from '@mui/material/Link';
import { getCurrentSchedule } from '../../../shared/JourneyActionManager';
import { EnumJourneyAction, getJourneyAction } from '../../../../utils/enum/journeyAction';

const Booking = () => {
  const { journey } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const typeDate = (type: string) => {
    switch (type) {
      case 'EXAM':
        return 'Data do exame';
      case 'VIRTUAL_CONSULTATION':
        return 'Data da consulta online';
      case 'PHYSICAL_APPOINTMENT':
        return 'Data da consulta presencial';
      default:
        '';
    }
  };

  const currentBooking = getCurrentSchedule(journey);
  const status = getJourneyAction(journey);

  if (!currentBooking) return <></>;

  return (
    <>
      <Box mt={3} mb={3}>
        <Divider />
        <Typography variant="body1B">{typeDate(currentBooking.type)}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Typography variant="body1">{formatDateLabel(currentBooking.dateStart)}</Typography>
          <Button variant="inline" color="primary" onClick={() => handleOpen()}>
            Como funciona
          </Button>
          {currentBooking.type === 'PHYSICAL_APPOINTMENT' && (
            <HowItWorks.ModalPhysicalAppointment
              openCloseController={open}
              onCloseHandler={() => {
                handleOpen();
              }}
            />
          )}
          {currentBooking.type === 'VIRTUAL_CONSULTATION' && (
            <HowItWorks.ModalVirtualConsultation
              openCloseController={open}
              onCloseHandler={() => {
                handleOpen();
              }}
            />
          )}
          {currentBooking.type === 'EXAM' && (
            <HowItWorks.ModalExam
              openCloseController={open}
              onCloseHandler={() => {
                handleOpen();
              }}
            />
          )}
        </Box>
        {currentBooking.teleUrl && status === EnumJourneyAction.VIDEOCALL_AVAILABLE && (
          <Box sx={{ mt: 2 }}>
            <Link href={currentBooking.teleUrl} target="_blank">
              <Button variant="contained" color="primary" endIcon={<ArrowForwardIcon />}>
                Entrar na videochamada
              </Button>
            </Link>
          </Box>
        )}
      </Box>
      {currentBooking.address && <Address currentBooking={currentBooking} />}
    </>
  );
};

export default Booking;
