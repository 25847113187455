import { FC, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { useNavigate } from 'react-router-dom';
import { customEventTracking } from '../../../lib/gtm';
import Button from '@mui/material/Button';

interface Props {
  onClick?: () => void;
  text: string;
  subText?: string;
  subText2?: string;
  icon?: ReactElement<SvgIconTypeMap>;
  button?: ReactElement;
  journeyId?: string;
  event?: string;
}

export const ItemButtonSimple: FC<Props> = ({ journeyId, text, icon, subText, subText2, button, event }) => {
  const navigate = useNavigate();
  const openUrl = () => {
    if (event) {
      customEventTracking.send({ event: event });
    }
    navigate(`/jornada/${journeyId}`);
  };
  return (
    <Box>
      <Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
          onClick={() => {
            openUrl();
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {icon}
            <Box>
              <Box>
                {text && (
                  <Typography variant="body1B" color="brand.darkGreen">
                    {text}
                  </Typography>
                )}
              </Box>
              <Box>{subText && <Typography variant="body2R">{subText}</Typography>}</Box>
              <Box>{subText2 && <Typography variant="body2R">{subText2}</Typography>}</Box>
            </Box>
          </Box>
          <Box>
            <Button onClick={() => openUrl()} variant="inline" color="primary" sx={{ mb: 3 }}>
              ver detalhes
            </Button>
          </Box>
        </Box>
        <Box mt={2}>{button}</Box>
      </Box>
    </Box>
  );
};

export default ItemButtonSimple;
