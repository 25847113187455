import { SectionFullWidth } from '../../components/shared/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Wrapper = styled(SectionFullWidth)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige1,
  width: '100%',
  height: '56px'
}));

type Props = {
  children: React.ReactNode;
};

const HeaderTwo = ({ children }: Props) => (
  <Wrapper component="header">
    <Box paddingTop={3}>{children}</Box>
  </Wrapper>
);

export default HeaderTwo;
