import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { RatingResulIcon } from '../styled';
import { Button, Typography } from '@mui/material';

export const NoShow = () => {
  return (
    <>
      <RatingResulIcon>
        <SentimentDissatisfiedIcon />
      </RatingResulIcon>
      <Typography variant="h1" sx={{ mb: 3 }}>
        Poxa, que pena!
      </Typography>
      <Typography variant="body1">Registramos seu caso e enviamos para o time responsável.</Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Se houver alguma dúvida, é só nos chamar.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="https://bit.ly/Fale_com_a_Oya"
        target="_black"
        sx={{ mt: 2 }}
        endIcon={<WhatsAppIcon />}
      >
        Falar com a gente
      </Button>
    </>
  );
};

export default NoShow;
