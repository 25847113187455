import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ReturnLink = styled(Box)(() => ({
  a: {
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  }
}));
