import Typography from '@mui/material/Typography';
import ItemButton from '../../../../shared/ItemButton';
import { ReactComponent as WhatsappIcon } from '../../../../../images/whatsapp.svg';
import { ReactComponent as QuestionIcon } from '../../../../../images/question.svg';
import { customEventTracking } from '../../../../../lib/gtm';
import Box from '@mui/material/Box';

const PatientCare = () => {
  const handlerWhatsClick = () => {
    process.env.REACT_APP_WHATSAPP && window.open(process.env.REACT_APP_WHATSAPP, '_blank');
    return false;
  };

  const handlerKnowMoreClick = () => {
    process.env.REACT_APP_SITE_URL && window.open(process.env.REACT_APP_SITE_URL + '/sobre/perguntas-frequentes', '_blank');
    return false;
  };

  return (
    <>
      <Typography variant="body1B" sx={{ mb: 1 }}>
        Atendimento à oyana
      </Typography>
      <Typography sx={{ mb: 2 }}>Entre em contato com nossa equipe de atendimento em horário comercial.</Typography>
      <ItemButton
        text="Entrar em contato com a Equipe de cuidados"
        icon={
          <Box sx={{ marginRight: 2 }}>
            <WhatsappIcon />
          </Box>
        }
        onClick={() => {
          handlerWhatsClick();
          customEventTracking.send({
            event: 'contato_equipe_cuidados_mc'
          });
        }}
      />
      <ItemButton
        text="Acessar perguntas frequentes"
        icon={
          <Box sx={{ marginRight: 2 }}>
            <QuestionIcon />
          </Box>
        }
        onClick={() => {
          handlerKnowMoreClick();
          customEventTracking.send({
            event: 'perguntas_frequentes_mc'
          });
        }}
      />
    </>
  );
};

export default PatientCare;
