import { useContext } from 'react';
import FlexibleReturnContext from './context';

function useFlexibleReturn() {
  const context = useContext(FlexibleReturnContext);

  if (context === undefined) {
    throw new Error('useFlexibleReturn must be used within a FlexibleReturnProvider');
  }

  return context;
}

export default useFlexibleReturn;
