import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import AnamneseContext, {
  ELogicCondition,
  EQuestionTypes,
  iFindPosition,
  IForm,
  IFormCategory,
  ILogicTarget,
  IOption,
  IQuestion,
  IStep
} from './context';
import {
  IAnamnesePostRequest,
  IAnswer,
  useGetAnamneseByFormIdMutation,
  usePostFormAnamneseMutation
} from '../../services/anamnese.service';
import { useNavigate, useParams } from 'react-router-dom';

interface AnamneseProviderProps {
  children: PropsWithChildren<any>;
}

const AnamneseProvider = ({ children }: AnamneseProviderProps) => {
  const { journeyId, formId, oyanaId } = useParams();
  const [getAnamneseByFormId] = useGetAnamneseByFormIdMutation();
  const [postFormAnamnese] = usePostFormAnamneseMutation();
  const navigate = useNavigate();

  const [isFinish, setIsFinish] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [step, setStep] = useState({
    current: 0,
    category: {
      initial: 0,
      current: 0,
      total: 0
    },
    question: {
      listBack: [],
      initial: 0,
      current: 0,
      total: [0]
    }
  } as IStep);
  const [form, setForm] = useState<IForm>({} as IForm);

  const stepNext = () => {
    stepCountPlus();
    if (
      step.question.current === step.question.total[step.category.current] - 1 &&
      step.category.current === step.category.total - 1
    ) {
      setIsFinish(true);
    } else if (step.question.current === step.question.total[step.category.current] - 1) {
      stepCategoryNext();
    } else {
      stepQuestionNext();
    }
  };

  //callback como o stepper
  const stepBack = useCallback(() => {
    stepCountMinus();
    stepQuestionBack();
  }, [step]);

  const stepCountPlus = useCallback(() => {
    setStep((prev: IStep) => ({
      ...prev,
      current: prev.current + 1
    }));
  }, [step]);

  const stepCountMinus = useCallback(() => {
    if (step.category.current === 0 && step.question.current === 0) {
      setStep((prev: IStep) => ({
        ...prev,
        current: 0
      }));
    } else {
      setStep((prev: IStep) => ({
        ...prev,
        current: prev.current - 1
      }));
    }
  }, [step]);

  const stepCategoryNext = useCallback(
    () =>
      setStep((prev: IStep) => ({
        ...prev,
        question: {
          ...prev.question,
          current: prev.question.initial
        },
        category: {
          ...prev.category,
          current: prev.category.current === prev.category.total - 1 ? prev.category.current : prev.category.current + 1
        }
      })),
    [step]
  );

  const stepQuestionNext = useCallback(() => {
    setStep((prev: IStep) => ({
      ...prev,
      question: {
        ...prev.question,
        current: prev.question.current + 1
      }
    }));
  }, [step]);

  const stepQuestionBack = useCallback(() => {
    const history = getQuestionsBack();
    setStep((prev: IStep) => ({
      ...prev,
      category: { ...prev.category, current: history.category },
      question: { ...prev.question, current: history.question }
    }));
  }, [step]);

  const stepGoTo = useCallback(
    (category: number, question: number) => {
      stepCountPlus();
      setStep((prev: IStep) => ({
        ...prev,
        question: {
          ...prev.question,
          current: question
        },
        category: {
          ...prev.category,
          current: category
        }
      }));
    },
    [step]
  );

  const hasContinue = useCallback(
    (questionId: string) => {
      const question = findQuestion(questionId);
      if (!question.isRequired) return true;
      switch (question.type) {
        case EQuestionTypes.SINGLE_CHOICE:
        case EQuestionTypes.LINEAR_SCALE:
        case EQuestionTypes.BOOLEAN:
          return false;
        default:
          return true;
      }
    },
    [form]
  );

  const hasFinish = useCallback(() => {
    if (
      step.category.current > 0 &&
      step.question.current > step.question.total[step.category.current] - 1 &&
      step.category.current > step.category.total - 1
    ) {
      return true;
    }
    return false;
  }, [step]);

  const findQuestion = useCallback(
    (questionId: string) => {
      let question = {} as IQuestion;
      form.categories?.forEach((item: IFormCategory) => {
        item.category.questions.forEach((q: IQuestion) => {
          if (q.id === questionId) question = q;
        });
      });
      return question;
    },
    [form]
  );

  const skepQuestion = useCallback(
    (questionId: string) => {
      const question = findQuestion(questionId);
      if (question) return question.isSkipped;
    },
    [form]
  );

  const getTotalQuestionsByCategory = (categories: IFormCategory[]): number[] => {
    const total: number[] = [];
    categories.map((item: IFormCategory) => {
      const totalQuestionsFiltered = item.category.questions.filter(
        (q: IQuestion) =>
          q.isSkipped === false ||
          (q.isSkipped === true && q.answer === undefined) || // não logado
          (q.isSkipped === true && q.answer && q.answer === '') // logado
      );
      const totalQuestion = totalQuestionsFiltered.length;
      total.push(totalQuestion);
    });
    return total;
  };

  const findQuestionPosition = useCallback(
    (questionId: string) => {
      let numberQuestion = 0;
      let numberCategory = 0;
      form.categories?.forEach((item: IFormCategory, indexCategory: number) => {
        const indexQuestion: number = item.category.questions.findIndex(objeto => objeto.id === questionId);
        if (indexQuestion !== -1) {
          numberQuestion = indexQuestion;
          numberCategory = indexCategory;
        }
      });
      return { question: numberQuestion, category: numberCategory };
    },
    [form]
  );

  const getQuestionsBack = (): iFindPosition => {
    const questionId = step.question.listBack.pop();
    setStep((prev: IStep) => ({
      ...prev,
      question: {
        ...prev.question,
        listBack: step.question.listBack
      }
    }));
    const findPosition: { question: number; category: number } = findQuestionPosition(questionId ?? '');

    return findPosition;
  };

  const addQuestionHistory = (questionId: string) => {
    setStep((prev: IStep) => ({
      ...prev,
      question: {
        ...prev.question,
        listBack: [...prev.question.listBack, questionId]
      }
    }));
  };

  const fillFormFromData = useCallback((data: IForm) => {
    setForm({ ...data });
    setStep({
      current: 0,
      category: {
        initial: 0,
        current: 0,
        total: data.categories?.length
      },
      question: {
        listBack: [],
        initial: 0,
        current: 0,
        total: getTotalQuestionsByCategory(data.categories)
      }
    });
  }, []);

  const isValidCondition = useCallback((filterQuestionTarget: ILogicTarget, answer: string) => {
    const { conditional } = filterQuestionTarget;
    const { conditionalValue } = filterQuestionTarget;

    if (conditional) {
      if (conditional === ELogicCondition.EQUAL) {
        return answer === conditionalValue;
      }
      if (conditional === ELogicCondition.NOT_EQUAL) {
        return answer !== conditionalValue;
      }
      if (conditional === ELogicCondition.GREATER_THAN) {
        return parseInt(answer) > parseInt(conditionalValue.toString());
      }
      if (conditional === ELogicCondition.LESS_THAN) {
        return parseInt(answer) < parseInt(conditionalValue.toString());
      }
      if (conditional === ELogicCondition.START_WITH) {
        return answer.startsWith(conditionalValue.toString());
      }
      if (conditional === ELogicCondition.END_WITH) {
        return answer.endsWith(conditionalValue.toString());
      }
      if (conditional === ELogicCondition.CONTAINS) {
        return answer.includes(conditionalValue.toString());
      }
    }
    return false;
  }, []);

  const findQuestionTarget = useCallback(
    (question: IQuestion, filterQuestionTarget: ILogicTarget) => {
      let questionTargetIndex = 0;
      let categoryTargetIndex = 0;
      form.categories?.forEach((item: IFormCategory, index: number) => {
        item.category.questions.forEach((q: IQuestion, indexQuestion: number) => {
          if (filterQuestionTarget.target === null) {
            categoryTargetIndex = step.category.current + 1;
            questionTargetIndex = -1;
          } else if (q.id === filterQuestionTarget.target.id) {
            categoryTargetIndex = index;
            questionTargetIndex = indexQuestion - 1;
          }
        });
      });
      return {
        questionTargetIndex,
        categoryTargetIndex
      };
    },
    [form, step]
  );

  const submit = (data: any) => {
    setIsError(false);
    const buildPayload = (data: any): any => {
      const questionsFilteresByAnswer = [] as IQuestion[];
      form.categories.forEach((category: IFormCategory) => {
        category.category.questions.forEach((question: IQuestion) => {
          if (question.isSkipped && question.answer) {
            questionsFilteresByAnswer.push(question);
          }
        });
      });
      const skipedFields = questionsFilteresByAnswer.map(question => ({
        value: question.answer,
        type: question.type,
        question: { id: question.id }
      }));

      const payload: IAnamnesePostRequest = {
        form: {
          id: form.id
        },
        journeyId: journeyId || '',
        oyanaId: oyanaId || '',
        answers: skipedFields
      };
      Object.entries(data).forEach(([key1, val]: any) => {
        const result = form?.categories?.map((valueCategories: IFormCategory) => {
          return valueCategories.category.questions.filter((valueQuestions: IQuestion) => valueQuestions.id === key1);
        });

        const unionArray: IQuestion[] = [];
        for (let index = 0; index < form?.categories.length; index++) {
          unionArray.push(...result[index]);
        }

        let valueCheckBox: IOption[] = [
          {
            id: '',
            label: '',
            value: false
          }
        ];

        const isCheckBox = unionArray[0].type === EQuestionTypes.CHECKBOX;
        const valueObjCheckbox = [];

        if (isCheckBox) {
          valueCheckBox = val?.options.filter((resp: IOption) => resp.value === true);
          const hasOtherCheckbox = valueCheckBox.filter((resp: IOption) => resp.id === 'other');
          if (hasOtherCheckbox.length > 0) {
            valueObjCheckbox.push(val.otherChoice);
          }
          for (let index = 0; index < valueCheckBox.length; index++) {
            if (valueCheckBox[index]?.id !== 'other') {
              valueObjCheckbox.push(valueCheckBox[index]?.label);
            }
          }
        }

        const isSingleChoice = unionArray[0].type === EQuestionTypes.SINGLE_CHOICE;
        let valueObjSingleChoice = '';

        if (isSingleChoice) {
          if (val?.option?.id === 'other') {
            valueObjSingleChoice = val.otherChoice;
          } else valueObjSingleChoice = val?.option?.label;
        }

        let valueOfNewObj;
        if (isCheckBox) {
          valueOfNewObj = valueObjCheckbox.join(';');
        } else if (isSingleChoice) {
          valueOfNewObj = valueObjSingleChoice;
        } else valueOfNewObj = val;

        if (!valueOfNewObj) return;

        const answer: IAnswer = {
          value: valueOfNewObj,
          type: unionArray[0].type,
          question: {
            id: key1
          }
        };

        payload.answers.push(answer);
      });

      return payload;
    };

    setIsLoading(true);

    postFormAnamnese(buildPayload(data))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        navigate('/fim-anamnese');
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };

  const value = {
    state: {
      isFinish,
      isLoading,
      isError,
      form,
      step
    },
    actions: {
      addQuestionHistory,
      getQuestionsBack,
      stepNext,
      stepBack,
      stepGoTo,
      submit,
      hasContinue,
      skepQuestion,
      hasFinish,
      isValidCondition,
      findQuestionTarget
    }
  };

  useEffect(() => {
    getAnamneseByFormId({ formId, oyanaId })
      .unwrap()
      .then((res: IForm) => {
        if (res) {
          fillFormFromData(res);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  return <AnamneseContext.Provider value={value}>{children}</AnamneseContext.Provider>;
};

export default AnamneseProvider;
