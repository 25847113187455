import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import { InnerSectionContainer } from '../../components/shared/Grid';

const Wrapper = styled(MuiBox)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige1
}));

type Props = {
  children: React.ReactNode;
};

const Main = ({ children }: Props) => (
  <Wrapper>
    <InnerSectionContainer>{children}</InnerSectionContainer>
  </Wrapper>
);

export default Main;
