import { useState } from 'react';
import copy from 'copy-to-clipboard';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { ISchedules } from '../../../../../services/journey.service';
import ItemButton from '../../../../shared/ItemButton';

interface AddressProps {
  currentBooking: ISchedules;
}

const Address = ({ currentBooking }: AddressProps) => {
  const [copyadress, setCopyadress] = useState(false);

  const copyToClipboard = (currentBookingToCopy: ISchedules) => {
    const value = `${currentBookingToCopy.address.address} ${currentBookingToCopy.address.addressNumber} ${currentBookingToCopy.address.addressComplement} ${currentBookingToCopy.address.addressReference} - ${currentBookingToCopy.address.neighborhood}`;
    setCopyadress(true);
    copy(value);
  };

  const openLink = (currentBookingMaps: ISchedules) =>
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${currentBookingMaps.address.address}+${currentBookingMaps.address.addressNumber}+${currentBookingMaps.address.neighborhood}`,
      '_blank'
    );

  return (
    <Box mt={3}>
      <Typography variant="body1B">
        {currentBooking?.type === 'EXAM' ? 'Endereço da coleta do exame' : 'Endereço da consulta'}
      </Typography>
      <Typography mt={1} variant="body1">
        {currentBooking?.address.address}, {currentBooking?.address.addressNumber}
      </Typography>
      <Typography mt={1} variant="body1">
        {currentBooking?.address.neighborhood}, {currentBooking?.address.city}-{currentBooking?.address.state}
      </Typography>

      <ItemButton
        text={copyadress ? 'Endereço copiado' : 'Copiar endereço'}
        icon={<ContentCopyOutlinedIcon />}
        onClick={() => copyToClipboard(currentBooking)}
      />

      <ItemButton text="Como chegar" icon={<PlaceOutlinedIcon />} onClick={() => openLink(currentBooking)} />
    </Box>
  );
};

export default Address;
