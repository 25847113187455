import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const BodyButton = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  padding: '12px 17px',
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ':hover': {
    border: `1px solid ${theme.palette.brand.darkGreen}`
  },
  '.MuiFormControlLabel-label': {
    marginLeft: 0,
    lineHeight: '1.5rem'
  },
  '&.checked': {
    backgroundColor: theme.palette.brand.darkGreen,
    '.MuiFormControlLabel-root': {
      color: theme.palette.neutrals.beige1
    }
  },
  '&.unchecked': {
    backgroundColor: theme.palette.neutrals.beige1,
    '.MuiFormControlLabel-root': {
      color: theme.palette.brand.darkGreen
    }
  }
}));
