import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderTop: `1px solid ${theme.palette.neutrals.beige3}`,
  borderBottom: `1px solid ${theme.palette.neutrals.beige3}`,
  padding: `${theme.spacing(2)} 0`,
  '.MuiTypography-root': {
    flexGrow: 1
  }
}));

export const StartIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}));

export const EndIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  svg: {
    fill: theme.palette.neutrals.beige4
  }
}));
