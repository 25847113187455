import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, WrapperAvatar, MenuItem, CustomIcon } from './styled';
import { RootState } from '../../../../store';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper/Paper';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import { ReactComponent as Prescriptions } from '../../../../images/prescription.svg';
import { ReactComponent as Report } from '../../../../images/report.svg';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Typography from '@mui/material/Typography';
import { expireAuth } from '../../../../features/singin/singin-slice';
import Avatar from '../../Avatar';
import { Link } from 'react-router-dom';
import { customEventTracking } from '../../../../lib/gtm';

const MenuDesktop = () => {
  const {
    oyana: { me },
    journeys: { doneJourneys }
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (menuRef.current && menuRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const lougOut = () => {
    dispatch(expireAuth(true));
  };
  return (
    <WrapperAvatar onMouseLeave={handleToggle} ref={menuRef}>
      <Avatar name={me.nickName?.length > 0 ? me.nickName : me.firstName} onMouseEnter={handleToggle} />
      <List open={open} anchorEl={menuRef.current} role={undefined} placement="bottom-end" transition disablePortal>
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-end' ? 'top bottom' : 'left bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="composition-menu" aria-labelledby="composition-button">
                    <MenuItem
                      onClick={() => {
                        customEventTracking.send({
                          event: 'minhas_jornadas_mc'
                        });
                        handleClose;
                      }}
                    >
                      <Link to={'/'}>
                        <Typography variant="body2B">
                          <MedicalInformationOutlinedIcon />
                          Jornadas atuais
                        </Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        customEventTracking.send({
                          event: 'meus_arquivos_mc'
                        });
                        handleClose;
                      }}
                    >
                      <Link to={'/meus-arquivos'}>
                        <Typography variant="body2B">
                          <CustomIcon>
                            <Prescriptions />
                          </CustomIcon>
                          Meus arquivos
                        </Typography>
                      </Link>
                    </MenuItem>
                    {doneJourneys[0]?.status !== '' ? (
                      <MenuItem
                        onClick={() => {
                          customEventTracking.send({
                            event: 'meus_historico_mc'
                          });
                          handleClose;
                        }}
                      >
                        <Link to={'/historico'}>
                          <Typography variant="body2B">
                            <CustomIcon>
                              <Report />
                            </CustomIcon>
                            Histórico
                          </Typography>
                        </Link>
                      </MenuItem>
                    ) : (
                      []
                    )}

                    <MenuItem
                      onClick={event => {
                        handleClose(event);
                        lougOut();
                      }}
                    >
                      <Typography variant="body2B">
                        <LogoutOutlinedIcon />
                        Sair da conta
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </List>
    </WrapperAvatar>
  );
};

export default MenuDesktop;
