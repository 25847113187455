import { FC, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { EndIcon, Root, StartIcon } from './style';

interface Props {
  onClick: () => void;
  title: string;
  iconStart?: ReactElement<SvgIconTypeMap>;
  iconEnd?: ReactElement<SvgIconTypeMap>;
}

export const TittleButton: FC<Props> = ({ onClick, title, iconStart, iconEnd }) => {
  return (
    <Root onClick={() => onClick()}>
      {iconStart ? <StartIcon>{iconStart}</StartIcon> : []}
      <Typography variant="h3">{title}</Typography>
      {iconEnd ? (
        <EndIcon>{iconEnd}</EndIcon>
      ) : (
        <EndIcon>
          <ArrowForwardIcon />
        </EndIcon>
      )}
    </Root>
  );
};

export default TittleButton;
