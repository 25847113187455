import { PropsWithChildren } from 'react';
import FlexibleReturnContext from './context';
import { ECalendarType, IJourneyResp } from '../../services/journey.service';
import { ICalendarTypeSelected } from '../../features/journeyManager/journey-manager-slice';

interface FlexibleReturnProviderProps {
  journey: IJourneyResp;
  children: PropsWithChildren<any>;
}

const FlexibleReturnProvider = ({ children, journey }: FlexibleReturnProviderProps) => {
  const filterCalendarByCalendarType = (journey: IJourneyResp): Array<ICalendarTypeSelected> => {
    const filtered: any = [];
    let typeFilred: any = [];

    if (journey.calendarTypes.length >= 1) {
      if (journey.calendarTypes.length === 1) {
        typeFilred = journey.calendarTypes;
      } else {
        typeFilred = journey.calendarTypes?.filter((type: any) => type.type !== ECalendarType.MAIN);
      }

      typeFilred.forEach((type: any) => {
        journey.calendars?.forEach((calendar: any) => {
          if (type.calendarId === calendar.id) {
            filtered.push(calendar);
          }
        });
      });
    }
    return filtered;
  };
  const calendarByCalendarType = journey?.calendarTypes[0]?.calendarId !== '' ? filterCalendarByCalendarType(journey) : [];
  return (
    <FlexibleReturnContext.Provider
      value={{
        calendarByCalendarType: calendarByCalendarType
      }}
    >
      {children}
    </FlexibleReturnContext.Provider>
  );
};

export default FlexibleReturnProvider;
