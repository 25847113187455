import { useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { FullHeight } from '../../components/shared/Grid';
import { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import { ReactComponent as UploadImage } from '../../images/upload.svg';
import { ReactComponent as ProfileIcon } from '../../images/profile.svg';
import { fileTypes } from '../../services/oyanaFilesUpload.service';
import TemplateDefault from '../../layout/TemplateDefault';
import UploadFiles from '../../components/shared/ModalFileUpload/UploadFiles';
import { SuccessIcon } from '../../components/shared/ModalFileUpload/style';

const UploadTemporaryDocument = () => {
  const { journeyId, oyanaId } = useParams();
  const [screenStep, setScreenStep] = useState<string>('upload');

  return (
    <TemplateDefault>
      <FullHeight container>
        <Grid container justifyContent={'space-around'} xs={10}>
          <Grid item xs order={{ xs: 4, md: 2 }}>
            {screenStep === 'upload' ? (
              <Box>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  Envio de arquivos
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Formatos permitidos: .jpg, .png e .pdf de até 10MB
                </Typography>
                <UploadFiles
                  isSuccess={() => setScreenStep('success')}
                  reset={false}
                  journeyId={journeyId}
                  oyanaId={oyanaId}
                  emptyFilesIcon={false}
                  fileType={fileTypes.PRIVATE}
                />
              </Box>
            ) : (
              <Box sx={{ px: '1px' }}>
                <SuccessIcon>
                  <CheckOutlinedIcon />
                </SuccessIcon>
                <Typography variant="h1" sx={{ mb: 2 }}>
                  Enviado!
                </Typography>
                <Typography variant="body1">
                  Fique tranquila, para sua segurança e privacidade os arquivos serão permanentemente excluídos após a
                  consulta.
                </Typography>
                <Link href={'/'} sx={{ textDecoration: 'none' }}>
                  <Button
                    component="label"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    endIcon={<ProfileIcon />}
                    sx={{ display: 'flex', justifyContent: ' space-between', marginTop: 5 }}
                  >
                    Ir para a Minha Conta
                  </Button>
                </Link>
              </Box>
            )}
          </Grid>
          <Grid item xs order={{ xs: 2, md: 4 }} marginX={5}>
            <UploadImage
              style={{
                maxWidth: '350px'
              }}
            />
          </Grid>
        </Grid>
      </FullHeight>
    </TemplateDefault>
  );
};

export default UploadTemporaryDocument;
