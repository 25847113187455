import { styled } from '@mui/material/styles';

export const BodyButton = styled('button')(({ theme }) => ({
  borderRadius: '12px',
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  marginBottom: '8px',
  padding: '24px 17px',
  fontSize: '1rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  lineHeight: '1.5rem',
  ':active': {
    backgroundColor: theme.palette.brand.darkGreen,
    color: theme.palette.neutrals.beige1
  },
  ':hover': {
    border: `1px solid ${theme.palette.brand.darkGreen}`
  },
  cursor: 'pointer'
}));
