import JourneyPeding from './Peding';
import JourneyCurrent from './Current';
import { IJourneyResp, ISchedules } from '../../../services/journey.service';

export interface JourneyActionVariant {
  variant: 'pending' | 'current';
}

export interface JourneyActionPayload {
  journey: IJourneyResp;
}

export const getCurrentSchedule = (journey: IJourneyResp): ISchedules | undefined => {
  const currentSchedule = journey.schedules?.find(schedule => schedule.status === 'SCHEDULED');
  if (!currentSchedule) return;
  return currentSchedule;
};

export const getScheduleType = (type: string) => {
  switch (type) {
    case 'EXAM':
      return 'Data do exame';
    case 'VIRTUAL_CONSULTATION':
    case 'PHYSICAL_APPOINTMENT':
      return 'Consulta';
    default:
      'Consulta';
  }
};

const JourneyActionManager = ({ variant }: JourneyActionVariant) => {
  if (variant === 'pending') {
    return <JourneyPeding />;
  }
  if (variant === 'current') {
    return <JourneyCurrent />;
  }
  return <></>;
};

export default JourneyActionManager;
