import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { expireAuth } from '../features/singin/singin-slice';
const newrelic = (window as any).newrelic;

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
  if (action.type.includes('oyaApi/executeMutation/rejected')) {
    const error = action.payload.data?.message
      ? action.payload.data?.message
      : `Error on request ${action.meta.baseQueryMeta.request.url}`;
    newrelic?.noticeError(`[Minha Conta] ${error}`, action.meta);
    if (
      action.meta.baseQueryMeta.response.ok == false &&
      (action.meta.baseQueryMeta.response.status == 401 || action.meta.baseQueryMeta.response.status == 403)
    ) {
      api.dispatch(expireAuth(true));
    }
  }
  return next(action);
};

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_OYA_CARE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).singIn.auth.access_token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
  headers: {
    'Content-type': 'application/json'
  }
});

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'oyaApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ['Coupon'],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({})
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => 'test'
  })
});
