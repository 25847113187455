import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { customEventTracking } from '../../../lib/gtm';
import { RootState } from '../../../store';
import { ReturnLink } from './style';

const NotLogged: React.FC = () => {
  const navigate = useNavigate();
  const {
    anamnese: { journeyId, formId, oyanaId },
    singIn: {
      auth: { access_token }
    }
  } = useSelector((state: RootState) => state);

  return (
    <>
      <Typography variant="body1" mb={4}>
        Antes da sua consulta, precisamos revisar alguns dados. Assim, a médica se prepara da melhor forma para que sua
        consulta seja acolhedora e personalizada.
      </Typography>

      {access_token?.length === 0 ? (
        <>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                customEventTracking.send({
                  event: 'iniciar_home_login_anamnese_mc'
                });
                navigate(`/login?redirectTo=/responda-anamnese/${formId}/${journeyId}/${oyanaId}`);
              }}
            >
              Acessar minha conta
            </Button>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box display="flex" alignItems="center">
              <TimerOutlinedIcon sx={{ fontSize: '16px' }} />
            </Box>
            <ReturnLink marginLeft={0.5}>
              <Link to={`/responda-anamnese/${formId}/${journeyId}`}>
                <Typography variant="body2R">Não consigo acessar a minha conta</Typography>
              </Link>
            </ReturnLink>
          </Box>
        </>
      ) : (
        <>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                customEventTracking.send({
                  event: 'iniciar_home_anamnese_mc'
                });
                navigate(`/responda-anamnese/${formId}/${journeyId}/${oyanaId}`);
              }}
            >
              Iniciar
            </Button>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box display="flex" alignItems="center">
              <TimerOutlinedIcon sx={{ fontSize: '16px' }} />
            </Box>
            <ReturnLink marginLeft={0.5}>
              <Typography variant="body2R">Leva 7 minutos</Typography>
            </ReturnLink>
          </Box>
        </>
      )}
    </>
  );
};

export default NotLogged;
