import { useDispatch, useSelector } from 'react-redux';
import { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Snackbar from '@mui/material/Snackbar';

import Login from '../pages/Login';
import Home from '../pages/Home';
import OneTimePassword from '../pages/OneTimePassword';
import LoginMethod from '../pages/LoginMethod';
import UploadTemporaryDocument from '../pages/UploadTemporaryDocument';
import Journey from '../pages/Journey';
import MyFiles from '../pages/MyFiles';
import History from '../pages/History';
import Anamnese from '../pages/Anamnese';
import HomeAnamnese from '../pages/HomeAnamnese';
import FinishAnamnese from '../pages/FinishAnamnese';

import RatingNotification from '../components/shared/RatingNotification';
import ModalFileUpload from '../components/shared/ModalFileUpload';
import { customEventTracking, pageview } from '../lib/gtm';

import { RootState } from '../store';
import { AuthContext, useAuth } from '../contexts/AuthContext';
import { expireAuth, saveOyanaAuth } from '../features/singin/singin-slice';
import { hideNotification, successNotification } from '../features/notifications/notifications-slice';

const AuthProvider = function AuthProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['access_token']);

  dispatch(saveOyanaAuth(cookies.access_token ? cookies.access_token : ''));

  return (
    <AuthContext.Provider
      value={{
        token: cookies.access_token
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const RequireAuth = function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const App = () => {
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const navigate = useNavigate();
  const {
    notifications: { message, open },
    singIn
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const status = searchParams.get('status');

    // Google Analytics custom events
    customEventTracking.send({ event: 'journey_init' });

    if (status === 'paid') {
      dispatch(successNotification({ message: 'Pagamento realizado com sucesso!', open: true }));
    }
    searchParams.delete('status');
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    // Google Analytics
    pageview(location.pathname);
    //Scroll to top
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (singIn.auth.expiredToken) {
      removeCookie('access_token');
      dispatch(expireAuth(false));
      navigate('/login');
    }
  }, [singIn.auth.expiredToken]);

  return (
    <>
      <Routes>
        <Route index path="/login" element={<Login />} />
      </Routes>
      <Routes>
        <Route index path="/login/metodo-de-envio" element={<LoginMethod />} />
      </Routes>
      <Routes>
        <Route path="/login/codigo" element={<OneTimePassword />} />
      </Routes>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/jornada/:journeyid"
            element={
              <RequireAuth>
                <Journey />
              </RequireAuth>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/meus-arquivos"
            element={
              <RequireAuth>
                <MyFiles />
              </RequireAuth>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/historico"
            element={
              <RequireAuth>
                <History />
              </RequireAuth>
            }
          />
        </Routes>
        <Routes>
          <Route path="/arquivo-temporario/:journeyId/oyana/:oyanaId" element={<UploadTemporaryDocument />} />
        </Routes>
        <Routes>
          <Route index path="/home-anamnese/:formId/:journeyId/:oyanaId?" element={<HomeAnamnese />} />
        </Routes>
        <Routes>
          <Route
            index
            path="/responda-anamnese/:formId/:journeyId/:oyanaId?"
            element={
              <Suspense fallback={<div>Carregando...</div>}>
                <Anamnese />
              </Suspense>
            }
          />
        </Routes>
        <Routes>
          <Route index path="/fim-anamnese" element={<FinishAnamnese />} />
        </Routes>
      </AuthProvider>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={() => dispatch(hideNotification())}
        message={message}
        autoHideDuration={6000}
      />
      <RatingNotification />
      <ModalFileUpload />
    </>
  );
};

export default App;
