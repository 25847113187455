type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

window.dataLayer = window.dataLayer || [];

export const pageview = (url: string) => {
  window.dataLayer.push({
    event: 'page_view',
    page: url
  });
};

interface EventProps {
  action: string;
  category: string;
  label: string;
  value?: string;
}
export const event = ({ action, category, label, value }: EventProps) => {
  window.dataLayer.push({
    event: 'eventTracking',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value
  });
};

interface EventTrackingClickProps {
  category: string;
  label: string;
}
export const eventTracking = {
  click: ({ category, label }: EventTrackingClickProps) => {
    event({
      action: 'Clique',
      category,
      label
    });
  }
};

interface CusTomEventTrackingClickProps {
  event: string;
  props?: Record<string, any>;
}

export const customEventTracking = {
  send: ({ event, props }: CusTomEventTrackingClickProps) => {
    window.dataLayer.push({
      event: event,
      ...props
    });
  }
};

interface DynamicCusTomEventTrackingClickProps {
  dynamicEventName: string;
}

export const dynamicCustomEventTracking = {
  send: ({ dynamicEventName }: DynamicCusTomEventTrackingClickProps) => {
    window.dataLayer.push({
      event: 'dynamicEventTracking',
      dynamicEventName
    });
  }
};
