import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import useAnamnese from '../../../providers/AnamneseProvider/use';
import QuestionList from './QuestionList';
import { BoxCard, Form, GridRoot } from './style';
import { IForm, IFormCategory } from '../../../providers/AnamneseProvider/context';
import FormHeader from './FormHeader';
import { dynamicCustomEventTracking } from '../../../lib/gtm';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AlertMessage from '../../shared/AlertMessage';

const Anamnese = () => {
  const {
    state: { form, step, isFinish, isError },
    actions
  } = useAnamnese();
  const methods = useForm();

  useEffect(() => {
    if (isFinish) {
      actions.submit(methods.getValues());
    }
  }, [isFinish]);

  useEffect(() => {
    const titleCategory = getCategoryName(form);
    if (titleCategory) {
      const formatTitle = titleCategory?.replace(/\s/g, '_').toLowerCase();
      dynamicCustomEventTracking.send({
        dynamicEventName: `cat_${formatTitle}_anamnese_mc`
      });
    }
  }, [step]);

  const getCategoryName = (form: IForm): string => {
    if (Object.keys(form).length === 0) return '';
    if (step.question.current === 0) {
      return form?.categories[step.category.current].category.title;
    } else return '';
  };

  return (
    <GridRoot>
      <FormHeader />
      <BoxCard>
        {form?.categories?.length > 0 ? (
          <FormProvider {...methods}>
            <Form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              {form?.categories?.map((item: IFormCategory, index: number) => {
                if (index === step.category.current)
                  return <QuestionList key={item.category.title} questions={item.category.questions} />;
              })}
            </Form>
          </FormProvider>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress sx={{ color: 'brand.darkGreen' }} />
          </Box>
        )}
        {isError ? <AlertMessage text="Algo deu errado ao enviar suas respostas." type="error" /> : []}
      </BoxCard>
    </GridRoot>
  );
};

export default Anamnese;
