import Attend from './Attend';
import TitleSection from '../../../shared/TitleSection';
import { Divider } from '../../../shared/Divider';
// import StepByStep from './StepByStep';
import CallectionPolicy from './CancellationPolicy';

const InformationAbout = () => {
  return (
    <>
      <TitleSection>Informações sobre a jornada</TitleSection>
      <Attend />
      {/* <StepByStep /> */}
      <Divider />
      <CallectionPolicy />
    </>
  );
};

export default InformationAbout;
