import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const AvailableSchedules = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: '47px',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: '24px'
  },
  [theme.breakpoints.up('xl')]: {
    width: '100%'
  }
}));

export const AvailableDays = styled(Box)(({ theme }) => ({
  width: '300px',
  marginBottom: '30px',
  '.MuiChip-root': {
    lineHeight: '2rem',
    marginBottom: '10px',
    '&::first-letter': {
      textTransform: 'capitalize'
    },
    '&.selected': {
      backgroundColor: theme.palette.brand.darkGreen,
      borderColor: theme.palette.brand.darkGreen,
      color: theme.palette.neutrals.beige1
    }
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '30px',
    width: '100%'
  }
}));

export const AvailableTimes = styled(Box)(({ theme }) => ({
  width: '310px',
  [theme.breakpoints.down('md')]: {
    width: 'auto'
  },
  '.MuiChip-root': {
    marginBottom: '10px',
    marginRight: '10px',
    '&.selected': {
      backgroundColor: theme.palette.brand.darkGreen,
      borderColor: theme.palette.brand.darkGreen,
      color: theme.palette.neutrals.beige1
    }
  },

  '.MuiTypography-root': {
    marginBottom: '10px'
  }
}));
