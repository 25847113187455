import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  DeleteButton,
  FileItem,
  FileItemActions,
  FileItemDescription,
  FileItemErrorMessage,
  FileItemName,
  FileItemSize,
  RetryButton
} from '../style';
import FileQueueProvider from './FileQueueProvider';
import { ReactComponent as Delete } from '../../../../../images/delete.svg';
import { ReactComponent as Replay } from '../../../../../images/swap.svg';
import {
  fileType,
  IFileUploadRequest,
  useDeleteSingleFileMutation,
  useUploadSingleFileMutation
} from '../../../../../services/oyanaFilesUpload.service';
import { calcSize } from '../../../../../utils/fileUpload';

interface Props {
  file: File;
  onSuccess: (file: File) => void;
  onError: (file: File) => void;
  onDelete: (file: File) => void;
  scheduleId: string;
  journeyId: string;
  oyanaId: string;
  fileType: fileType;
}

export const fileQueue = new FileQueueProvider([]);

const FileItemUpload = ({ file, journeyId, scheduleId, oyanaId, onSuccess, onError, onDelete, fileType }: Props) => {
  const maxFileSizeInMB = 10;
  const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;
  const validSizeUploadFile = file.size < maxFileSizeInKB;

  const oyanaFile: IFileUploadRequest = {
    id: null,
    name: file.name,
    fileType,
    url: '',
    report: null,
    oyana: {
      id: oyanaId
    },
    status: '',
    scheduleId: scheduleId,
    journeyId: journeyId,
    showToOyana: true,
    maskType: 'Exame (Diversos)'
  };
  const formData = new FormData();
  formData.append('file', file);
  formData.append('oyanaFiles', JSON.stringify(oyanaFile));
  formData.append('oyana', JSON.stringify(oyanaFile.oyana));
  const [
    uploadSingleFile,
    { isLoading: uploadIsLoading, isError: uploadIsError, isSuccess: uploadIsSuccess, error: uploadError }
  ] = useUploadSingleFileMutation();
  const [deleteSingleFile, { isLoading: deleteIsLoading }] = useDeleteSingleFileMutation();

  const retrySendFile = () => {
    uploadSingleFile(formData)
      .then((res: any) => {
        if (res.error) {
          fileQueue.setErroByFileName(file.name);
          onError(file);
        }
        fileQueue.setSuccessByFileName(file.name, res.data.id);
        onSuccess(file);
      })
      .catch(error => {
        onError(error);
      });
  };

  const deleteFile = () => {
    onDelete(file);
    fileQueue.setCurrentByFileName(file.name);
    if (!validSizeUploadFile || uploadIsError) {
      fileQueue.removeFromQueueByFileName(file.name);
      return;
    }

    const fileToDelete = fileQueue.getFileByName(file.name);
    deleteSingleFile({ id: fileToDelete?.id })
      .unwrap()
      .then(() => {
        fileQueue;
      })
      .catch(error => console.log(error));
  };

  const deleteAction = () => {
    if (uploadIsLoading) return [];
    if (uploadIsSuccess || !validSizeUploadFile || uploadIsError || fileQueue.findByFileName(file.name)) {
      return (
        <DeleteButton onClick={deleteFile}>
          <Delete />
        </DeleteButton>
      );
    }
  };

  useEffect(() => {
    if (fileQueue.findByFileName(file.name)) return;
    fileQueue.addFile(file);
    if (!validSizeUploadFile) return;
    uploadSingleFile(formData)
      .then((res: any) => {
        if (res.error) {
          fileQueue.setErroByFileName(file.name);
          onError(file);
        }
        fileQueue.setSuccessByFileName(file.name, res.data.id);
        onSuccess(file);
      })
      .catch(error => {
        onError(error);
      });
  }, [file]);

  return (
    <FileItem>
      <FileItemDescription>
        <FileItemName variant="body1B">{file.name}</FileItemName>
        <FileItemSize variant="body2B">{calcSize(file.size)}</FileItemSize>
        {!validSizeUploadFile ? (
          <FileItemErrorMessage variant="body2R">Esse arquivo é grande demais</FileItemErrorMessage>
        ) : (
          []
        )}
        {uploadError || fileQueue.findErrorByFileName(file.name) ? (
          <FileItemErrorMessage variant="body2R">Falha no envio do arquivo</FileItemErrorMessage>
        ) : (
          []
        )}
      </FileItemDescription>
      <FileItemActions>
        {uploadIsLoading || (deleteIsLoading && fileQueue.findCurrentByFileName(file.name)) ? (
          <CircularProgress sx={{ color: 'brand.mediumGreen', verticalAlign: 'middle' }} size={30} />
        ) : (
          []
        )}

        {deleteAction()}

        {uploadIsError || fileQueue.findErrorByFileName(file.name) ? (
          <RetryButton onClick={retrySendFile}>
            <Replay />
          </RetryButton>
        ) : (
          []
        )}
      </FileItemActions>
    </FileItem>
  );
};

export default FileItemUpload;
