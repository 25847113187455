import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { SessionLimiterStrong } from '../../components/shared/Divider';
import ContentExams from '../../components/pages/Journey/ContentExams';
import InformationAbout from '../../components/pages/Journey/InformationAbout';
import { ViewContent } from '../../layout/Main/styled';
import { Files, useGetJourneyByIdMutation } from '../../services/journey.service';
import { saveCurrentJourney, updateFiles } from '../../features/journey';
import PaymentInfo from '../../components/pages/Journey/PaymentInfo';
import PatientCare from '../../components/pages/Journey/InformationAbout/PatientCare';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Calendar from '../../components/shared/Calendar';
import { RootState } from '../../store';
import Booking from '../../components/pages/Journey/Booking';
import JourneyActionManager from '../../components/shared/JourneyActionManager';
import IconButton from '@mui/material/IconButton';
import { useGetMyPersonalFilesMutation, useGetPersonalDataMutation } from '../../services/me.service';
import { saveOyanaPersonaData } from '../../features/me/me-slice';
import Grid from '@mui/material/Grid';
import { refreshSchedule } from '../../features/schedule/schedule-slice';
import { JourneyManagerInitialState, saveCalendarTypeSelected } from '../../features/journeyManager/journey-manager-slice';
import TemplateDefault from '../../layout/TemplateDefault';

const Journey = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const {
    schedule: {
      confirm: confirmSchedule,
      haveToAnswerAnamnese,
      sendExams,
      ui: { scheduling, backToTop }
    },
    singIn: {
      auth: { access_token }
    },
    oyana: {
      me: { nickName }
    }
  } = useSelector((state: RootState) => state);
  const [getJourneyById, { data, isLoading }] = useGetJourneyByIdMutation();
  const [getPersonalData, { data: dataOyana }] = useGetPersonalDataMutation();
  const [getPersonalFiles] = useGetMyPersonalFilesMutation();

  const loadFiles = () => {
    getPersonalFiles({})
      .unwrap()
      .then((res: Files) => {
        dispatch(updateFiles(res));
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const loadJourneyData = () => {
    const url = path.split('/')[2];
    getJourneyById({ journeyId: url })
      .unwrap()
      .then((res: any) => {
        dispatch(refreshSchedule());
        dispatch(saveCurrentJourney(res));
        loadFiles();
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadJourneyData();
    dispatch(saveCalendarTypeSelected(JourneyManagerInitialState));
    if (access_token && nickName === '') getPersonalData({});
  }, []);

  useEffect(() => {
    if (confirmSchedule || haveToAnswerAnamnese || sendExams) loadJourneyData();
  }, [confirmSchedule, haveToAnswerAnamnese, sendExams]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [backToTop]);

  useEffect(() => {
    if (dataOyana) {
      dispatch(saveOyanaPersonaData(dataOyana));
    }
  }, [dataOyana]);

  return (
    <>
      <TemplateDefault>
        <ViewContent className="ViewContent">
          <Grid container>
            <Grid item xs={0} sm={1} md={3}></Grid>
            <Grid item xs={12} sm={10} md={6}>
              <IconButton
                sx={{ width: 48, height: 48, backgroundColor: 'neutrals.beige3', mb: 4 }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>

              <Typography mb={3} variant="h3">
                {data?.product?.name}
              </Typography>
              {!isLoading ? <></> : <CircularProgress sx={{ color: 'brand.darkGreen' }} />}
              <JourneyActionManager variant="current" />
              <Booking />
              <SessionLimiterStrong />
              <Typography variant="h3">Exames e resultados</Typography>
              <ContentExams />
              <SessionLimiterStrong />
              <InformationAbout />
              <PaymentInfo />
              <SessionLimiterStrong />
              <PatientCare />
              {scheduling === 'initialize' ? <Calendar /> : []}
            </Grid>
            <Grid item xs={0} sm={1} md={3}></Grid>
          </Grid>
        </ViewContent>
      </TemplateDefault>
    </>
  );
};

export default Journey;
