import { createSlice } from '@reduxjs/toolkit';

interface INotifications {
  message: string;
  open: boolean;
}

const initialState: INotifications = {
  message: '',
  open: false
};

// com o toolkit essa função substitui o reducer
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    successNotification: (state, action) => {
      state.message = action.payload.message;
      state.open = action.payload.open;
    },
    hideNotification: state => {
      state.message = '';
      state.open = false;
    }
  }
});

export const { successNotification, hideNotification } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
