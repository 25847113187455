import { createContext } from 'react';

export enum ELogicCondition {
  EQUAL = 'EQUAL',
  START_WITH = 'START_WITH',
  END_WITH = 'END_WITH',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  NOT_EQUAL = 'NOT_EQUAL',
  CONTAINS = 'CONTAINS'
}

export enum EQuestionTypes {
  CHECKBOX = 'CHECKBOX',
  BOOLEAN = 'BOOLEAN',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  TEXT = 'TEXT',
  LINEAR_SCALE = 'LINEAR_SCALE',
  UNIT_OF_MEASUREMENT = 'UNIT_OF_MEASUREMENT',
  DATE = 'DATE',
  LONG_TEXT = 'LONG_TEXT'
}
export enum EQuestionUnitOfMeasurementTypes {
  WEIGHT = 'kg',
  HEIGHT = 'cm'
}
export interface IOption {
  id: string;
  label: string;
  value?: boolean;
}

export interface IQuestion {
  id: string;
  title: string;
  allowOtherChoice: boolean;
  isRequired: boolean;
  isRecovery: boolean;
  tip: string;
  subtitle: string;
  answer: string;
  isSkipped: boolean;
  order: number;
  type: EQuestionTypes;
  options?: IOption[];
  logicTarget: ILogicTarget[];
  unitOfMeasurementType: EQuestionUnitOfMeasurementTypes;
}

export interface ILogicTarget {
  id: string;
  target: IQuestion;
  origin: IQuestion;
  conditional: '';
  conditionalValue: string | number | boolean;
}

export interface ICategory {
  id: string;
  title: string;
  questions: IQuestion[];
}

export interface iFindPosition {
  question: number;
  category: number;
}

export interface IFormCategory {
  id: string;
  order: number;
  category: ICategory;
}

export interface IForm {
  id: string;
  title: string;
  createdAt: string;
  categories: IFormCategory[];
}

export interface IStep {
  current: number;
  category: {
    initial: number;
    current: number;
    total: number;
  };
  question: {
    listBack: Array<string>;
    initial: number;
    current: number;
    total: Array<number>;
  };
}

export interface AnamneseContextProps {
  state: {
    isFinish: boolean;
    isLoading: boolean;
    isError: boolean;
    form: IForm;
    step: IStep;
  };
  actions: {
    getQuestionsBack: (questionId: string) => any;
    addQuestionHistory: (questionId: string) => void;
    stepNext: () => void;
    stepBack: () => void;
    stepGoTo: (category: number, question: number) => void;
    submit: (data: any) => void;
    hasContinue: (questionId: string) => boolean;
    skepQuestion: (questionId: string) => void;
    hasFinish: () => boolean;
    isValidCondition: (filterQuestionTarget: ILogicTarget, answer: string) => boolean;
    findQuestionTarget: (
      question: IQuestion,
      filterQuestionTarget: ILogicTarget
    ) => {
      questionTargetIndex: number;
      categoryTargetIndex: number;
    };
  };
}

const AnamneseContext = createContext<AnamneseContextProps>({
  state: {
    isFinish: false,
    isLoading: false,
    isError: false,
    form: {
      id: '',
      title: '',
      createdAt: '',
      categories: []
    },
    step: {
      current: 0,
      category: {
        initial: 0,
        current: 0,
        total: 0
      },
      question: {
        listBack: [],
        initial: 0,
        current: 0,
        total: [0]
      }
    }
  },
  actions: {
    addQuestionHistory: () => {
      return void 0;
    },
    getQuestionsBack: () => {
      return void 0;
    },
    stepNext: () => {
      return void 0;
    },
    stepBack: () => {
      return void 0;
    },
    stepGoTo: () => {
      return void 0;
    },
    submit: () => {
      return void 0;
    },
    hasContinue: () => false,
    skepQuestion: () => {
      return void 0;
    },
    hasFinish: () => false,
    isValidCondition: () => false,
    findQuestionTarget: () => {
      return {
        questionTargetIndex: 0,
        categoryTargetIndex: 0
      };
    }
  }
});

export default AnamneseContext;
