import { IForm } from '../providers/AnamneseProvider/context';
import { api } from './anamnese.api.service';

type IAnamneseResponse = IForm;
type IAnamneseRequest = {
  formId: string | undefined;
  oyanaId?: string;
};

export interface IAnswer {
  value: any;
  type: string;
  question: {
    id: string;
  };
}
export interface IAnamnesePostRequest {
  form: {
    id: string;
  };
  journeyId: string;
  oyanaId?: string;
  answers: IAnswer[];
}

export const anamnese = api.injectEndpoints({
  endpoints: build => ({
    getAnamneseByFormId: build.mutation<IAnamneseResponse, IAnamneseRequest>({
      query: ({ formId = '', oyanaId = '' }) => ({
        url: `/form/${formId}${oyanaId ? `/${oyanaId}` : ''}`
      })
    }),
    postFormAnamnese: build.mutation<IAnamnesePostRequest, any>({
      query: data => ({
        url: `/form-answer`,
        method: 'POST',
        body: data,
        headers: {}
      })
    })
  })
});

export const { useGetAnamneseByFormIdMutation, usePostFormAnamneseMutation } = anamnese;
