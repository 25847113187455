import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAppointments } from '../../components/shared/Schedules/hooks';

export interface GroupTimes {
  [key: string]: IAppointments;
}

interface IScheduleState {
  dateStart: string;
  dateStartISO: string;
  selectedDay: string;
  confirm: boolean;
  events: string;
  haveToAnswerAnamnese: boolean;
  sendExams: boolean;
  rescheduleWithSameDoctor: boolean;
  finishReschedule: boolean;
  ui: {
    scheduling: 'idle' | 'initialize' | 'done';
    steps: 'idle' | 'openCalendar' | 'selectTime' | 'confirmSchedule' | 'sendFile';
    handleClose?: boolean;
    hasBackButton?: boolean;
    backToTop: number;
  };
}

interface IDoneScheduleAppointment {
  closeAllModals: boolean;
}

const initialState: IScheduleState = {
  dateStart: '',
  dateStartISO: '',
  selectedDay: '',
  confirm: false,
  haveToAnswerAnamnese: false,
  sendExams: false,
  events: '',
  rescheduleWithSameDoctor: false,
  finishReschedule: false,
  ui: {
    scheduling: 'idle',
    steps: 'idle',
    backToTop: 0
  }
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    saveSelectedDate: (state, action) => {
      state.dateStart = action.payload;
      state.dateStartISO = new Date(action.payload).toISOString();
    },
    saveSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
    },
    updateScheduleEvents: (state, action) => {
      state.events = action.payload;
    },
    saveConfirmSchedule: (state, action) => {
      state.confirm = action.payload;
    },
    updateScheduleSteps: (state, action) => {
      state.ui.steps = action.payload;
    },
    initizalizeScheduleAppointment: state => {
      state.ui.scheduling = 'initialize';
      state.ui.steps = 'openCalendar';
      state.ui.handleClose = false;
    },
    doneScheduleAppointment: (state, action: PayloadAction<IDoneScheduleAppointment>) => {
      state.ui.scheduling = 'done';
      state.ui.steps = 'idle';
      if (action.payload.closeAllModals) {
        state.ui.handleClose = true;
      }
    },
    closeScheduleAppointment: (state, action) => {
      state.ui.handleClose = action.payload;
    },
    doneAnsweredAnamnese: (state, action) => {
      state.haveToAnswerAnamnese = action.payload;
    },
    doneSendExams: (state, action) => {
      state.sendExams = action.payload;
    },
    hasbackButton: (state, action) => {
      state.ui.hasBackButton = action.payload;
    },
    backToTopPage: state => {
      state.ui.backToTop += 1;
    },
    rescheduleWithSameDoctor: (state, action) => {
      state.rescheduleWithSameDoctor = action.payload;
    },
    refreshSchedule: state => {
      state.confirm = false;
      state.finishReschedule = false;
    },
    doneReschedule: state => {
      state.finishReschedule = true;
      state.dateStart = '';
      state.dateStartISO = '';
      state.selectedDay = '';
      state.confirm = true;
      state.rescheduleWithSameDoctor = false;
      state.ui.scheduling = 'done';
      state.ui.steps = 'idle';
      state.ui.handleClose = true;
      state.ui.hasBackButton = false;
    }
  }
});

export const {
  saveSelectedDate,
  saveSelectedDay,
  updateScheduleEvents,
  saveConfirmSchedule,
  updateScheduleSteps,
  initizalizeScheduleAppointment,
  doneScheduleAppointment,
  doneAnsweredAnamnese,
  backToTopPage,
  hasbackButton,
  closeScheduleAppointment,
  doneSendExams,
  rescheduleWithSameDoctor,
  doneReschedule,
  refreshSchedule
} = scheduleSlice.actions;
export const scheduleReducer = scheduleSlice.reducer;
