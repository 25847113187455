import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const OneTimePasswordStyled = styled(Box)(({ theme }) => ({
  '.Pincode-container': {
    display: 'flex !important',
    justifyContent: 'space-between',
    margin: '24px 0',
    div: {
      marginRight: '1rem',
      '&:last-child': {
        marginRight: '0'
      }
    }
  },
  '.Pincode-input': {
    fontSize: '1.5rem',
    outline: 'none',
    backgroundColor: 'transparent',
    width: '100% !important',
    maxWidth: '133px !important',
    borderRadius: '0',
    border: '0 none',
    borderBottom: `solid 2px ${theme.palette.neutrals.beige4}`,
    padding: '10px',
    boxSizing: 'border-box',
    fontFamily: 'F Grotesk Book',
    color: theme.palette.neutrals.lead,
    '&:focus': {
      caretColor: theme.palette.brand.lightGreen,
      borderColor: theme.palette.brand.lightGreen
    },
    '&.Pincode-error': {
      borderColor: theme.palette.colored.salmon
    }
  }
}));
