import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ButtonCustom = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  letterSpacing: 0,
  borderRadius: '12px',
  height: '88px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: `${(theme.spacing(1), theme.spacing(3))}`,
  cursor: 'pointer',
  ':hover': {
    border: `1px solid ${theme.palette.brand.darkGreen}`
  }
}));
