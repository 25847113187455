import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ReactComponent as Archive } from '../../../../images/archive.svg';
import { FilesList, VisuallyHiddenInput } from './style';
import FileItemUpload, { fileQueue } from './FileItemUpload';
import { fileTypes } from '../../../../services/oyanaFilesUpload.service';
import { RootState } from '../../../../store';
import { getCurrentSchedule } from '../../JourneyActionManager';

interface Props {
  isSuccess: () => void;
  oyanaId?: string;
  journeyId?: string;
  reset: boolean;
  emptyFilesIcon?: boolean;
  fileType?: fileTypes;
}
const UploadFiles = ({
  isSuccess,
  reset,
  journeyId,
  oyanaId: oyanaIdByParams,
  emptyFilesIcon = true,
  fileType = fileTypes.EXAM
}: Props) => {
  const {
    journey,
    journey: { journeyid },
    oyana: {
      me: { id: oyanaId }
    }
  } = useSelector((state: RootState) => state);
  const currentSchedule = getCurrentSchedule(journey);
  const [files, setFiles] = useState<File[]>([]);
  const listOfName = [''];
  const [countSuccessUpload, setCountSuccessUpload] = useState<string[]>([]);
  const [validUpload, setValidUpload] = useState<boolean>(false);
  const inputFilesRef = useRef(null);
  const inputFileChange = (event: React.SyntheticEvent<EventTarget>) => {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;

    const newFiles = Object.values(target.files).map((file: File) => file);
    if (newFiles) {
      const updatedList = [...files, ...newFiles].filter(file => {
        const isDuplicated = listOfName.includes(file.name);
        if (!isDuplicated) {
          listOfName.push(file.name);
          return file;
        }
      });
      setFiles(updatedList);
    }
  };

  useEffect(() => {
    if (reset) {
      setFiles([]);
      fileQueue.clean();
    }
  }, [reset]);

  useEffect(() => {
    if (files.length === 0) {
      fileQueue.clean();
      return;
    }
    setValidUpload(false);
    if (countSuccessUpload.length === files.length) {
      setValidUpload(true);
    }
  }, [countSuccessUpload, files]);

  useEffect(() => {
    if (files.length === 0) {
      setValidUpload(false);
    }
  }, [files]);

  return (
    <Box>
      <Button
        component="label"
        fullWidth
        variant="contained"
        color="secondary"
        endIcon={<FileUploadOutlinedIcon />}
        sx={{ display: 'flex', justifyContent: ' space-between', marginBottom: 5 }}
      >
        Enviar arquivos
        <VisuallyHiddenInput
          multiple
          type="file"
          ref={inputFilesRef}
          onClick={e => (e.currentTarget.value = '')}
          onChange={inputFileChange}
          accept="image/jpg,image/png,application/pdf"
        />
      </Button>

      {files.length > 0 ? (
        <>
          <Typography variant="body1B" sx={{ mb: 2 }}>
            Arquivos enviados
          </Typography>
          <FilesList>
            {files.map((item, index) => (
              <FileItemUpload
                journeyId={journeyId || journeyid}
                scheduleId={currentSchedule?.id || ''}
                oyanaId={oyanaIdByParams || oyanaId}
                file={item}
                key={index}
                fileType={fileType}
                onSuccess={file => {
                  setCountSuccessUpload(old => [...old, file.name]);
                }}
                onError={error => console.log(error, item)}
                onDelete={file => {
                  setFiles(files.filter(x => x.name !== file.name));
                  setCountSuccessUpload(countSuccessUpload.filter(x => x !== file.name));
                }}
              />
            ))}
          </FilesList>
          <Button
            fullWidth
            variant="contained"
            endIcon={<ArrowForwardOutlinedIcon />}
            disabled={!validUpload}
            onClick={() => {
              isSuccess();
            }}
            sx={{ display: 'flex', justifyContent: ' space-between', marginBottom: 2 }}
          >
            Concluir
          </Button>
        </>
      ) : (
        []
      )}
      {emptyFilesIcon && files.length === 0 ? <Archive /> : []}
    </Box>
  );
};

export default UploadFiles;
