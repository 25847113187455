import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { Middleware } from '@reduxjs/toolkit';
const newrelic = (window as any).newrelic;

export const rtkQueryErrorLogger: Middleware = () => next => action => {
  if (action.type.includes('oyaApi/executeMutation/rejected')) {
    const error = action.payload.data?.message
      ? action.payload.data?.message
      : `Error on request ${action.meta.baseQueryMeta.request.url}`;
    newrelic.noticeError(`[Anamnese] ${error}`, action.meta);
  }
  return next(action);
};

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_OYA_CARE_ANAMNESE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).singIn.auth.access_token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
  headers: {
    'Content-type': 'application/json'
  }
});

export const api = createApi({
  reducerPath: 'oyaAnamneseApi',
  baseQuery: baseQuery,
  endpoints: () => ({})
});
