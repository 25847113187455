import { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import TemplateFullWithHeader from '../../layout/TemplateFullWithHeader';
import CircleBig from '../../images/circle_big.svg';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { RootState } from '../../store';
import TemplateFullWithHeaderLogged from '../../layout/TemplateFullWithHeaderLogged';
import { useGetPersonalDataMutation } from '../../services/me.service';
import { saveOyanaPersonaData } from '../../features/me/me-slice';
import { customEventTracking, dynamicCustomEventTracking } from '../../lib/gtm';
import { doneAnsweredAnamnese } from '../../features/schedule/schedule-slice';
import PageLoading from '../../components/shared/Loading';
import { fileUploadModalOpen } from '../../features/fileUpload';

interface Props {
  logged: boolean;
}

const ContentFinishAnamnese: FC<Props> = ({ logged }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    anamnese: { journeyId, oyanaId }
  } = useSelector((state: RootState) => state);
  const [cookies] = useCookies(['oya.anamnese.journeyId', 'oya.anamnese.oyanaId']);

  const sendGAEvent = (journeyid: string, label: string) => {
    customEventTracking.send({
      event: `${label}_finish_anamnese_mc`,
      props: {
        journeyid_button_anamnese: journeyid
      }
    });
  };

  const handleUploadFiles = () => {
    dispatch(
      fileUploadModalOpen({
        title: 'Enviar exames',
        successMessage:
          'Seus exames foram enviados com sucesso para o seu prontuário e já estão disponíveis para visualização na sua conta.',
        onClose: () => {
          if (logged) {
            navigate(`/jornada/${journeyId ? journeyId : cookies['oya.anamnese.journeyId']}`);
          }
        },
        forwardRefJorneyId: journeyId ? journeyId : cookies['oya.anamnese.journeyId'],
        forwardRefId: oyanaId ? oyanaId : cookies['oya.anamnese.oyanaId']
      })
    );
    dynamicCustomEventTracking.send({
      dynamicEventName: `enviar_exames_anamnese_mc`
    });
  };

  return (
    <Grid container sx={{ minHeight: 'calc(100vh)' }}>
      <Grid xs={0} md={3} item></Grid>
      <Grid xs={12} md={6} item>
        <Box mb={2} mt={{ xs: 4, md: 10 }}>
          <CardMedia component="img" alt="Circle" sx={{ width: '80px' }} image={CircleBig} />
        </Box>
        <Box mb={2}>
          <Typography variant="h1">Obrigada!</Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body1">Tudo pronto. As perguntas médicas nos ajudam a te conhecer melhor.</Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="subtitle1">Próximos passos</Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body1">
            Se você possui resultados de exames recentes (realizados nos últimos 12 meses) que gostaria de mostrar à equipe
            médica, nos envie clicando no botão abaixo. Isso ajudará a otimizar o tempo do seu atendimento.
          </Typography>
        </Box>
        <Grid xs={12} md={4} item sx={{ my: 4 }}>
          <Box mb={5}>
            <Button variant="contained" color="primary" fullWidth onClick={() => handleUploadFiles()}>
              Enviar exames
            </Button>
          </Box>
        </Grid>

        <Grid xs={12} sm={5} md={7.5} item>
          <Box mb={5}>
            {logged ? (
              <Button
                variant="inline"
                color="primary"
                onClick={() => {
                  dispatch(doneAnsweredAnamnese(true));
                  sendGAEvent(journeyId, 'detalhes_da_jornada');
                  navigate(`/jornada/${journeyId ? journeyId : cookies['oya.anamnese.journeyId']}`);
                }}
              >
                Ver detalhes da jornada
              </Button>
            ) : (
              <Button
                variant="inline"
                color="primary"
                onClick={() => {
                  sendGAEvent('', 'login');
                  navigate('/login');
                }}
              >
                Acessar minha conta
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid xs={0} md={3} item></Grid>
    </Grid>
  );
};

const FinishAnamnese = () => {
  const dispatch = useDispatch();
  const [logged, setLogged] = useState(false);
  const [getPersonalData, { data: dataOyana, isLoading }] = useGetPersonalDataMutation();
  const {
    oyana: { me },
    singIn: {
      auth: { access_token }
    }
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (access_token && me.nickName === '') getPersonalData({});
  }, []);

  useEffect(() => {
    if (dataOyana) {
      dispatch(saveOyanaPersonaData(dataOyana));
    }
  }, [dataOyana]);

  useEffect(() => {
    if (me.id?.length > 0) {
      setLogged(true);
    } else setLogged(false);
  }, [me]);

  return (
    <>
      {isLoading ? (
        <PageLoading />
      ) : (
        <>
          {logged ? (
            <TemplateFullWithHeaderLogged>
              <ContentFinishAnamnese logged={logged} />
            </TemplateFullWithHeaderLogged>
          ) : (
            <TemplateFullWithHeader>
              <ContentFinishAnamnese logged={logged} />
            </TemplateFullWithHeader>
          )}
        </>
      )}
    </>
  );
};

export default FinishAnamnese;
