import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import 'dayjs/locale/pt-br';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IQuestion } from '../../../../../providers/AnamneseProvider/context';
import { dateValidation } from '../../../../../validations';
import { ReactComponent as CalendarIcon } from '../../../../../images/calendar.svg';
import { Actions, Header } from './styled';
interface TextProps {
  question: IQuestion;
}

const Date = ({ question }: TextProps) => {
  const { control, trigger, getValues } = useFormContext();
  const validateDate = (value: string, isRequired: boolean): boolean => {
    if (!isRequired) return true;
    return dateValidation(value);
  };
  const [open, setOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setOpen(!open);
  };

  const CustomMobileDatePickerHeader = () => (
    <Header>
      <CloseIcon
        onClick={() => {
          toggleModal();
        }}
        sx={{
          mb: 1,
          cursor: 'pointer'
        }}
      />
    </Header>
  );

  const CustomActionBar = (props: PickersActionBarProps) => {
    const { onAccept } = props;
    return (
      <Actions sx={{ justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={() => {
            toggleModal();
            onAccept();
          }}
        >
          Ok
        </Button>
      </Actions>
    );
  };

  useEffect(() => {
    trigger(question.id);
  }, [getValues(question.id)]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Controller
          name={question.id}
          control={control}
          defaultValue={question.isRecovery && question.answer ? question.answer : null}
          rules={{
            required: question.isRequired,
            validate: {
              validDate: value => validateDate(value, question.isRequired)
            }
          }}
          render={({ field: { onChange, value } }) => (
            <MobileDatePicker
              ToolbarComponent={() => CustomMobileDatePickerHeader()}
              open={open}
              value={value}
              onChange={onChange}
              DialogProps={{
                PaperProps: {
                  sx: {
                    borderRadius: '28px',
                    backgroundColor: 'neutrals.beige2'
                  }
                }
              }}
              components={{
                ActionBar: CustomActionBar
              }}
              componentsProps={{
                actionBar: {
                  actions: ['accept']
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  autoComplete="date"
                  helperText={'dd/mm/aaaa'}
                  placeholder={question.tip}
                  type="tel"
                  variant="standard"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    onClick: () => toggleModal()
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => toggleModal()}>
                        <CalendarIcon />
                      </IconButton>
                    ),
                    onClick: () => toggleModal()
                  }}
                />
              )}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default Date;
