import { createSlice } from '@reduxjs/toolkit';

export interface ICalendarTypeSelected {
  id: string;
  durationEvent: number;
  type: string;
}

export interface IJourneyManager {
  calendarTypeSelected: ICalendarTypeSelected;
}

export const JourneyManagerInitialState: IJourneyManager = {
  calendarTypeSelected: {
    id: '',
    durationEvent: 0,
    type: ''
  }
};

export const journeyManagerSlice = createSlice({
  name: 'journeyManager',
  initialState: JourneyManagerInitialState,
  reducers: {
    saveCalendarTypeSelected: (state, action) => {
      state.calendarTypeSelected = action.payload;
    }
  }
});

export const { saveCalendarTypeSelected } = journeyManagerSlice.actions;
export const journeyManagerReducer = journeyManagerSlice.reducer;
