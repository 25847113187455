import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatDateLabel } from '../../../../../lib/dayjs';
import { updateScheduleSteps } from '../../../../../features/schedule/schedule-slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Divider } from '../../../Divider';
import { DialogContent } from '../../styled';
import { IReschedulingConfirmation, useReschedulingConfirmationMutation } from '../../../../../services/schedule.service';
import { getCurrentSchedule } from '../../../JourneyActionManager';

const RescheduleAppointment = () => {
  const {
    schedule: { dateStart, dateStartISO, rescheduleWithSameDoctor },
    journey,
    journey: { schedules, doctor }
  } = useSelector((state: RootState) => state);
  const currentSchedule = getCurrentSchedule(journey);
  const dispatch = useDispatch();
  const [reschedulingConfirmation, { isLoading }] = useReschedulingConfirmationMutation();

  const typeDate = (type: string) => {
    switch (type) {
      case 'EXAM':
        return 'Data do exame';
      case 'VIRTUAL_CONSULTATION':
        return 'Data da consulta online';
      case 'PHYSICAL_APPOINTMENT':
        return 'Data da consulta presencial';
      default:
        '';
    }
  };
  return (
    <>
      <DialogContent>
        <Typography>Depois de agendar, você ainda poderá alterar a data da consulta.</Typography>
        <Divider />
        <Typography variant="body1B" sx={{ mb: 1 }}>
          {typeDate(currentSchedule ? currentSchedule.type : '')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Typography>{formatDateLabel(dateStart)}</Typography>
          <Button variant="inline" disabled={isLoading} onClick={() => dispatch(updateScheduleSteps('openCalendar'))}>
            Alterar
          </Button>
        </Box>
      </DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => {
            const payload: IReschedulingConfirmation = {
              currentScheduleId: schedules[0].id,
              dateStart: dateStartISO
            };

            if (rescheduleWithSameDoctor) {
              payload.userId = doctor?.id;
            }
            reschedulingConfirmation(payload)
              .unwrap()
              .then(() => {
                dispatch(updateScheduleSteps('sendFile'));
              })
              .catch(error => {
                console.log(error);
              });
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Salvar
        </LoadingButton>
      </Box>
    </>
  );
};

export default RescheduleAppointment;
