import BasicModal from '../../../../shared/Modal/ModalBasic';
import { ReactComponent as Archive } from '../../../../../images/archive.svg';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { Files, useGetFileUrlMutation, useLogSelectedFileMutation } from '../../../../../services/journey.service';
import { formatOnlyDate } from '../../../../../lib/dayjs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DonwloadFileRow, LinkDownload } from '../styled';
import { Typography } from '@mui/material';

interface ModalProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
  infoType?: string;
  dataFiles: Files;
}

interface OyanaFiles {
  date?: string;
  fileType?: string;
  id?: string;
  name?: string;
  url?: string;
}

interface OyanaFilesDownload {
  id: string;
  download: string;
}

const Modal = ({ openCloseController, onCloseHandler, infoType, dataFiles }: ModalProps) => {
  const [noFiles, setNoFiles] = useState(true);
  const [title, setTitle] = useState('');
  const [titleNoFiles, setTitleNoFiles] = useState('');
  const [getFileUrl, { isLoading }] = useGetFileUrlMutation();

  const [oyanaFiles, setOyanaFiles] = useState<Array<OyanaFilesDownload>>([]);
  const [files, setFiles] = useState<Array<OyanaFiles>>([
    {
      date: '',
      fileType: '',
      id: '',
      name: '',
      url: ''
    }
  ]);

  useEffect(() => {
    if (openCloseController) {
      switch (infoType) {
        case 'EXAM':
          if (dataFiles?.exams.length !== 0) {
            setTitle('Selecione um Exame');
            setFiles(dataFiles?.exams);
            setNoFiles(false);
          } else {
            setTitleNoFiles('Você ainda não possui exames');
            setNoFiles(true);
            setOyanaFiles([]);
            setFiles([
              {
                date: '',
                fileType: '',
                id: '',
                name: '',
                url: ''
              }
            ]);
          }
          break;
        case 'REVENUES':
          if (dataFiles?.requests.length !== 0) {
            setTitle('Selecione um pedido ou receita');
            setFiles(dataFiles?.requests);
            setNoFiles(false);
          } else {
            setTitleNoFiles('Você ainda não possui pedidos e receitas');
            setNoFiles(true);
            setOyanaFiles([]);
            setFiles([
              {
                date: '',
                fileType: '',
                id: '',
                name: '',
                url: ''
              }
            ]);
          }

          break;
        case 'REPORTS':
          if (dataFiles?.reports.length !== 0) {
            setTitle('Selecione um relatório');
            setFiles(dataFiles?.reports);
            setNoFiles(false);
          } else {
            setTitleNoFiles('Você ainda não possui relatórios');
            setNoFiles(true);
            setOyanaFiles([]);
            setFiles([
              {
                date: '',
                fileType: '',
                id: '',
                name: '',
                url: ''
              }
            ]);
          }
          break;
        default:
          '';
      }
    }
  }, [dataFiles, openCloseController]);

  useEffect(() => {
    if (files[0].name === '') return;
    files.map(file => {
      getFileUrl({ key: file.url || '' })
        .unwrap()
        .then(resp => {
          setOyanaFiles((prevState: any) => [...prevState, { id: file.url, download: resp.url }]);
        })
        .catch(err => console.log(err));
    });
  }, [files]);

  const OyanaFiles = () => {
    const [logFile] = useLogSelectedFileMutation();
    const findFile = (id: string) => {
      const file = oyanaFiles.find((f: any) => f.id === id);
      return file?.download;
    };

    const handleClick = (file: OyanaFiles) => {
      logSelectedFile(file);
    };

    const logSelectedFile = (file: OyanaFiles) => {
      logFile(file.id || '')
        .unwrap()
        .catch(err => console.log(err));
    };

    if (oyanaFiles.length < files.length) return null;

    return (
      <>
        {files.map((f: OyanaFiles) => {
          return (
            <LinkDownload
              key={f.url}
              href={findFile(f.url || '')}
              target="_blank"
              rel="noreferrer"
              download={f.name}
              onClick={() => handleClick(f)}
            >
              <DonwloadFileRow component="span">
                {f.name}
                <Typography variant="body2R" component="span">
                  {formatOnlyDate(f.date || '')}
                </Typography>
              </DonwloadFileRow>
              <ArrowForwardIosIcon />
            </LinkDownload>
          );
        })}
      </>
    );
  };

  return (
    <>
      <BasicModal
        open={openCloseController}
        onCloseHandler={onCloseHandler}
        title={noFiles ? titleNoFiles : title}
        typeTitle="h3"
      >
        <Box mt={4}>
          {noFiles && <Archive />}
          {isLoading ? <CircularProgress sx={{ color: 'brand.darkGreen' }} /> : null}
          <OyanaFiles />
        </Box>
      </BasicModal>
    </>
  );
};

export default Modal;
