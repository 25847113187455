import { styled } from '@mui/material/styles';
import MuiDivider from '@mui/material/Divider';

export const Divider = styled(MuiDivider)(({ theme }) => () => ({
  backgroundColor: theme.palette.neutrals.beige3,
  height: '1px',
  borderBottom: 0,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3)
}));

export const SessionLimiterStrong = styled(MuiDivider)(({ theme }) => () => ({
  backgroundColor: theme.palette.neutrals.beige3,
  height: '4px',
  borderBottom: 0,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3)
}));
