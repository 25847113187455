import { FC, useEffect, useState } from 'react';
import { IOption, IQuestion } from '../../../../../providers/AnamneseProvider/context';
import ButtonCheckbox from './ButtonCheckbox';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';

interface Props {
  question: IQuestion;
}

interface OptionList {
  options: IOption[];
  otherChoice?: string;
}
const GroupCheckbox: FC<Props> = ({ question }) => {
  const { control, trigger, getValues } = useFormContext();
  const optionsList = ({ options = [] }: IQuestion): IOption[] => {
    const list = options.map((option: IOption) => ({
      id: option.id,
      label: option.label,
      value: false
    }));
    if (question.allowOtherChoice) list.push({ id: 'other', label: 'Outro', value: false });
    return list;
  };
  const optionsListObject: OptionList = {
    options: optionsList(question),
    otherChoice: ''
  };

  const setInitialValue = (question: IQuestion): OptionList => {
    // quando o usuário navega entre as perguntas e o valor já foi preenchido
    if (getValues(question.id)) return getValues(question.id);
    // quando a pergunta é recuperável
    if (question.isRecovery) {
      const formatedAnswer = question.answer ? question.answer?.split(';') : [''];
      const optionsAnsweredStringList = question.options?.map((option: IOption) => option.label);
      const optionsAnswered = question.options?.filter((option: IOption) => {
        return formatedAnswer.includes(option.label);
      });
      const otherChoice = formatedAnswer.find((answer: string) => {
        return !optionsAnsweredStringList?.includes(answer);
      });

      if (optionsAnswered?.length) {
        optionsListObject.options.forEach((option: IOption) => {
          const optionAnswered = optionsAnswered.find((optionAnswered: IOption) => optionAnswered.label === option.label);
          if (optionAnswered) {
            option.value = true;
          }
        });
      }

      if (otherChoice) {
        optionsListObject.options.forEach((option: IOption) => {
          if (option.id === 'other') {
            option.value = true;
          }
        });
        optionsListObject.otherChoice = otherChoice;
      }

      return optionsListObject;
    }
    // quando o usuario entra na pergunta pela primeira vez
    return {
      options: optionsList(question),
      otherChoice: ''
    };
  };

  const [optionsValue, setOptionsValue] = useState<OptionList>(setInitialValue(question));

  const getOptionsValue = (optionId: string) => {
    const option = optionsValue.options.find((option: IOption) => option.id === optionId);
    return option?.value;
  };

  const updateOptionsValue = (optionId: string) => {
    const option = optionsValue.options.find((option: IOption) => option.id === optionId) || ({} as IOption);
    option.value = !option.value;
    setOptionsValue({ ...optionsValue, options: [...new Set([...optionsValue.options, option])] });
  };

  const validateOptions = (value: OptionList, isRequired: boolean, allowOtherChoice: boolean): boolean => {
    if (!isRequired) return true;
    if (!allowOtherChoice) return value.options.some((option: IOption) => option.value === true);
    if (allowOtherChoice && getOptionsValue('other') && value.otherChoice === '') return false;
    return value.options.some((option: IOption) => option.value === true);
  };

  useEffect(() => {
    trigger(question.id);
  }, []);

  return (
    <>
      <Controller
        control={control}
        defaultValue={optionsValue}
        name={question.id}
        rules={{
          validate: (value: OptionList) => {
            const { allowOtherChoice, isRequired } = question;
            const isValid = validateOptions(value, isRequired, allowOtherChoice);
            return isValid;
          }
        }}
        render={({ field: { onChange } }) => (
          <>
            {optionsValue.options?.map((option: IOption) => (
              <ButtonCheckbox
                key={option.id}
                questionId={question.id}
                option={option}
                onChange={() => {
                  onChange(optionsValue);
                  updateOptionsValue(option.id);
                  trigger(question.id);
                }}
                value={getOptionsValue(option.id) || false}
              />
            ))}

            {question.allowOtherChoice && getOptionsValue('other') ? (
              <TextField
                value={optionsValue.otherChoice}
                placeholder="Descreva aqui"
                type="text"
                variant="standard"
                fullWidth
                onChange={e => {
                  setOptionsValue({ ...optionsValue, otherChoice: e.target.value });
                  onChange({ ...optionsValue, otherChoice: e.target.value });
                  trigger(question.id);
                }}
              />
            ) : (
              []
            )}
          </>
        )}
      />
    </>
  );
};

export default GroupCheckbox;
