import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiModal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Button from '@mui/material/Button';
import { SuccessIcon } from './style';
import UploadFiles from './UploadFiles';
import { ModalBoxContent, ModalContent, ModalHeader, ModalWrapper } from '../Modal/ModalBasic/styled';
import { RootState } from '../../../store';
import { doneSendExams } from '../../../features/schedule/schedule-slice';
import { fileUploadModalClose } from '../../../features/fileUpload';

const ModalFileUpload = () => {
  const {
    fileUpload: { open, title, forwardRefJorneyId, forwardRefId, successMessage, homeButton, onClose }
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [screenStep, setScreenStep] = useState<string>('upload');
  const [clearFiles, setClearFiles] = useState<boolean>(false);
  useEffect(() => {
    if (screenStep === 'success') {
      setClearFiles(true);
      dispatch(doneSendExams(true));
    }
  }, [screenStep]);

  return (
    <MuiModal open={open} aria-labelledby="envio-de-arquivos" aria-describedby="enviar arquivos">
      <ModalWrapper>
        <ModalContent>
          <ModalHeader>
            <CloseIcon
              onClick={() => {
                dispatch(fileUploadModalClose());
                setClearFiles(true);
                setTimeout(() => {
                  setScreenStep('upload');
                  dispatch(doneSendExams(false));
                }, 500);
                if (onClose) onClose();
              }}
              sx={{ cursor: 'pointer' }}
            />
          </ModalHeader>
          {screenStep === 'upload' ? (
            <ModalBoxContent sx={{ px: '1px' }} id="modal-modal-description">
              <Typography variant="h3" sx={{ mb: 2 }}>
                {title}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Formatos permitidos: Imagens e PDFs de até 10MB
              </Typography>
              <UploadFiles
                isSuccess={() => setScreenStep('success')}
                reset={clearFiles}
                journeyId={forwardRefJorneyId}
                oyanaId={forwardRefId}
              />
            </ModalBoxContent>
          ) : (
            <ModalBoxContent sx={{ px: '1px' }} id="modal-modal-description">
              <SuccessIcon>
                <CheckOutlinedIcon />
              </SuccessIcon>
              <Typography variant="h1" sx={{ mb: 2 }}>
                Enviado!
              </Typography>
              <Typography variant="body1">{successMessage}</Typography>
              {homeButton && (
                <Button
                  component="label"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ display: 'flex', justifyContent: ' space-between', marginTop: 5 }}
                >
                  Ir para a Minha Conta
                </Button>
              )}
            </ModalBoxContent>
          )}
        </ModalContent>
      </ModalWrapper>
    </MuiModal>
  );
};

export default ModalFileUpload;
