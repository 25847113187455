import { useState } from 'react';
import Box from '@mui/material/Box';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import { RatingHighlight } from '..';
import BasicModal from '../../Modal/ModalBasic';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { CardMedia } from '../../../pages/Journey/InformationAbout/Attend/styled';
import { useSaveRatingMutation, useNoshowMutation } from '../../../../services/csat.service';
import { ButtonNoShow, Rating, RationgAction, TextField } from './styled';
import LowRating from './LowRating';
import HighRating from './HighRating';
import NoShow from './NoShow';
import { formatDateLabel } from '../../../../lib/dayjs';

interface ModalProps {
  toggleHandler: boolean;
  onCloseHandler: () => void;
  ratingHighlight?: RatingHighlight;
}

const Modal = ({ toggleHandler, onCloseHandler, ratingHighlight }: ModalProps) => {
  const [rating, setRating] = useState<number | null>(null);
  const [comment, setComment] = useState<string>('');
  const [step, setStep] = useState<number>(0);
  const [saveRating, { isLoading, isSuccess, reset }] = useSaveRatingMutation();
  const [noshow, { isLoading: noShowisLoading, isSuccess: isSuccessNoShow, reset: resetNoShow }] = useNoshowMutation();

  const handleSaveRating = () => {
    if (rating) {
      saveRating({
        rate: rating,
        scheduleId: ratingHighlight?.scredule?.id as string,
        comment
      }).then(() => {
        setStep(1);
      });
    }
  };

  const handleNoShow = () => {
    if (rating !== null) return;
    noshow({
      scheduleId: ratingHighlight?.scredule?.id as string
    }).then(() => {
      setRating(null);
      setStep(1);
    });
  };

  const resetState = () => {
    setStep(0);
    setRating(null);
    setComment('');
    reset();
    resetNoShow();
  };

  const handleOnClose = () => {
    onCloseHandler();
    resetState();
  };

  return (
    <>
      <BasicModal
        open={toggleHandler}
        onCloseHandler={() => handleOnClose()}
        title={step === 0 ? 'Foi um grande prazer ter te atendido!' : ''}
      >
        {isSuccessNoShow && rating === null && <NoShow />}
        {(isSuccess && rating === 0) || (isSuccess && rating && rating <= 3 && <LowRating />)}
        {isSuccess && rating && rating > 3 && <HighRating />}
        {step === 0 && !isSuccessNoShow && !isSuccess && (
          <Box>
            <Box sx={{ display: 'flex', mb: 3 }}>
              {ratingHighlight?.doctorProfileImage ? (
                <CardMedia
                  sx={{ mr: 2 }}
                  component="img"
                  alt={ratingHighlight?.doctorName}
                  image={ratingHighlight?.doctorProfileImage}
                />
              ) : (
                []
              )}

              <Typography variant="body1">
                Que nota você daria para o seu atendimento com a {ratingHighlight?.doctorName} no dia{' '}
                {formatDateLabel(ratingHighlight?.scredule?.dateStart || '')}?
              </Typography>
            </Box>
            <Rating
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              emptyIcon={<StarOutlineRoundedIcon fontSize="large" />}
              icon={<StarRateRoundedIcon fontSize="large" />}
            />

            <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
              Conte-nos o por quê da sua nota:
            </Typography>
            <TextField
              value={comment}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              autoComplete="off"
              onChange={event => setComment(event.target.value)}
            />
            <RationgAction>
              <LoadingButton
                sx={{ mr: 3 }}
                disabled={!rating}
                loading={isLoading}
                variant="contained"
                color="primary"
                onClick={handleSaveRating}
              >
                Enviar avaliação
              </LoadingButton>

              <ButtonNoShow color="primary" onClick={handleNoShow} className={rating === null ? '' : 'disabled'}>
                {noShowisLoading ? 'A consulta não ocorreu ...' : 'A consulta não ocorreu'}
              </ButtonNoShow>
            </RationgAction>
          </Box>
        )}
      </BasicModal>
    </>
  );
};

export default Modal;
