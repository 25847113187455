import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export const Circle = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%'
}));

export const LinkDownload = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  wordBreak: 'break-all',
  textDecoration: 'none',
  padding: `${theme.spacing(2)} 0`,
  borderTop: `1px solid ${theme.palette.neutrals.beige4}`,
  cursor: 'pointer',
  svg: {
    fill: theme.palette.neutrals.beige4
  }
}));

export const DonwloadFile = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const DonwloadFileRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: theme.spacing(2),
  span: {
    marginTop: theme.spacing(1)
  }
}));
