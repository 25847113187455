import { PropsWithChildren } from 'react';
import HeaderTwo from '../HeaderTwo';
import MainThree from '../MainThree';
import Logo from '../../images/logo_horizontal_black.svg';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

const TemplateFullWithHeader = ({ children }: PropsWithChildren) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignContent: 'stretch', flexWrap: 'wrap' }}>
      <HeaderTwo>
        <CardMedia component="img" alt="Logo" image={Logo} sx={{ objectFit: 'contain', width: '88px' }} />
      </HeaderTwo>
      <MainThree>{children}</MainThree>
    </Box>
  );
};

export default TemplateFullWithHeader;
