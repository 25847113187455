import { api } from './api.service';
import { Files } from './journey.service';

export interface IOyanaAddresses {
  id: string;
  cep: string;
  city: string;
  state: string;
  country: string;
  address: string;
  addressNumber: number;
  addressComplement: string;
  addressReference: string;
  neighborhood: string;
}

export interface IOyanaResponse {
  id: string;
  cpf: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  birthDate: string;
  nickName: string;
  fullName: string;
  addresses: Array<IOyanaAddresses>;
}

export interface ISingInOTPRequest {
  oyanaid: string;
  code: string;
}

export const oyana = api.injectEndpoints({
  endpoints: build => ({
    getPersonalData: build.mutation<IOyanaResponse, any>({
      query: () => ({
        url: `/portal/v1/me`
      })
    }),
    getMyPersonalFiles: build.mutation<Files, any>({
      query: () => ({
        url: `/portal/v1/me/files`
      })
    })
  })
});

export const { useGetPersonalDataMutation, useGetMyPersonalFilesMutation } = oyana;
