import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import MuiGrid from '@mui/material/Unstable_Grid2';
import MuiContainer, { ContainerProps } from '@mui/material/Container';

export const SectionRoot = styled(MuiBox)(() => ({
  backgroundColor: 'transparent'
}));

export const SectionGrid = styled(MuiGrid)(() => ({
  margin: 'auto'
}));

export const MainGrid = styled(MuiGrid)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse'
  }
}));

export const FullHeightOnly = styled(MuiGrid)(({ theme }) => ({
  minHeight: 'calc(100vh - 302px)',
  [theme.breakpoints.down('md')]: {
    minHeight: 'calc(100vh - 250px)'
  }
}));

export const FullHeightBig = styled(MuiGrid)(() => ({
  minHeight: 'calc(100vh)'
}));

export const FullHeight = styled(MuiGrid)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  minHeight: 'calc(100vh - 302px)',
  [theme.breakpoints.down('md')]: {
    minHeight: 'calc(100vh - 250px)',
    flexDirection: 'column-reverse'
  }
}));

export const InnerSectionContainer = styled((props: ContainerProps) => <MuiContainer disableGutters {...props} />)(
  ({ theme }) => ({
    padding: '80px',

    [theme.breakpoints.down('md')]: {
      padding: '24px 24px 54px 24px;'
    }
  })
);

export const InnerSectionContainerTwo = styled(MuiBox)(({ theme }) => ({
  padding: '0px 80px 0px 80px;',
  position: 'absolute',
  overflow: 'auto',
  top: 0,
  left: 0,
  bottom: 0,
  minHeight: '100%',
  width: '100%',
  backgroundColor: theme.palette.brand.darkGreen,

  [theme.breakpoints.down('md')]: {
    padding: 0
  }
}));

export const InnerSectionContainerThree = styled((props: ContainerProps) => <MuiContainer disableGutters {...props} />)(
  ({ theme }) => ({
    padding: '0px 80px 24px 80px;',

    [theme.breakpoints.down('md')]: {
      padding: '0px 24px 0px 24px;'
    }
  })
);

export const SectionFullWidth = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  padding: '0 80px',
  [theme.breakpoints.down('md')]: {
    padding: '0 40px'
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 24px'
  }
}));

export const InnerSectionBox = styled(MuiBox)(({ theme }) => ({
  padding: '80px 0',
  [theme.breakpoints.down('md')]: {
    padding: '56px 0'
  }
}));
