import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiLinearProgress from '@mui/material/LinearProgress';

export const GridRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  width: '670px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

export const Form = styled('form')(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column'
}));

export const BoxScroll = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  padding: '12px',
  height: '100%',
  '::-webkit-scrollbar': {
    width: '8px'
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey'
  },

  '::-webkit-scrollbar-track': {
    background: theme.palette.neutrals.beige1
  }
}));

export const BoxCard = styled(Box)(({ theme }) => ({
  borderRadius: '16px 16px 0px 0px',
  backgroundColor: theme.palette.neutrals.beige1,
  width: '100%',
  padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
  flex: '1'
}));

export const Question = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige3,
  borderRadius: '8px',
  padding: '16px',
  marginBottom: '16px'
}));

export const QuestionTitle = styled(Typography)(() => ({}));
export const QuestionSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

export const QuestionRoot = styled(Box)(() => ({
  height: '100%'
}));

export const QuestionBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
  '&.with-subtitle': {
    marginTop: theme.spacing(1)
  }
}));
export const QuestionField = styled(Box)(() => ({}));
export const QuestionAction = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(7)
}));
export const QuestionActionButton = styled(LoadingButton)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  '&.Mui-disabled': {
    fontWeight: '400'
  }
}));

export const BackButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.neutrals.beige1,
  padding: 0
}));

export const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  backgroundColor: `${theme.palette.brand.mediumGreen}5e`,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.brand.mediumGreen
  }
}));
