import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Dialog, DialogContent, DialogTitle } from '../styled';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { backToTopPage, doneReschedule } from '../../../../features/schedule/schedule-slice';
import { fileUploadModalOpen } from '../../../../features/fileUpload';
import { RootState } from '../../../../store';
import { getCurrentSchedule } from '../../JourneyActionManager';

interface IProps {
  open: boolean;
}

const SendFiles = ({ open }: IProps) => {
  const dispatch = useDispatch();
  const { journey } = useSelector((state: RootState) => state);
  const currentSchedule = getCurrentSchedule(journey);
  const titleFormat = (type: string) => {
    switch (type) {
      case 'EXAM':
        return 'Seu exame está confirmado!';
      case 'VIRTUAL_CONSULTATION':
      case 'PHYSICAL_APPOINTMENT':
        return 'Sua consulta está confirmada!';
      default:
        '';
    }
  };

  const handleUploadFiles = () => {
    dispatch(doneReschedule());
    dispatch(backToTopPage());
    dispatch(
      fileUploadModalOpen({
        title: 'Enviar exames',
        successMessage:
          'Seus exames foram enviados com sucesso para o seu prontuário e já estão disponíveis para visualização na sua conta.'
      })
    );
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          <CloseIcon
            onClick={() => {
              dispatch(doneReschedule());
              dispatch(backToTopPage());
            }}
            sx={{ cursor: 'pointer', mb: 3 }}
          />
          <Typography variant="h3" sx={{ mb: 2 }}>
            {titleFormat(currentSchedule ? currentSchedule.type : '')}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography>
            Ao realizar novos exames para o seu atendimento, lembre-se de nos enviar os resultados. É só anexar os documentos
            aqui na sua conta.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 2, gap: 3, flexWrap: 'wrap' }}>
            <Button variant="contained" onClick={() => handleUploadFiles()}>
              Enviar exames
            </Button>
            <Button
              variant="inline"
              onClick={() => {
                dispatch(doneReschedule());
                dispatch(backToTopPage());
              }}
            >
              Agora não, obrigada
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default SendFiles;
