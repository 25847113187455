import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';

export const LoginButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutrals.lead,
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  path: {
    fill: theme.palette.neutrals.lead
  }
}));

export const WrapperBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige1
}));

export const MenuListItem = styled(ListItemButton)(({ theme }) => ({
  textDecoration: 'none',
  paddingTop: 19,
  paddingBottom: 19,
  paddingLeft: 24,
  paddingRight: 24,
  color: theme.palette.neutrals.lead,
  a: {
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.neutrals.lead
    }
  },
  '.MuiListItemIcon-root': {
    minWidth: 'auto',
    marginRight: theme.spacing(1)
  },
  svg: {
    path: {
      color: theme.palette.neutrals.lead
    }
  }
}));

export const HeaderMobile = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige1,
  textAlign: 'right',
  padding: '16px 24px',
  height: 56,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 1
}));
