import { de } from 'date-fns/locale';
import { api } from './api.service';

interface IAvailableTimesQuery {
  type?: string;
  calendarId: string;
  doctorId?: string;
  month: string;
  year: string;
}

export enum AvailableTimesType {
  SCHEDULE_AFTER_EXAM = 'scheduleAfterExam',
  SCHEDULE_RETURN = 'scheduleReturn',
  RESCHEDULE_RETURN = 'rescheduleReturn',
  RESCHEDULE_WITH_SAME_DOCTOR = 'rescheduleWithSameDoctor',
  ANY_SCHEDULE = 'anySchedule'
}

export const availableTimes = api.injectEndpoints({
  endpoints: build => ({
    getAvailableTimes: build.mutation<any, IAvailableTimesQuery>({
      query: ({ type = '', calendarId = '', doctorId = '', month, year }) => {
        switch (type) {
          case AvailableTimesType.SCHEDULE_AFTER_EXAM:
            return {
              url: `calendars/${calendarId}/availableTimes/${month}/${year}`
            };
          case AvailableTimesType.SCHEDULE_RETURN:
          case AvailableTimesType.RESCHEDULE_RETURN:
            return {
              url: `calendars/${calendarId}/${doctorId}/availableTimes/${month}/${year}/true`
            };
          case AvailableTimesType.RESCHEDULE_WITH_SAME_DOCTOR:
            return {
              url: `calendars/${calendarId}/${doctorId}/availableTimes/${month}/${year}/false`
            };
          default:
            return {
              url: `calendars/${calendarId}/availableTimes/${month}/${year}`
            };
        }
      }
    })
  })
});

export const { useGetAvailableTimesMutation } = availableTimes;
