import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { BodyButton } from './styled';

interface ButtonCheckboxProps {
  questionId: string;
  value: boolean;
  option: {
    id: string;
    label: string;
  };
  onChange: () => void;
}

const ButtonCheckbox: FC<ButtonCheckboxProps> = ({ option, value, onChange }) => {
  return (
    <BodyButton
      className={value ? 'checked' : 'unchecked'}
      onClick={e => {
        e.preventDefault();
        onChange();
      }}
    >
      <FormControlLabel
        label={option.label}
        control={
          <Checkbox
            checked={value}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              marginRight: '5px',
              color: 'neutrals.lead',
              '&.Mui-checked': {
                color: 'brand.lightGreen'
              }
            }}
            onChange={onChange}
          />
        }
      />
    </BodyButton>
  );
};

export default ButtonCheckbox;
