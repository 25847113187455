import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import MaskedInput from 'react-text-mask';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useUserVerifyMutation } from '../../../../services/singnin.service';
import { saveOyanaIdentity } from '../../../../features/singin/singin-slice';
import { setRedirect } from '../../../../features/redirect';
import isCpf from '../../../../validations/isCpf';
import { Typography } from '@mui/material';

interface ISignIn {
  oyana: {
    cpf: string;
  };
}

const SignIn = () => {
  const {
    control,
    register,
    trigger,
    getValues,
    setError,
    setFocus,
    formState: { errors }
  } = useForm<ISignIn>();

  const [userVerify, { isLoading }] = useUserVerifyMutation();
  const [cookies, setCookie, removeCookie] = useCookies(['oya.oyanaid', 'oya.method1', 'oya.method2']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const redirectTo = searchParams.get('redirectTo');
  if (redirectTo) {
    dispatch(setRedirect(redirectTo));
  }

  const formatCpf = (cpf: string): string => {
    return cpf.replace(/[.-]/g, '');
  };

  const submit = () => {
    const cpf = formatCpf(getValues('oyana.cpf'));
    const cookieConfig = { secure: true, maxAge: 86400 };

    userVerify({ cpf })
      .unwrap()
      .then(res => {
        const { oyanaid, email, whatsapp } = res;
        setCookie('oya.oyanaid', oyanaid, cookieConfig);
        setCookie('oya.method1', email, cookieConfig);
        setCookie('oya.method2', whatsapp, cookieConfig);
        dispatch(saveOyanaIdentity({ oyanaid, email, whatsapp }));
      })
      .then(() => {
        navigate('/login/metodo-de-envio');
      })
      .catch(err => {
        setError('oyana.cpf', { type: 'manual', message: err?.data?.message });
      });
  };

  useEffect(() => {
    setFocus('oyana.cpf');
  }, [setFocus]);

  useEffect(() => {
    removeCookie('oya.oyanaid');
    removeCookie('oya.method1');
    removeCookie('oya.method2');
  }, []);

  return (
    <>
      <form
        id="form-2"
        onSubmit={e => e.preventDefault()}
        onKeyPress={e => {
          if (e.code === 'Enter') {
            submit();
          }
        }}
        autoComplete="on"
      >
        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Digite seu <strong>CPF</strong>
          </Typography>
          <Controller
            name="oyana.cpf"
            control={control}
            render={() => (
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                className="form-control"
                guide={false}
                render={(ref, props) => (
                  <TextField
                    {...props}
                    inputRef={ref}
                    helperText={errors?.oyana?.cpf && errors?.oyana?.cpf.message}
                    error={errors?.oyana?.cpf && true}
                    type="tel"
                    variant="standard"
                    inputProps={{
                      maxLength: 14
                    }}
                    fullWidth
                    placeholder="000.000.000-00"
                    sx={{ mb: 4 }}
                    {...register('oyana.cpf', {
                      validate: value => isCpf(value) || 'CPF inválido',
                      required: 'Campo Obrigatório',
                      onBlur: () => trigger('oyana.cpf'),
                      onChange: (event: any) => {
                        const value = event.target.value;
                        if (value.length === 14) {
                          trigger('oyana.cpf');
                        }
                      }
                    })}
                  />
                )}
              />
            )}
          />

          <LoadingButton
            sx={{ display: 'inline-flex', justifyContent: 'space-between' }}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            fullWidth
            onClick={() => submit()}
            loading={isLoading}
            disabled={getValues('oyana.cpf') === '' || Object.keys(errors).length > 0}
          >
            Receber código
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default SignIn;
