import { IJourneyResp } from '../../../../services/journey.service';
import { EnumJourneyAction, getJourneyAction } from '../../../../utils/enum/journeyAction';
import { getCurrentSchedule } from '../../JourneyActionManager';

export const formatTitleByJourneyStatus = (journey: IJourneyResp): string => {
  const status = getJourneyAction(journey);
  const currentSchedule = getCurrentSchedule(journey);
  switch (status) {
    case EnumJourneyAction.AWAITING_SCHEDULE:
      return `Agendamento de consulta`;
    case EnumJourneyAction.AWAITING_CONSULTATION:
      if (currentSchedule?.allowReschedule) return `Reagendar consulta`;
      return `Agendamento de consulta virtual`;
    case EnumJourneyAction.VIDEOCALL_AVAILABLE:
      if (currentSchedule?.allowReschedule) return `Reagendar consulta`;
      return `Agendamento de consulta virtual`;
    case EnumJourneyAction.AWAITING_EXAM:
      return `Agendamento de exame`;
    case EnumJourneyAction.AWAITING_SCHEDULE_RETURN:
      return `Agendamento de retorno`;
    default:
      return `Agendamento`;
  }
};
