import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';

import { RootState } from '../../store';
import { useGetMyPersonalFilesMutation, useGetPersonalDataMutation } from '../../services/me.service';
import { Files } from '../../services/journey.service';
import { saveOyanaPersonaData } from '../../features/me/me-slice';

import ContentExams from '../../components/pages/Journey/ContentExams';
import { ViewContent } from '../../layout/Main/styled';
import { updateFiles } from '../../features/journey';
import TemplateDefault from '../../layout/TemplateDefault';

const MyFiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    schedule: {
      sendExams,
      ui: { backToTop }
    },
    singIn: {
      auth: { access_token }
    },
    oyana: {
      me: { nickName }
    }
  } = useSelector((state: RootState) => state);
  const [getPersonalData, { data: dataOyana }] = useGetPersonalDataMutation();
  const [getPersonalFiles, { isLoading }] = useGetMyPersonalFilesMutation();

  const loadFiles = () => {
    getPersonalFiles({})
      .unwrap()
      .then((res: Files) => {
        dispatch(updateFiles(res));
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadFiles();
    if (access_token && nickName === '') getPersonalData({});
  }, []);

  useEffect(() => {
    if (sendExams) loadFiles();
  }, [sendExams]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [backToTop]);

  useEffect(() => {
    if (dataOyana) {
      dispatch(saveOyanaPersonaData(dataOyana));
    }
  }, [dataOyana]);

  return (
    <>
      <TemplateDefault>
        <ViewContent className="ViewContent">
          <Grid container>
            <Grid item xs={0} sm={1} md={3}></Grid>
            <Grid item xs={12} sm={10} md={6}>
              <IconButton
                sx={{ width: 48, height: 48, backgroundColor: 'neutrals.beige3', mb: 4 }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>

              <Typography mb={3} variant="h3">
                Meus arquivos
              </Typography>
              {!isLoading ? <ContentExams /> : <CircularProgress sx={{ color: 'brand.darkGreen' }} />}
            </Grid>
            <Grid item xs={0} sm={1} md={3}></Grid>
          </Grid>
        </ViewContent>
      </TemplateDefault>
    </>
  );
};

export default MyFiles;
