import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISingInResponse } from '../../services/singnin.service';

export interface ISingIn {
  identity: ISingInResponse;
  auth: {
    access_token: string;
    expiredToken: boolean;
  };
}

const initialState: ISingIn = {
  identity: {
    oyanaid: '',
    email: '',
    whatsapp: ''
  },
  auth: {
    access_token: '',
    expiredToken: false
  }
};

export const singIn = createSlice({
  name: 'singin',
  initialState,
  reducers: {
    saveOyanaIdentity: (state, action: PayloadAction<ISingInResponse>) => {
      state.identity = action.payload;
    },
    saveOyanaAuth: (state, action: PayloadAction<string>) => {
      state.auth.access_token = action.payload;
    },
    expireAuth: (state, action) => {
      state.auth.expiredToken = action.payload;
    }
  }
});

export const { saveOyanaIdentity, saveOyanaAuth, expireAuth } = singIn.actions;
export const singInReducer = singIn.reducer;
