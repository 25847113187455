import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const LinearOptions = styled(Box)(({ theme }) => ({
  margin: `0 0 ${theme.spacing(1)} 0`,
  padding: 0,
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'space-between'
}));

export const LinearOption = styled('li')(({ theme }) => ({
  alignContent: 'center',
  marginLeft: theme.spacing(1),
  width: '100%',
  padding: '17px 0',
  borderRadius: '12px',
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  display: 'flex',
  justifyContent: 'center',
  ':first-of-type': {
    marginLeft: 0
  },
  ':hover': {
    border: `1px solid ${theme.palette.brand.darkGreen}`,
    cursor: 'pointer'
  },
  '&.selected': {
    backgroundColor: theme.palette.brand.darkGreen,
    color: theme.palette.neutrals.beige1
  }
}));

export const LinearOptionLabel = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));
