import { api } from './api.service';

export type fileType = 'REPORT' | 'REQUEST' | 'EXAM' | 'OTHER' | 'HAM' | 'PRIVATE';

export enum fileTypes {
  REPORT = 'REPORT',
  REQUEST = 'REQUEST',
  EXAM = 'EXAM',
  OTHER = 'OTHER',
  HAM = 'HAM',
  PRIVATE = 'PRIVATE'
}

export interface IFileUploadRequest {
  id: string | null;
  name: string;
  fileType: fileType;
  maskType: string;
  url: string;
  report: null;
  oyana: any;
  status: string;
  showToOyana: boolean;
  scheduleId: string | null;
  journeyId: string | null;
  updatedAt?: Date | null;
}

export const OyanaFileUpload = api.injectEndpoints({
  endpoints: build => ({
    uploadSingleFile: build.mutation<any, any>({
      query: data => ({
        url: `/oyanafiles`,
        method: 'POST',
        body: data,
        headers: {}
      })
    }),
    deleteSingleFile: build.mutation<any, any>({
      query: ({ id }) => ({
        url: `/oyanafiles/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const { useUploadSingleFileMutation, useDeleteSingleFileMutation } = OyanaFileUpload;
