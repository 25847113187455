import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  borderRadius: '100%',
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

export const Card = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  borderRadius: '16px',
  padding: theme.spacing(4)
}));
