import { Fragment } from 'react';
import useAnamnese from '../../../../providers/AnamneseProvider/use';
import Validation from '../Validation';
import { IQuestion } from '../../../../providers/AnamneseProvider/context';
import { QuestionTitle } from '../style';

interface Props {
  questions: IQuestion[];
}
const QuestionList = ({ questions }: Props) => {
  const { state } = useAnamnese();
  const questionsFiltered = questions.filter(
    (q: IQuestion) =>
      q.isSkipped === false ||
      (q.isSkipped === true && q.answer === undefined) || // não logado
      (q.isSkipped === true && q.answer && q.answer === '') // logado
  );

  return (
    <>
      {questionsFiltered.map((q: IQuestion, index: number) => {
        if (index === state.step.question.current) {
          return (
            <Fragment key={q.id}>
              <QuestionTitle variant="h3">{q.title}</QuestionTitle>
              <Validation key={q.id} question={q} />
            </Fragment>
          );
        }
      })}
    </>
  );
};

export default QuestionList;
