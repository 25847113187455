import BasicModal from '../../../../../shared/Modal/ModalBasic';
import Typography from '@mui/material/Typography';

interface ModalProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
}

const Modal = ({ openCloseController, onCloseHandler }: ModalProps) => {
  return (
    <>
      <BasicModal open={openCloseController} onCloseHandler={onCloseHandler} title="Como funciona a consulta presencial?">
        <Typography variant="body1" sx={{ mb: 4 }}>
          A consulta presencial acontece na nossa clínica em São Paulo (SP). A Clínica da Oya é moderna e acolhedora, um
          refúgio que une tecnologia de ponta e equipamentos de última geração em um ambiente projetado com carinho, sempre
          de portas abertas para te ouvir e amparar.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Durante a consulta, um dos médicos e médicas da nossa equipe vai conversar com você sobre suas dúvidas e
          desconfortos e pensar em um plano de ação a partir do seu histórico de saúde, estilo de vida e necessidades do
          momento.
        </Typography>
      </BasicModal>
    </>
  );
};

export default Modal;
