import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatDateLabel } from '../../../../../lib/dayjs';
import { updateScheduleSteps } from '../../../../../features/schedule/schedule-slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Divider } from '../../../Divider';
import { DialogContent } from '../../styled';
import { useScheduleReturnConfirmationMutation } from '../../../../../services/schedule.service';

const ScheduleReturn = () => {
  const {
    schedule: { dateStart, dateStartISO },
    journey: { journeyid, doctor },
    journeyManager: { calendarTypeSelected }
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [scheduleReturnConfirmation, { isLoading }] = useScheduleReturnConfirmationMutation();
  return (
    <>
      <DialogContent>
        <Typography>Depois de agendar, você ainda poderá alterar a data da consulta.</Typography>
        <Divider />
        <Typography variant="body1B">Data do retorno</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography>{formatDateLabel(dateStart)}</Typography>
          <Button variant="inline" disabled={isLoading} onClick={() => dispatch(updateScheduleSteps('openCalendar'))}>
            Alterar
          </Button>
        </Box>
      </DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => {
            scheduleReturnConfirmation({
              calendarid: calendarTypeSelected.id || '',
              dateStart: dateStartISO,
              journeyid,
              returnSchedule: true,
              userid: doctor?.id || '',
              timezone: '-3'
            })
              .unwrap()
              .then(() => {
                dispatch(updateScheduleSteps('sendFile'));
              })
              .catch(error => {
                console.log(error);
              });
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Salvar
        </LoadingButton>
      </Box>
    </>
  );
};

export default ScheduleReturn;
