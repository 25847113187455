import { Controller, useFormContext } from 'react-hook-form';
import { IQuestion } from '../../../../../providers/AnamneseProvider/context';
import { Typography } from '@mui/material';
import { LinearOption, LinearOptionLabel, LinearOptions } from './styled';

interface LinearScaleProps {
  question: IQuestion;
}

const LinearScale = ({ question }: LinearScaleProps) => {
  const { control, trigger, getValues } = useFormContext();
  const options = ['1', '2', '3', '4', '5'];
  const validateOptions = (value: string, isRequired: boolean): boolean => {
    if (!isRequired) return true;
    if (value === '') {
      return false;
    }
    return true;
  };
  return (
    <>
      <Controller
        control={control}
        defaultValue={question.isRecovery ? question.answer : ''}
        name={question.id}
        rules={{
          validate: (value: string) => validateOptions(value, question.isRequired)
        }}
        render={({ field: { onChange } }) => {
          return (
            <>
              <LinearOptions component={'ul'}>
                {options.map((value, index) => (
                  <LinearOption
                    key={index}
                    className={`${getValues(question.id) === value ? 'selected' : ''}`}
                    onClick={() => {
                      if (!question.isRequired && options.includes(getValues(question.id))) {
                        onChange('');
                      } else {
                        onChange(value);
                      }
                      trigger(question.id);
                    }}
                  >
                    {value}
                  </LinearOption>
                ))}
              </LinearOptions>
              <LinearOptionLabel>
                <Typography variant="body1" component={'span'}>
                  Pouco
                </Typography>
                <Typography variant="body1" component={'span'}>
                  Muito
                </Typography>
              </LinearOptionLabel>
            </>
          );
        }}
      />
    </>
  );
};

export default LinearScale;
