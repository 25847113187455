import Box from '@mui/material/Box';
import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IQuestion } from '../../../../../providers/AnamneseProvider/context';
import { ButtonField } from '../style';

interface FieldBooleanProps {
  question: IQuestion;
}

const FieldBoolean: FC<FieldBooleanProps> = ({ question }) => {
  const { control, trigger, getValues } = useFormContext();

  const [inputValue, setInputValue] = useState<string>(getValues(question.id) ? getValues(question.id) : '');

  const validateOptions = (value: string, isRequired: boolean): boolean => {
    if (!isRequired) return true;
    if (value === '') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    trigger(question.id);
  }, []);

  return (
    <Controller
      control={control}
      defaultValue={inputValue}
      name={question.id}
      rules={{
        validate: (value: string) => validateOptions(value, question.isRequired)
      }}
      render={({ field: { onChange } }) => {
        return (
          <Box display="flex" flexDirection="column" width="100%">
            <ButtonField
              type="button"
              value="Sim"
              className={`${inputValue === 'yes' ? 'active' : ''}`}
              onClick={() => {
                if (!question.isRequired && getValues(question.id) === 'yes') {
                  onChange('');
                  setInputValue('');
                } else {
                  onChange('yes');
                  setInputValue('yes');
                }
                trigger(question.id);
              }}
            />
            <ButtonField
              type="button"
              value="Não"
              className={`${inputValue === 'no' ? 'active' : ''}`}
              onClick={() => {
                if (!question.isRequired && getValues(question.id) === 'no') {
                  onChange('');
                  setInputValue('');
                } else {
                  onChange('no');
                  setInputValue('no');
                }
                trigger(question.id);
              }}
            />
          </Box>
        );
      }}
    />
  );
};

export default FieldBoolean;
