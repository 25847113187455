import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PincodeInput from 'react-pinco';

import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { OneTimePasswordStyled } from './styled';
import AlertMessage from '../../../shared/AlertMessage';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { RootState } from '../../../../store';
import { useOtpVerifyMutation, useSingInMethodMutation } from '../../../../services/singnin.service';
import { saveOyanaAuth } from '../../../../features/singin/singin-slice';
import { setRedirect } from '../../../../features/redirect';

const OneTimePassword = () => {
  const {
    redirect: { redirectTo }
  } = useSelector((state: RootState) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpVerify, { isLoading: isLoadingOTP, isError }] = useOtpVerifyMutation();
  const [singInMethod, { isLoading: isLoadingRetry }] = useSingInMethodMutation();
  const [otp, setOTP] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies([
    'access_token',
    'oya.oyanaid',
    'oya.method1',
    'oya.method2',
    'oya.singInMethod'
  ]);

  const submit = () => {
    if (!cookies['oya.oyanaid']) return navigate('/login');

    otpVerify({ code: otp, oyanaid: cookies['oya.oyanaid'] })
      .unwrap()
      .then(resp => {
        const access_token = resp.token;
        setCookie('access_token', access_token, { secure: true, maxAge: 86400 });
        removeCookie('oya.oyanaid');
        removeCookie('oya.method1');
        removeCookie('oya.method2');
        removeCookie('oya.singInMethod');
        dispatch(saveOyanaAuth(access_token));
        if (redirectTo) {
          navigate(redirectTo);
          dispatch(setRedirect(''));
        } else {
          navigate('/');
        }
      })
      .catch(err => {
        setErrorMessage(err.data.message);
      });
  };

  const retry = (e: any) => {
    e.preventDefault();
    singInMethod({ id: cookies['oya.oyanaid'], method: cookies['oya.singInMethod'] })
      .unwrap()
      .catch((err: any) => {
        setErrorMessage(err.data.message);
      });
  };

  return (
    <>
      <form
        id="form-3"
        onSubmit={e => e.preventDefault()}
        onKeyPress={e => {
          if (e.code === 'Enter') {
            submit();
          }
        }}
        autoComplete="on"
      >
        <Typography variant="body1" sx={{ mb: 4 }}>
          Nós enviamos seu código para{' '}
          <strong>{cookies['oya.singInMethod'] === 'email' ? cookies['oya.method1'] : cookies['oya.method2']}</strong>
        </Typography>
        <OneTimePasswordStyled>
          <PincodeInput
            onChange={(value: any) => {
              setOTP(value.join(''));
            }}
            numInputs={6}
            containerStyle="Pincode-container"
            inputStyle="Pincode-input"
            errorStyle="Pincode-error"
            isInputNum={true}
            hasErrored={isError}
            sx={{ borderRadius: 0 }}
          />
        </OneTimePasswordStyled>

        <Typography variant="body1" sx={{ mb: 4 }}>
          Não recebeu?{' '}
          <Link onClick={e => retry(e)} sx={{ cursor: 'pointer' }}>
            Reenviar o código
          </Link>
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" startIcon={<ArrowBackIcon />} color="secondary" onClick={() => navigate(-1)}>
            Voltar
          </Button>

          <LoadingButton
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={() => submit()}
            loading={isLoadingOTP}
            disabled={otp.length === 6 ? false : true}
          >
            Continuar
          </LoadingButton>
        </Box>
        {isLoadingRetry && <AlertMessage text="O código foi reenviado." type="success" />}
        {isError && <AlertMessage text={errorMessage} textButton="Tente novamente" type="error" />}
      </form>
    </>
  );
};

export default OneTimePassword;
