import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ECalendarType, Files, IJourneyResp as IJourneyResponse } from '../../services/journey.service';

export const initialState: IJourneyResponse = {
  status: '',
  journeyid: '',
  schedules: [
    {
      address: {
        address: '',
        addressComplement: '',
        addressNumber: '',
        addressReference: '',
        cep: '',
        city: '',
        neighborhood: '',
        state: ''
      },
      dateStart: '',
      dateEnd: '',
      id: '',
      returnSchedule: false,
      status: '',
      type: '',
      calendarId: '',
      teleUrl: '',
      allowReschedule: false,
      isNeedCollectCSAT: false
    }
  ],
  payments: [],
  product: {
    id: '',
    name: ''
  },
  haveToAnswerAnamnese: '',
  doctor: {
    name: '',
    profileImage: '',
    profileUrl: '',
    id: ''
  },
  steps: {
    currentStep: '',
    nextSteps: '',
    previousSteps: ''
  },
  calendarTypes: [
    {
      calendarId: '',
      id: '',
      type: ECalendarType.MAIN
    }
  ],
  calendars: [
    {
      durationEvent: 0,
      id: '',
      type: ''
    }
  ],
  calendarSecundary: {
    durationEvent: 0,
    id: '',
    type: ''
  },
  files: {
    exams: [],
    reports: [],
    requests: []
  }
};

export const Journey = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    saveCurrentJourney: (state, action: PayloadAction<any>) => {
      state.status = action.payload.status;
      state.haveToAnswerAnamnese = action.payload.haveToAnswerAnamnese;
      state.journeyid = action.payload.journeyid;
      state.schedules = action.payload.schedules;
      state.steps = action.payload.steps;
      state.doctor = action.payload.doctor;
      state.payments = action.payload.payments;
      state.product = action.payload.product;
      state.calendarSecundary = action.payload.calendarSecundary;
      state.calendars = action.payload.calendars;
      state.files = action.payload.files;
      state.calendarTypes = action.payload.calendarTypes;
    },
    saveJourneyStatus: (state, action: PayloadAction<any>) => {
      state.status = action.payload;
    },
    updateFiles: (state, action: PayloadAction<Files>) => {
      state.files = action.payload;
    }
  }
});

export const { saveCurrentJourney, saveJourneyStatus, updateFiles } = Journey.actions;
export const journeyReducer = Journey.reducer;
