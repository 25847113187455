import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import ItemButton from '../../../shared/ItemButton';
import { ReactComponent as Report } from '../../../../images/report.svg';
import { ReactComponent as ClinicalNotes } from '../../../../images/clinical_notes.svg';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Modal from './Modal';
import { RootState } from '../../../../store';
import { customEventTracking } from '../../../../lib/gtm';
import { fileUploadModalOpen } from '../../../../features/fileUpload';

export const ContentExams: FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [infoType, setInfoType] = useState('');
  const {
    journey: { files }
  } = useSelector((state: RootState) => state);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleUploadFiles = () => {
    dispatch(
      fileUploadModalOpen({
        title: 'Enviar exames',
        successMessage:
          'Seus exames foram enviados com sucesso e já estão disponíveis para serem visualizados na aba Ver exames.'
      })
    );
    customEventTracking.send({
      event: 'enviar_exames_mc'
    });
  };

  return (
    <Box>
      <Box>
        <Box>
          <ItemButton
            text="Ver exames"
            icon={<VaccinesOutlinedIcon sx={{ marginRight: 2 }} />}
            onClick={() => {
              handleOpen();
              setInfoType('EXAM');
              customEventTracking.send({
                event: 'ver_docs_exames_mc'
              });
            }}
          />
        </Box>
        <Box>
          <ItemButton
            text="Ver pedidos e receitas"
            icon={
              <Box sx={{ marginRight: 2 }}>
                <ClinicalNotes />
              </Box>
            }
            onClick={() => {
              handleOpen();
              setInfoType('REVENUES');
              customEventTracking.send({
                event: 'ver_docs_pedidos_receitas_mc'
              });
            }}
          />
        </Box>
        <Box>
          <ItemButton
            text="Ver relatório"
            icon={
              <Box sx={{ marginRight: 2 }}>
                <Report />
              </Box>
            }
            onClick={() => {
              handleOpen();
              setInfoType('REPORTS');
              customEventTracking.send({
                event: 'ver_docs_relatórios_mc'
              });
            }}
          />
        </Box>
        <Box>
          <ItemButton
            text="Enviar exames"
            icon={<DriveFolderUploadIcon sx={{ marginRight: 2 }} />}
            onClick={() => handleUploadFiles()}
          />
        </Box>
        <Modal
          openCloseController={open}
          onCloseHandler={() => {
            handleOpen();
          }}
          infoType={infoType}
          dataFiles={files}
        />
      </Box>
    </Box>
  );
};

export default ContentExams;
