import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { customEventTracking } from '../../../lib/gtm';
import { RootState } from '../../../store';

const NotLogged: React.FC = () => {
  const navigate = useNavigate();
  const {
    anamnese: { journeyId, formId, oyanaId }
  } = useSelector((state: RootState) => state);

  return (
    <>
      <Typography variant="body1" mb={4}>
        Precisamos que responda algumas perguntinhas para aproveitar melhor o tempo da sua consulta. Assim, a médica te
        conhece melhor e prepara sua conversa de forma mais personalizada.
      </Typography>
      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            customEventTracking.send({
              event: 'iniciar_home_anamnese_mc'
            });
            navigate(`/responda-anamnese/${formId}/${journeyId}${oyanaId ? `/${oyanaId}` : ''}`);
          }}
        >
          Iniciar
        </Button>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" alignItems="center">
          <TimerOutlinedIcon sx={{ fontSize: '16px' }} />
        </Box>
        <Box marginLeft={0.5}>
          <Typography variant="body2R">Leva 7 minutos</Typography>
        </Box>
      </Box>
    </>
  );
};

export default NotLogged;
