import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import { Header, Actions } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeScheduleAppointment,
  doneScheduleAppointment,
  hasbackButton,
  saveSelectedDay,
  updateScheduleEvents,
  updateScheduleSteps
} from '../../../features/schedule/schedule-slice';
import { PickersDay } from '@mui/x-date-pickers';
import { RootState } from '../../../store';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface CustomMobileDatePickerProps {
  title: string;
  updateMonth: (month: string) => void;
  updateYear: (month: string) => void;
  onFinished: () => void;
  availableDays: string[];
  isLoading?: boolean;
}

export const setMonthStartedtDay = (value: Dayjs = dayjs(), availableDays: string[]) => {
  const fist = availableDays.find(day => {
    if (dayjs(day).get('month') === value.get('month')) {
      return dayjs(day);
    }
  });
  return fist ? dayjs(fist) : value;
};

const CustomMobileDatePicker = ({
  title,
  updateMonth,
  updateYear,
  availableDays,
  onFinished,
  isLoading
}: CustomMobileDatePickerProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    schedule: {
      ui: { steps }
    }
  } = useSelector((state: RootState) => state);
  const [value, setValue] = useState<Dayjs>(dayjs(availableDays[0]));
  const CustomMobileDatePickerHeader = (date: any) => {
    const formatDate = (date: any) => {
      return date?.format('ddd, DD MMM');
    };
    return (
      <Header>
        <CloseIcon
          onClick={() => {
            dispatch(saveSelectedDay(availableDays[0]));
            dispatch(doneScheduleAppointment({ closeAllModals: false }));
            dispatch(closeScheduleAppointment(true));
            dispatch(hasbackButton(false));
          }}
          sx={{
            mb: 3,
            cursor: 'pointer'
          }}
        />
        <Typography variant="h3" sx={{ mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="h2" sx={{ mb: 2 }}>
          {formatDate(date)}
        </Typography>
      </Header>
    );
  };

  const CustomActionBar = () => {
    const {
      schedule: {
        ui: { hasBackButton }
      }
    } = useSelector((state: RootState) => state);
    return (
      <Actions sx={{ justifyContent: hasBackButton ? 'space-around' : 'flex-end' }}>
        {hasBackButton ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(saveSelectedDay(availableDays[0]));
              dispatch(doneScheduleAppointment({ closeAllModals: false }));
            }}
          >
            <ArrowBackIcon />
            <Typography variant="body1" sx={{ ml: 1.5 }}>
              Voltar
            </Typography>
          </Button>
        ) : (
          []
        )}

        <Button
          variant="contained"
          onClick={() => {
            onFinished();
            dispatch(updateScheduleSteps('selectTime'));
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Continuar
        </Button>
      </Actions>
    );
  };
  const handleOnChange = (newDate: any) => {
    setValue(newDate);
    dispatch(saveSelectedDay(newDate.format('YYYY-M-D')));
  };
  const handleonMonthChange = (newMonth: any) => {
    setValue(newMonth);
    updateMonth(newMonth.get('month').toString());
    if (newMonth.get('year') === dayjs().get('year')) {
      updateYear(newMonth.get('year').toString());
    }
    if (newMonth.get('year') > dayjs().get('year')) {
      updateYear(newMonth.get('year').toString());
    }
    dispatch(updateScheduleEvents('calendar_selected_day'));
  };

  useEffect(() => {
    const monthStartedtDay = setMonthStartedtDay(value, availableDays);
    setValue(monthStartedtDay);
    dispatch(saveSelectedDay(monthStartedtDay.format('YYYY-M-D')));
    dispatch(updateScheduleEvents('calendar_month_selected_first_day'));
  }, [availableDays]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <MobileDatePicker
        ToolbarComponent={({ parsedValue }) => CustomMobileDatePickerHeader(parsedValue)}
        value={value}
        loading={isLoading}
        renderLoading={() => <CircularProgress sx={{ color: 'brand.darkGreen' }} />}
        onChange={handleOnChange}
        onMonthChange={handleonMonthChange}
        renderInput={params => <TextField sx={{ display: 'none' }} {...params} />}
        open={steps === 'openCalendar'}
        dayOfWeekFormatter={day => day}
        disablePast
        renderDay={(day, _, DayProps) => {
          if (!availableDays.includes(day.format('YYYY-M-D'))) DayProps.disabled = true;
          return <PickersDay {...DayProps} />;
        }}
        views={['day']}
        orientation="portrait"
        components={{
          ActionBar: CustomActionBar
        }}
        componentsProps={{
          actionBar: {
            actions: ['cancel', 'accept']
          }
        }}
        DialogProps={{
          PaperProps: {
            sx: {
              borderRadius: '28px',
              backgroundColor: 'neutrals.beige2',
              [theme.breakpoints.down('md')]: {
                margin: '0',
                position: 'absolute',
                bottom: '0',
                width: '100%',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0'
              }
            }
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomMobileDatePicker;
