import { getCurrentSchedule } from '../../../components/shared/JourneyActionManager';
import { scheduleIsEnded } from '../../../lib/dayjs';
import { IJourneyResp } from '../../../services/journey.service';

export enum EnumJourneyAction {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  AWAITING_EXAM = 'AWAITING_EXAM',
  AWAITING_SCHEDULE = 'AWAITING_SCHEDULE',
  AWAITING_ANAMNESE = 'AWAITING_ANAMNESE',
  AWAITING_CONSULTATION = 'AWAITING_CONSULTATION',
  AWAITING_SCHEDULE_RETURN = 'AWAITING_SCHEDULE_RETURN',

  MEDICAL_REQUEST_AVAILABLE = 'MEDICAL_REQUEST_AVAILABLE',
  RESPORT_AVAILABLE = 'RESPORT_AVAILABLE',
  EXAME_AVAILABLE = 'EXAME_AVAILABLE',
  VIDEOCALL_AVAILABLE = 'VIDEOCALL_AVAILABLE',
  DONE = 'DONE',
  CANCELED = 'CANCELED'
}

export const getJourneyAction = (journey: IJourneyResp): EnumJourneyAction => {
  const currentSchedule = getCurrentSchedule(journey);
  if (journey.status === 'CANCELED') {
    return EnumJourneyAction.CANCELED;
  }
  if (journey.status === 'AWAITING_PAYMENT') {
    return EnumJourneyAction.AWAITING_PAYMENT;
  }
  if (journey.status === 'AWAITING_EXAM') {
    return EnumJourneyAction.AWAITING_EXAM;
  }
  if (journey.status === 'AWAITING_SCHEDULE_CONSULTATION') {
    return EnumJourneyAction.AWAITING_SCHEDULE;
  }
  if (journey.status === 'AWAITING_SCHEDULE_RETURN') {
    return EnumJourneyAction.AWAITING_SCHEDULE_RETURN;
  }
  if (journey.status === 'AWAITING_VIRTUAL_CONSULTATION') {
    if (currentSchedule) {
      if (!scheduleIsEnded(currentSchedule?.dateEnd) && currentSchedule.type === 'VIRTUAL_CONSULTATION') {
        return EnumJourneyAction.VIDEOCALL_AVAILABLE;
      }
      if (currentSchedule.allowReschedule) {
        return EnumJourneyAction.AWAITING_CONSULTATION;
      }
      if (journey.files.exams.length > 0) {
        return EnumJourneyAction.EXAME_AVAILABLE;
      }
      if (journey.files.requests.length > 0) {
        return EnumJourneyAction.MEDICAL_REQUEST_AVAILABLE;
      }
      return EnumJourneyAction.AWAITING_CONSULTATION;
    }
  }
  if (journey.files.reports.length > 0) {
    return EnumJourneyAction.RESPORT_AVAILABLE;
  }
  return EnumJourneyAction.DONE;
};
