import { styled } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.neutrals.beige1,
    [theme.breakpoints.down('md')]: {
      margin: '0',
      position: 'absolute',
      bottom: '0',
      width: '100%',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },
  '& .MuiDialogContent-root': {
    padding: '0'
  }
}));

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(1)
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));
