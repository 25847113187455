import CheckIcon from '@mui/icons-material/Check';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { RatingResulIcon } from '../styled';
import { Button, Typography } from '@mui/material';

export const HighRating = () => {
  return (
    <>
      <RatingResulIcon>
        <CheckIcon />
      </RatingResulIcon>
      <Typography variant="h1" sx={{ mb: 3 }}>
        Obrigada!
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Caso tenha alguma dúvida, é só nos chamar.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="https://bit.ly/Fale_com_a_Oya"
        target="_black"
        sx={{ mt: 2 }}
        endIcon={<WhatsAppIcon />}
      >
        Falar com a gente
      </Button>
    </>
  );
};

export default HighRating;
