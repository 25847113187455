import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ViewContent } from '../../layout/Main/styled';
import TemplateDefault from '../../layout/TemplateDefault';
import { Divider } from '../../components/shared/Divider';
import ItemButtonSimple from '../../components/shared/ItemButtonSimple';
import CircularProgress from '@mui/material/CircularProgress';

import { RootState } from '../../store';
import { IJourneyResp } from '../../services/journey.service';
import { useGetPersonalDataMutation } from '../../services/me.service';
import { useGetHistoryMutation } from '../../services/home.service';
import { saveDoneJourneys } from '../../features/journeys';
import { saveOyanaPersonaData } from '../../features/me/me-slice';
import { getCurrentSchedule, getScheduleType } from '../../components/shared/JourneyActionManager';
import { formatDateLabel } from '../../lib/dayjs';

const History = () => {
  const {
    singIn: {
      auth: { access_token }
    },
    oyana: {
      me: { nickName }
    },
    journeys: { doneJourneys }
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getHistory, { data, isLoading }] = useGetHistoryMutation();
  const [getPersonalData, { data: dataOyana }] = useGetPersonalDataMutation();

  useEffect(() => {
    getHistory({});
    if (access_token && nickName === '') getPersonalData({});
  }, []);

  useEffect(() => {
    if (data) {
      const res = data.filter(resp => resp.status === 'DONE');
      dispatch(saveDoneJourneys(res));
    }
  }, [data]);

  useEffect(() => {
    if (dataOyana) {
      dispatch(saveOyanaPersonaData(dataOyana));
    }
  }, [dataOyana]);

  return (
    <>
      <TemplateDefault>
        <ViewContent className="ViewContent">
          <Grid container>
            <Grid item xs={0} sm={1} md={3}></Grid>
            <Grid item xs={12} sm={10} md={6}>
              <IconButton
                sx={{ width: 48, height: 48, backgroundColor: 'neutrals.beige3', mb: 4 }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography mb={3} variant="h3">
                Histórico
              </Typography>
              <Divider />
              {isLoading ? (
                <CircularProgress sx={{ color: 'brand.darkGreen' }} />
              ) : (
                <>
                  {doneJourneys.map((journey: IJourneyResp) => {
                    const currentSchedule = getCurrentSchedule(journey);
                    return (
                      <Box key={journey.journeyid}>
                        <ItemButtonSimple
                          journeyId={journey?.journeyid}
                          text={journey?.product.name || ''}
                          event="clique_jornada_mc"
                          subText={`${getScheduleType(currentSchedule?.type || '')}: ${formatDateLabel(
                            currentSchedule?.dateStart || ''
                          )}`}
                          subText2={journey?.doctor?.name}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
            </Grid>
            <Grid item xs={0} sm={1} md={3}></Grid>
          </Grid>
        </ViewContent>
      </TemplateDefault>
    </>
  );
};

export default History;
