import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const UserAvatar = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`
  }
}));

export const UserAvatarFirstLetter = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  height: 32,
  backgroundColor: theme.palette.brand.mediumGreen,
  color: theme.palette.neutrals.beige1,
  borderRadius: '100%',
  fontFamily: 'F Grotesk Book',
  fontSize: '1.125rem',
  textTransform: 'uppercase',
  marginRight: theme.spacing(0.5),
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: 48,
    height: 48,
    marginLeft: 0
  }
}));
