import CircularProgress from "@mui/material/CircularProgress";
import { Loading } from './styled';

const PageLoading = () => {
  return (
    <Loading>
      <CircularProgress sx={{ color: 'brand.darkGreen'}} />
    </Loading>
  )
};

export default PageLoading;
