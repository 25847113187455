import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Arrow, ReciveItem, ReciveItemIcon, ReciveItemText, Root } from './styled';
import CircularProgress from '@mui/material/CircularProgress';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import AlertMessage from '../../../shared/AlertMessage';

import { useSingInMethodMutation } from '../../../../services/singnin.service';
import { dynamicCustomEventTracking } from '../../../../lib/gtm';

const ReceivingMethod = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['oya.singInMethod', 'oya.oyanaid', 'oya.method1', 'oya.method2']);
  const [singInMethod, { isLoading }] = useSingInMethodMutation();
  const [error, setError] = useState<string>('');
  const [method, setMethod] = useState<string>('');

  const handleOnClick = (sendingMethod: string) => {
    if (!cookies['oya.oyanaid']) return navigate('/login');
    handleRequest(sendingMethod);
    setMethod(sendingMethod);
    updateSingInMethodCookie(sendingMethod);
  };

  const updateSingInMethodCookie = (method: string) => {
    removeCookie('oya.singInMethod');
    setCookie('oya.singInMethod', method, { secure: true, maxAge: 86400 });
  };
  const handleRequest = (method: string) => {
    singInMethod({ id: cookies['oya.oyanaid'], method })
      .unwrap()
      .then(() => {
        dynamicCustomEventTracking.send({
          dynamicEventName: `login_method_${method}_mc`
        });
        navigate('/login/codigo');
      })
      .catch((err: any) => {
        setError(err.data.message);
        console.log(err);
      });
  };

  useEffect(() => {
    removeCookie('oya.singInMethod');
  }, []);
  return (
    <Root>
      <ReciveItem onClick={() => handleOnClick('phone')}>
        <ReciveItemIcon>
          <WhatsAppIcon />
        </ReciveItemIcon>
        <ReciveItemText>
          <Typography variant="body1B">WhatsApp</Typography>
          <Typography variant="body1">{cookies['oya.method2']}</Typography>
        </ReciveItemText>
        <Arrow>
          {method === 'phone' && isLoading ? <CircularProgress sx={{ color: 'brand.darkGreen' }} /> : <ChevronRightIcon />}
        </Arrow>
      </ReciveItem>
      <ReciveItem onClick={() => handleOnClick('email')}>
        <ReciveItemIcon>
          <EmailOutlinedIcon />
        </ReciveItemIcon>
        <ReciveItemText>
          <Typography variant="body1B">E-mail</Typography>
          <Typography variant="body1">{cookies['oya.method1']}</Typography>
        </ReciveItemText>
        <Arrow>
          {method === 'email' && isLoading ? <CircularProgress sx={{ color: 'brand.darkGreen' }} /> : <ChevronRightIcon />}
        </Arrow>
      </ReciveItem>
      {error && <AlertMessage text={error} textButton="Tente novamente" type="error" />}
    </Root>
  );
};

export default ReceivingMethod;
