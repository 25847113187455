import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { IQuestion } from '../../../../../providers/AnamneseProvider/context';

interface TextProps {
  question: IQuestion;
  nextHandler: () => void;
}

const Text = ({ question, nextHandler }: TextProps) => {
  const {
    register,
    trigger,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    trigger(question.id);
  }, []);
  return (
    <TextField
      defaultValue={question.isRecovery ? question.answer : undefined}
      variant="standard"
      fullWidth
      placeholder={question.tip}
      onKeyUp={() => {
        trigger(question.id);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (errors[question.id]) return;
          nextHandler();
        }
      }}
      {...register(question.id, {
        required: question.isRequired,
        onBlur: () => trigger(question.id),
        onChange: () => trigger(question.id)
      })}
    />
  );
};

export default Text;
