import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useEffect, useState } from 'react';
import { IPayments } from '../../../../services/journey.service';
import { formatCurrency } from '../../../../lib/formatCurrency';
import Box from '@mui/material/Box/Box';
import { SessionLimiterStrong } from '../../../shared/Divider';

const PaymentInfo = () => {
  const {
    journey: { payments }
  } = useSelector((state: RootState) => state);
  const [journeyPaid, setJourneyPaid] = useState<IPayments>({
    address: {
      address: '',
      addressComplement: '',
      addressNumber: '',
      addressReference: '',
      cep: '',
      city: '',
      neighborhood: '',
      state: ''
    },
    amount: 0,
    cupomCode: '',
    discount: 0,
    id: '',
    installments: 0,
    method: '',
    paymentDate: '',
    status: ''
  });

  const formatPaymentMethod = (method: string): string => {
    switch (method) {
      case 'creditCard':
        return 'Cartão de crédito';
      case 'pix':
        return 'Pix';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (payments?.length > 0) {
      setJourneyPaid(payments.filter((payment: any) => payment.status === 'paid')[0]);
    }
  }, [payments]);

  return (
    <>
      {journeyPaid?.status === 'paid' && (
        <>
          <SessionLimiterStrong />
          <Box>
            <Typography variant="h3" sx={{ mb: 4 }}>
              Informações de pagamento
            </Typography>
            <Typography variant="body1B" sx={{ mb: 1 }}>
              Pagamento
            </Typography>
            {journeyPaid.amount > 0 && <Typography>{formatPaymentMethod(journeyPaid?.method)}</Typography>}
            {journeyPaid?.cupomCode && <Typography>{`Cupom ${journeyPaid?.cupomCode}`} </Typography>}
            {journeyPaid?.method === 'healthPlan' ? (
              'Plano de Saúde'
            ) : (
              <>
                <Typography sx={{ mb: 1 }}>
                  {formatCurrency(journeyPaid?.amount, 'BRL')}
                  {journeyPaid?.installments === 1 ? ' à vista' : ` ${journeyPaid?.installments}x(sem juros)`}
                </Typography>
                {journeyPaid?.amount !== 0 && (
                  <Typography variant="body2R">Você receberá a nota fiscal após a consulta por e-mail.</Typography>
                )}
              </>
            )}
          </Box>
          {journeyPaid.address ? (
            <Box sx={{ mt: 2 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1B">Endereço de cobrança</Typography>
              </Box>
              <Box>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="body1">{`${journeyPaid.address.address}, ${journeyPaid.address.addressNumber}`}</Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="body1">
                    {`${journeyPaid.address.neighborhood}, ${journeyPaid.address.city} - ${journeyPaid.address.state}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default PaymentInfo;
