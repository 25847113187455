import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Header = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 3, 0, 3),
  borderBottom: `1px solid ${theme.palette.neutrals.beige4}`,
  '.MuiTypography-body2R': {
    marginBottom: '36px'
  },
  '.MuiTypography-h2': {
    textTransform: 'capitalize'
  }
}));

export const Actions = styled(Box)({
  display: 'flex',
  paddingBottom: '24px',
  paddingRight: '10px'
});
