import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import { InnerSectionContainerThree } from '../../components/shared/Grid';

const Wrapper = styled(MuiBox)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige1
}));

type Props = {
  children: React.ReactNode;
};

const MainThree = ({ children }: Props) => (
  <Wrapper sx={{ flex: 1 }}>
    <InnerSectionContainerThree>{children}</InnerSectionContainerThree>
  </Wrapper>
);

export default MainThree;
