import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import { ReactComponent as Logo } from '../../../images/logo_horizontal.svg';
import MenuMobile from './MenuMobile';
import Button from '@mui/material/Button';
import { customEventTracking, eventTracking } from '../../../lib/gtm';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from './styled';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import MenuDesktop from './MenuDesktop';

const ResponsiveAppBar = () => {
  const {
    singIn: {
      auth: { access_token }
    }
  } = useSelector((state: RootState) => state);

  return (
    <AppBar position="static" sx={{ p: 0, backgroundColor: 'transparent', boxShadow: 0 }}>
      <Toolbar disableGutters sx={{ minHeight: { xs: '56px' }, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, cursor: 'pointer' }}>
          <Link href={process.env.REACT_APP_SITE_URL} rel="noreferrer">
            <Logo data-testid="logo-app-bar" />
          </Link>
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{ py: 1, px: { md: 2 } }}
            href="https://bit.ly/Fale_com_a_Oya"
            target="_blank"
            endIcon={<WhatsAppIcon />}
            onClick={() => {
              customEventTracking.send({
                event: 'contato_mc'
              });
            }}
          >
            Fale com a gente
          </Button>
          {access_token && access_token.length > 0 && <MenuDesktop />}
        </Box>

        <Box sx={{ flexGrow: 0, mr: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            data-testid="icon-button"
            onClick={() => {
              window.open('https://bit.ly/Fale_com_a_Oya');
              customEventTracking.send({
                event: 'contato_flutuante_mc'
              });
            }}
          >
            <WhatsAppIcon />
          </IconButton>
        </Box>

        <MenuMobile />
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
