import { EQuestionTypes, IQuestion } from '../../../../providers/AnamneseProvider/context';
import GroupCheckbox from './GroupCheckbox';
import FieldBoolean from './FieldBoolean';
import LongText from './LongText';
import Text from './Text';
import Date from './Date';
import LinearScale from './LinearScale';
import UnitMeasure from './UnitMeasure';
import SingleChoice from './SingleChoice';

interface Props {
  question: IQuestion;
  nextHandler: () => void;
}
const FieldType = ({ question, nextHandler }: Props) => {
  const type = question.type;
  switch (type) {
    case EQuestionTypes.UNIT_OF_MEASUREMENT:
      return <UnitMeasure nextHandler={nextHandler} question={question} />;
    case EQuestionTypes.DATE:
      return <Date question={question} />;
    case EQuestionTypes.TEXT:
      return <Text nextHandler={nextHandler} question={question} />;
    case EQuestionTypes.SINGLE_CHOICE:
      return <SingleChoice question={question} />;
    case EQuestionTypes.CHECKBOX:
      return <GroupCheckbox question={question} />;
    case EQuestionTypes.BOOLEAN:
      return <FieldBoolean question={question} />;
    case EQuestionTypes.LONG_TEXT:
      return <LongText question={question} />;
    case EQuestionTypes.LINEAR_SCALE:
      return <LinearScale question={question} />;
    default:
      return <></>;
  }
};

export default FieldType;
