import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FileUpload {
  open: boolean;
  title?: string;
  successMessage?: string;
  homeButton?: boolean;
  forwardRefJorneyId?: string;
  forwardRefId?: string;
  onClose?: () => void;
}

const initialState: FileUpload = {
  open: false,
  title: 'Enviar exames',
  successMessage: 'Seus exames foram enviados com sucesso e já estão disponíveis para serem visualizados na aba Ver exames.',
  homeButton: false,
  forwardRefJorneyId: '',
  forwardRefId: ''
};

export const fileUpload = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    fileUploadModalOpen: (state, action: PayloadAction<Omit<FileUpload, 'open'>>) => {
      state.open = true;
      state.title = action.payload.title;
      state.successMessage = action.payload.successMessage;
      state.homeButton = action.payload.homeButton;
      state.forwardRefJorneyId = action.payload.forwardRefJorneyId;
      state.forwardRefId = action.payload.forwardRefId;
      state.onClose = action.payload.onClose;
    },
    fileUploadModalClose: state => {
      state.open = false;
      state.title = 'Enviar exames';
      state.successMessage =
        'Seus exames foram enviados com sucesso e já estão disponíveis para serem visualizados na aba Ver exames.';
      state.homeButton = false;
      state.forwardRefJorneyId = '';
      state.forwardRefId = '';
    }
  }
});

export const { fileUploadModalOpen, fileUploadModalClose } = fileUpload.actions;
export const fileUploadReducer = fileUpload.reducer;
