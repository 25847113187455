import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const SuccessIcon = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.neutrals.beige3,
  borderRadius: '100%',
  marginBottom: theme.spacing(2)
}));
