import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";

export const Loading = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.lead,
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent : 'center',
  flexWrap: 'wrap',
}));
