import { FC, ReactElement } from 'react';
import { Circle } from './styled';
import { useTheme } from '@mui/material/styles';

interface Props {
  icon?: ReactElement;
  backgroundColor?: string;
  size: string;
  border?: boolean;
}

export const CircleIcon: FC<Props> = ({ icon, size, border, backgroundColor }) => {
  const theme = useTheme();
  return (
    <Circle
      sx={{
        width: size || '80px',
        height: size || '80px',
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.colored.salmon,
        border: border ? `1px solid ${theme.palette.colored.salmon}` : ''
      }}
    >
      {icon}
    </Circle>
  );
};

export default CircleIcon;
