import { createSlice } from '@reduxjs/toolkit';

interface Anamnese {
  journeyId: string;
  oyanaId?: string;
  formId?: string;
}

const initialState: Anamnese = {
  journeyId: '',
  oyanaId: '',
  formId: ''
};

export const anamnese = createSlice({
  name: 'anamnese',
  initialState,
  reducers: {
    saveAnamneseMetaData: (state, action) => {
      state.journeyId = action.payload.journeyId;
      state.oyanaId = action.payload.oyanaId;
      state.formId = action.payload.formId;
    }
  }
});

export const { saveAnamneseMetaData } = anamnese.actions;
export const anamneseReducer = anamnese.reducer;
