import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ModalWrapper = styled(Box)(({ theme }) => () => ({
  backgroundColor: theme.palette.neutrals.beige1,
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '568px',
  maxHeight: '80vh',
  overflowY: 'auto',
  boxShadow: '5px',
  paddingBottom: '10px',
  top: '50%',
  borderRadius: '15px',
  outline: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    transform: 'none',
    top: 'initial',
    bottom: 0,
    left: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}));

export const ModalContent = styled(Box)(() => () => ({
  position: 'relative',
  padding: '0 30px 30px 30px'
}));

export const ModalBoxContent = styled(Box)(({ theme }) => () => ({
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.neutrals.beige4,
    borderRadius: '16px'
  }
}));

export const ModalExtraContent = styled(Box)(({ theme }) => () => ({
  padding: '30px',
  backgroundColor: theme.palette.neutrals.beige2
}));

export const ModalHeader = styled(Box)(({ theme }) => () => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.neutrals.beige1,
  paddingTop: 30,
  paddingBottom: theme.spacing(2)
}));
