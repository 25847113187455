import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useAnamnese from '../../../../providers/AnamneseProvider/use';
import { EQuestionTypes, IOption, IQuestion } from '../../../../providers/AnamneseProvider/context';
import FieldType from '../FieldType';
import { QuestionAction, QuestionActionButton, QuestionBody, QuestionField, QuestionSubtitle } from '../style';
import { scrollIntoView, elementScrollIntoViewPolyfill } from 'seamless-scroll-polyfill';

interface Props {
  question: IQuestion;
}
const Validation = ({ question }: Props) => {
  const { actions, state } = useAnamnese();
  const {
    getValues,
    watch,
    formState: { isValid },
    getFieldState
  } = useFormContext();
  const [otherChoiceSelect, setOtherChoiceSelect] = useState<boolean>(false);

  const scrollToTop = () => {
    if (window) {
      const idHeader = window.document.querySelector('#form_header');
      if (idHeader === null) {
        return '';
      } else {
        elementScrollIntoViewPolyfill();
        scrollIntoView(idHeader, { behavior: 'smooth' });
      }
    }
  };

  const nextHandler = () => {
    const { invalid } = getFieldState(question.id);
    if (!isValid && invalid) return;
    scrollToTop();
    actions.addQuestionHistory(question.id);

    if (question?.logicTarget?.length > 0) {
      if (getValues(question.id)?.option?.label) {
        question.logicTarget.forEach(filterQuestionTarget => {
          const isValidCondition = actions.isValidCondition(filterQuestionTarget, getValues(question.id)?.option?.label);
          if (isValidCondition) {
            const { questionTargetIndex, categoryTargetIndex } = actions.findQuestionTarget(question, filterQuestionTarget);
            actions.stepGoTo(categoryTargetIndex, questionTargetIndex);
          }
        });
      } else if (getValues(question.id).options) {
        const listCheckbox = getValues(question.id).options;
        const valueCheckbox = listCheckbox.filter((checkbox: IOption) => checkbox.value === true);

        valueCheckbox.forEach((checkbox: IOption) => {
          question.logicTarget.forEach(filterQuestionTarget => {
            const isValidCondition = actions.isValidCondition(filterQuestionTarget, checkbox.label);
            if (isValidCondition) {
              const { questionTargetIndex, categoryTargetIndex } = actions.findQuestionTarget(
                question,
                filterQuestionTarget
              );
              actions.stepGoTo(categoryTargetIndex, questionTargetIndex);
            }
          });
        });
      }

      actions.stepNext();
    } else {
      actions.stepNext();
    }
  };

  useEffect(() => {
    const subscription = watch((value: any) => {
      if (question.type === EQuestionTypes.SINGLE_CHOICE) {
        if (Object.keys(value[question.id].option).length == 0) return;
        if (value[question.id]?.option?.id !== 'other') {
          nextHandler();
        }
        if (value[question.id]?.option?.id === 'other') {
          setOtherChoiceSelect(true);
        } else {
          setOtherChoiceSelect(false);
        }
      }
      if (question.type === EQuestionTypes.BOOLEAN) {
        if (value[question.id].length === 0) return;
        nextHandler();
      }
      if (question.type === EQuestionTypes.LINEAR_SCALE) {
        if (value[question.id].length === 0) return;
        nextHandler();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (question.isRecovery && question?.answer?.length > 0) {
      setOtherChoiceSelect(true);
    } else {
      setOtherChoiceSelect(false);
    }
  }, []);

  return (
    <QuestionBody className={`${question.type} ${question?.subtitle?.length > 0 ? 'with-subtitle' : ''}`}>
      <QuestionField>
        {question.subtitle ? (
          <QuestionSubtitle variant="body1" className={`field-title-${question.type}`}>
            {question.subtitle}
          </QuestionSubtitle>
        ) : null}

        <FieldType nextHandler={nextHandler} question={question} />
      </QuestionField>
      <QuestionAction>
        {actions.hasContinue(question.id) || otherChoiceSelect ? (
          <QuestionActionButton
            loading={state.isLoading}
            fullWidth
            endIcon={<ArrowForwardIcon />}
            disabled={!isValid}
            onClick={() => nextHandler()}
            variant="contained"
          >
            Continuar
          </QuestionActionButton>
        ) : (
          []
        )}
      </QuestionAction>
    </QuestionBody>
  );
};

export default Validation;
