import { FC } from 'react';
import { UserAvatar, UserAvatarFirstLetter } from './styled';

interface UserAvatarProps {
  name: string;
  ref?: any;
  onMouseEnter?: any;
}

const Avatar: FC<UserAvatarProps> = ({ name = '', ref, onMouseEnter }) => {
  const nameFirstLetter = name ? name.charAt(0) : '';
  return (
    <UserAvatar variant="body1B" ref={ref} onMouseEnter={onMouseEnter}>
      <UserAvatarFirstLetter component={'span'}>{nameFirstLetter}</UserAvatarFirstLetter>
      {name}
    </UserAvatar>
  );
};

export default Avatar;
