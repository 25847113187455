import { useSelector } from 'react-redux';
import { useState } from 'react';
import { RootState } from '../../../../store';
import { AwaitingPaymentSmall } from '../AwaitingPayment';
import { AwaitingExamSmall } from '../AwaitingExam';
import { AwaitingAnamneseSmall } from '../AwaitingAnamnese';
import { AwaitingScheduleReturnSmall } from '../AwaitingScheduleReturn';
import { EnumJourneyAction, getJourneyAction } from '../../../../utils/enum/journeyAction';
import { AwaitingScheduleSmall } from '../AwaitingSchedule';
import { ReportAvailableSmall } from '../ReportAvailable';
import { VideoCallAvailableSmall } from '../VideoCallAvailable';
import { IJourneyResp } from '../../../../services/journey.service';
import { ExamAvailableSmall } from '../ExamAvailable';
import { MedicalRequestAvailableSmall } from '../MedicalRequestAvailable';
import Box from '@mui/material/Box';
import { AwaitingConsultationSmall } from '../AwaitingConsultation';
import FlexibleReturnProvider from '../../../../providers/FlexibleReturnProvider';
import Button from '@mui/material/Button';

const JourneyPeding = () => {
  const {
    journeys: { activeJourneys }
  } = useSelector((state: RootState) => state);
  const [showMore, setShowMore] = useState(false);
  const previewItems = 2;

  const displayedJourneys = showMore ? activeJourneys : activeJourneys.slice(0, previewItems);

  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <>
      {displayedJourneys.map((journey: IJourneyResp) => {
        const status = getJourneyAction(journey);
        if (status === EnumJourneyAction.AWAITING_PAYMENT)
          return (
            <Box key={journey.journeyid}>
              <AwaitingPaymentSmall journey={journey} />
            </Box>
          );
        if (
          (status === EnumJourneyAction.AWAITING_CONSULTATION && journey.haveToAnswerAnamnese) ||
          (status === EnumJourneyAction.VIDEOCALL_AVAILABLE && journey.haveToAnswerAnamnese)
        )
          return (
            <Box key={journey.journeyid}>
              <AwaitingAnamneseSmall journey={journey} />
            </Box>
          );
        if (status === EnumJourneyAction.AWAITING_EXAM && journey.haveToAnswerAnamnese)
          return (
            <Box key={journey.journeyid}>
              <AwaitingAnamneseSmall journey={journey} />
            </Box>
          );
        if (status === EnumJourneyAction.AWAITING_EXAM)
          return (
            <Box key={journey.journeyid}>
              <AwaitingExamSmall journey={journey} />
            </Box>
          );
        if (status === EnumJourneyAction.AWAITING_SCHEDULE_RETURN)
          return (
            <Box key={journey.journeyid}>
              <FlexibleReturnProvider journey={journey}>
                <AwaitingScheduleReturnSmall journey={journey} />
              </FlexibleReturnProvider>
            </Box>
          );
        if (status === EnumJourneyAction.AWAITING_SCHEDULE)
          return (
            <Box key={journey.journeyid}>
              <AwaitingScheduleSmall journey={journey} />
            </Box>
          );
        if (status === EnumJourneyAction.RESPORT_AVAILABLE)
          return (
            <Box key={journey.journeyid}>
              <ReportAvailableSmall journey={journey} />
            </Box>
          );
        if (status === EnumJourneyAction.EXAME_AVAILABLE)
          return (
            <Box key={journey.journeyid}>
              <ExamAvailableSmall journey={journey} />
            </Box>
          );
        if (status === EnumJourneyAction.MEDICAL_REQUEST_AVAILABLE)
          return (
            <Box key={journey.journeyid}>
              <MedicalRequestAvailableSmall journey={journey} />
            </Box>
          );
        if (status === EnumJourneyAction.AWAITING_CONSULTATION)
          return (
            <Box key={journey.journeyid}>
              <AwaitingConsultationSmall journey={journey} />
            </Box>
          );
        if (status === EnumJourneyAction.VIDEOCALL_AVAILABLE)
          return (
            <Box key={journey.journeyid}>
              <VideoCallAvailableSmall journey={journey} />
            </Box>
          );
      })}

      {!showMore && activeJourneys.length > previewItems && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="inline" color="primary" onClick={handleShowMore}>
            ver mais
          </Button>
        </Box>
      )}
    </>
  );
};

export default JourneyPeding;
