import * as React from 'react';

interface AuthContextType {
  token: string;
  /*signin: (user: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;*/
}

const AuthContext = React.createContext<AuthContextType>(null!);

const useAuth = function useAuth() {
  return React.useContext(AuthContext);
};

export { AuthContext, useAuth };
