import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export const FilesList = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
  marginBottom: theme.spacing(4)
}));

export const FileItem = styled('li')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.neutrals.beige3}`,
  borderTop: `1px solid ${theme.palette.neutrals.beige3}`
}));

export const FileItemDescription = styled(Box)(() => ({
  maxWidth: '70%'
}));

export const FileItemActions = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    svg: {
      path: {
        fill: theme.palette.brand.darkGreen
      }
    }
  }
}));

export const FileItemName = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}));

export const FileItemSize = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1)
}));

export const FileItemErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.colored.red,
  marginTop: theme.spacing(1)
}));

export const RetryButton = styled(IconButton)(() => ({}));
export const DeleteButton = styled(IconButton)(() => ({}));
