import { useContext } from 'react';
import AnamneseContext, { AnamneseContextProps } from './context';

function useAnamnese(): AnamneseContextProps {
  const context = useContext(AnamneseContext);

  if (context === undefined) {
    throw new Error('useAnamnese must be used within a AnamneseProvider');
  }

  return context;
}

export default useAnamnese;
