import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import { useState } from 'react';
import { WrapperBox, MenuListItem, HeaderMobile } from './styled';
import { eventTracking } from '../../../../lib/gtm';

import MenuIcon from '@mui/icons-material/Menu';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import { ReactComponent as Prescriptions } from '../../../../images/prescription.svg';
import { ReactComponent as Report } from '../../../../images/report.svg';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { IconButton, ListItemIcon } from '@mui/material';
import { ReactComponent as LogiMobile } from '../../../../images/horizontal-mobile.svg';
import { Divider } from '../../Divider';
import { expireAuth } from '../../../../features/singin/singin-slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Avatar from '../../Avatar';

const MenuMobile = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    singIn: {
      auth: { access_token }
    },
    oyana: { me },
    journeys: { doneJourneys }
  } = useSelector((state: RootState) => state);
  const handleToggleDrawer = () => {
    eventTracking.click({
      category: 'Menu Mobile',
      label: 'Close Menu'
    });
    setOpen(!open);
  };

  const lougOut = () => {
    dispatch(expireAuth(true));
    handleToggleDrawer();
  };

  return (
    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
      {access_token && access_token.length > 0 && (
        <IconButton onClick={() => handleToggleDrawer()} sx={{ color: 'neutrals.beige1' }}>
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        anchor="left"
        open={open}
        onClose={handleToggleDrawer}
        PaperProps={{ sx: { backgroundColor: 'neutrals.beige1', width: '80%' } }}
      >
        <WrapperBox role="presentation">
          <HeaderMobile>
            <LogiMobile />
            <CloseIcon onClick={handleToggleDrawer} />
          </HeaderMobile>
          <Divider sx={{ mx: 1, my: 0 }} />

          <Avatar name={me.nickName?.length > 0 ? me.nickName : me.firstName} />
          <List>
            <ListItem disablePadding>
              <MenuListItem>
                <ListItemIcon>
                  <MedicalInformationOutlinedIcon />
                </ListItemIcon>
                <Link to="/">
                  <Typography variant="body2B">Jornadas atuais</Typography>
                </Link>
              </MenuListItem>
            </ListItem>
            <ListItem disablePadding>
              <MenuListItem>
                <ListItemIcon>
                  <Prescriptions />
                </ListItemIcon>
                <Link to="/meus-arquivos">
                  <Typography variant="body2B">Meus arquivos</Typography>
                </Link>
              </MenuListItem>
            </ListItem>
            {doneJourneys[0]?.status !== '' ? (
              <ListItem disablePadding>
                <MenuListItem>
                  <ListItemIcon>
                    <Report />
                  </ListItemIcon>
                  <Link to="/historico">
                    <Typography variant="body2B">Histórico</Typography>
                  </Link>
                </MenuListItem>
              </ListItem>
            ) : (
              []
            )}
            <ListItem disablePadding>
              <MenuListItem onClick={() => lougOut()}>
                <ListItemIcon>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <Typography variant="body2B">Sair da conta</Typography>
              </MenuListItem>
            </ListItem>
          </List>
        </WrapperBox>
      </Drawer>
    </Box>
  );
};

export default MenuMobile;
